import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";
import moment from "moment";

const controlName = "adminOrders";

export type Order = {
  id: number;
  transactionId: number;
  fullName: string;
  email: string;
  userId: number;
  userAuthType: string;
  date: string;
  amount: number;
  entryIds: number[];
  numberOfTickets: number;
  details: OrderDetails;
};

export type OrderDetails = {
  id: string;
  orderItemsJson: string;
  paymentId: string;
  dateCreated: string;
  status: string;
  subtotal: number;
  total: number;
  receiptJsonData: string;
};

export type OrderUser = {
  id: number;
  displayName: string;
  authType: string;
  email: string;
};

type GetOrdersReturn = {
  orders: Order[];
  totalRecords: number;
  totalSales: number;
  page: number;
};

var getOrders = createAsyncAction<
  GetOrdersReturn, //return type
  { searchText: string; page: number; fromDate: string; toDate: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getOrders",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetOrders`,
  transformQueryParams: (params) => {
    return {
      searchText: params.searchText,
      page: params.page,
      fromDate:
        params.fromDate === ""
          ? undefined
          : moment(params.fromDate).startOf("day").toISOString(),
      toDate:
        params.toDate === ""
          ? undefined
          : moment(params.toDate).endOf("day").toISOString(),
    };
  },
  pending: (state, action) => {
    state.orders = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.orders.isLoading = false;
    state.orders.data = action.payload;
  },
  rejected: (state, action) => {
    state.orders.isLoading = false;
    state.orders.error = action.payload;
  },
});

type SearchUserReturn = {
  users: OrderUser[];
};

var searchUser = createAsyncAction<
  SearchUserReturn, //return type
  { searchText: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "searchUser",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetUsers`,
  pending: (state, action) => {
    state.searchUser = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.searchUser.isLoading = false;
    state.searchUser.data = action.payload;
  },
  rejected: (state, action) => {
    state.searchUser.isLoading = false;
    state.searchUser.error = action.payload;
  },
});

type moveEntriesReturn = {
  entryIds: number[];
  userId: number;
};

var moveEntries = createAsyncAction<
  moveEntriesReturn, //return type
  { orderId: number; toUserId: number; }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "moveEntries",
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/MoveEntries`,
  getAuthBearerToken,
  postAsJson: true,
  pending: (state, action) => {
    state.moveEntries = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.moveEntries.isLoading = false;
    state.moveEntries.data = action.payload;
  },
  rejected: (state, action) => {
    state.moveEntries.isLoading = false;
    state.moveEntries.error = action.payload;
  },
});

type State = {
  orders: ReducerState<GetOrdersReturn>;
  searchUser: ReducerState<SearchUserReturn>;
  moveEntries: ReducerState<moveEntriesReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    orders: {
      isLoading: false,
    },
    searchUser: {
      isLoading: false,
    },
    moveEntries: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getOrders.reducer,
    ...searchUser.reducer,
    ...moveEntries.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getOrders: getOrders.action,
      searchUser: searchUser.action,
      moveEntries: moveEntries.action,
    },
  },
};

export default duck;
