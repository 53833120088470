import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";
import moment from "moment";

const controlName = "logs";

export type Log = {
  id: number;
  reference: string;
  logType: number;
  logDataJson: string;
  timeStamp: string;
};

export type LogType = {
  id: number;
  name: string;
};

type GetLogsReturn = {
  logs: Log[];
  totalRecords: number;
};

var getLogs = createAsyncAction<
  GetLogsReturn, //return type
  {
    searchText: string;
    logType: number;
    fromDate: string;
    toDate: string;
    page: number;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getLogs",
  getAuthBearerToken,
  postAsJson: true,
  transformQueryParams: (params) => {
    return {
      searchText: params.searchText,
      page: params.page,
      logType: params.logType,
      fromDate:
        params.fromDate === ""
          ? undefined
          : moment(params.fromDate).startOf("day").toISOString(),
      toDate:
        params.toDate === ""
          ? undefined
          : moment(params.toDate).endOf("day").toISOString(),
    };
  },
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetLogs`,
  pending: (state, action) => {
    state.getLogs = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getLogs.isLoading = false;
    state.getLogs.data = action.payload;
  },
  rejected: (state, action) => {
    state.getLogs.isLoading = false;
    state.getLogs.error = action.payload;
  },
});


type GetLogTypesReturn = {
  logTypes: LogType[];
};

var getLogTypes = createAsyncAction<
  GetLogTypesReturn,//return type
  {},//params
  State
>({
  actionPrefix: controlName,
  actionName: "getLogTypes",
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetLogTypes`,
  getAuthBearerToken,
  pending: (state, action) => {
     state.getLogTypes = { isLoading: true };
  },
  fulfilled: (state, action) => {
     state.getLogTypes.isLoading=false;
     state.getLogTypes.data=action.payload;
  },
  rejected: (state, action) => {
     state.getLogTypes.isLoading=false;
     state.getLogTypes.error=action.payload;
  },
});

type State = {
  getLogs: ReducerState<GetLogsReturn>;
  getLogTypes: ReducerState<GetLogTypesReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getLogs: {
      isLoading: false,
    },
    getLogTypes: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getLogs.reducer,
    ...getLogTypes.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getLogs: getLogs.action,
      getLogTypes: getLogTypes.action,
    },
  },
};

export default duck;
