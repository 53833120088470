import { useMsal } from "@azure/msal-react";
import { SimpleDialog } from "@nait-aits/ui";
import CheckboxButton from "components/CheckboxButton/CheckboxButton";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GetUserReturn } from "../userProfile.duck";

export type Props = {
  user: GetUserReturn;
  onSave: (user: GetUserReturn) => void;
  isSaving: boolean;
  canChangeTeamName: boolean;
};

const Profile = (props: Props) => {
  const { user, isSaving, canChangeTeamName = true, onSave } = props;

  const [showCannotSubmit, setShowCannotSubmit] = useState(false);
  const [userData, setUserData] = useState({ ...user });

  const { instance } = useMsal();

  useEffect(() => {
    setUserData({ ...user });
  }, [user]);

  return (
    <div>
      {showCannotSubmit && (
        <SimpleDialog
          handleBackgroundClick={() => {
            setShowCannotSubmit(false);
          }}
        >
          <div style={{ padding: 30 }}>Please fix all errors to continue.</div>
        </SimpleDialog>
      )}
      <FormContent>
        <div className="nait-form-group-row">
          <FormItem
            label="Team Name"
            required
            value={userData.teamName}
            disabled={!canChangeTeamName}
            onChange={(e) => {
              userData.teamName = e;
              setUserData({ ...userData });
            }}
            fieldMessage="This is what will show on the leaderboard. 
            Please be respectful when choosing a name. You can change your Team Name until the purchase deadline."
          />
        </div>
        <h3>Account</h3>
        <div style={{ marginBottom: 20 }}>
          {userData.providerName === "NAIT" && (
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src="https://diviewerdev.blob.core.windows.net/images/eee/NAIT_LOGO.png"
                    alt="NAIT Logo"
                    height="32"
                  />
                </div>
                <div style={{ margin: "auto 0" }}>NAIT Login</div>
              </div>
              <AccountNote>
                If you need to change your account password, you will need to
                visit the NAIT website. <br />
                <a
                  href="https://accounts.nait.ca/changepassword.aspx"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  https://accounts.nait.ca/changepassword.aspx
                </a>
              </AccountNote>
            </div>
          )}
          {userData.providerName === "Google" && (
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAMAAACP+FljAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABOFBMVEX////62NXyi4Pual/rUEPqSz3tXlLyi4L5y8f86OfvcmjqQzXub2T85uT61dLrU0fvdWv86+nrT0LtX1PxgnnwgHfsXVHuaF398vHvdWnsWEv4xsL4wr7wenD+7sP0jRfqRjPsVkn85eT81mf7vAX3pQ7sUy/3ubT7xzH5uBj+/PyGsfjC1/v7vxP7yz9ChfSSuPiMtPj7xzDzwR78/fxel/VZlPWcv/n51GTVuBNOqki03r+vy/q60vv3672vtCI6qFA0qFNIsGTj8+jw9f5clvVDhfRrv4JLsWbC5cvY7t5gnt6Gsffp9exBrV5StGx4xY17xo9cuXU1pls9j8FNjPTo8P3S69lGr2JLoaPO3/zn9Opnvn5UtW7X7t3V7duCyZVeundCrl87q1lOs2l6xo+84sb9/v2qdWS0AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+EIGQ4HDEDExrUAAADWSURBVDjLY2AYBZiAkYmZhZWNnQOHNCcXNxTw8GKT5+PnhgMBLPKCQiAZYRFRMW5ucQksCiSB0lLSIJaMLDZ5OXkFbkUlPB5QVlFVU8fnQw0VFU0tEEMbCnTQFOiqqOiBGfpQYEBIgSGaAiMVFWMTEMMUCMyACszRFFioWFpZwzg2QAW2aArs7B0cnZwhbBdXfX03F3RvuDs6Onp4glhe3kADfDD86esHVOHoHxAY5Bgcoh8ahhkS4RGOcBAZhS2somNg8rFxOIIzPiExKTklNS2dYaQBADviJWzWK7IJAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA4LTI1VDE0OjA3OjEyLTA0OjAwBQDEvwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOC0yNVQxNDowNzoxMi0wNDowMHRdfAMAAAAASUVORK5CYII="
                    alt="Google Logo"
                  />
                </div>
                <div style={{ margin: "auto 0" }}>Google Login</div>
              </div>
              <AccountNote>
                If you need to change your account password, you will need to
                visit the Google Accounts website for more information. <br />
                <a
                  href="https://myaccount.google.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  https://myaccount.google.com/
                </a>
              </AccountNote>
            </div>
          )}
          {userData.providerName === "Custom" && (
            <div>
              <a
                className="nait-button nait-button--link"
                onClick={() => {
                  instance.loginRedirect({
                    scopes: [],
                    authority: `${process.env.REACT_APP_AZUREAD_AUTHORITY_PASSWORD_RESET}`,
                  });
                }}
              >
                Change Password
              </a>
            </div>
          )}
        </div>
        <h3>Contact Information</h3>
        <div className="nait-form-group-row">
          <FormItem
            label="Your Name"
            required
            value={userData.displayName}
            onChange={(e) => {
              userData.displayName = e;
              setUserData({ ...userData });
            }}
          />
        </div>
        <div className="nait-form-group-row">
          <FormItem
            label="Address"
            required
            value={userData.userProfileData.contactInformation.address}
            onChange={(e) => {
              userData.userProfileData = {
                ...userData.userProfileData,
                contactInformation: {
                  ...userData.userProfileData.contactInformation,
                  address: e,
                },
              };
              setUserData({ ...userData });
            }}
          />
        </div>
        <div className="nait-form-group-row">
          <FormItem
            required={false}
            label="Address Line 2"
            value={userData.userProfileData.contactInformation.addressLine2}
            onChange={(e) => {
              userData.userProfileData = {
                ...userData.userProfileData,
                contactInformation: {
                  ...userData.userProfileData.contactInformation,
                  addressLine2: e,
                },
              };
              setUserData({ ...userData });
            }}
          />
          <FormItem
            label="Postal Code"
            required
            value={userData.userProfileData.contactInformation.postalCode}
            onChange={(e) => {
              userData.userProfileData = {
                ...userData.userProfileData,
                contactInformation: {
                  ...userData.userProfileData.contactInformation,
                  postalCode: e.toUpperCase(),
                },
              };
              setUserData({ ...userData });
            }}
            validation={validatePostalCode}
          />
        </div>
        <div className="nait-form-group-row">
          <FormItem
            label="City"
            required
            value={userData.userProfileData.contactInformation.city}
            onChange={(e) => {
              userData.userProfileData = {
                ...userData.userProfileData,
                contactInformation: {
                  ...userData.userProfileData.contactInformation,
                  city: e,
                },
              };
              setUserData({ ...userData });
            }}
          />
          <div className="nait-form-group state-default">
            <label className="nait-form-label required">Province</label>
            <input
              type="text"
              className="nait-input"
              value={"AB"}
              disabled
              onChange={(e) => {}}
            />
            <span className="nait-field-message">
              Worlds Richest Draft is available to Alberta residents only.
            </span>
          </div>
        </div>
        <h3>Notification Settings</h3>
        <div className="nait-form-group-row">
          <FormItem
            label="Contact Email"
            required
            value={userData.emailAddress}
            onChange={(e) => {
              userData.emailAddress = e;
              setUserData({ ...userData });
            }}
            validation={validateEmail}
            fieldMessage="This is only for communication purposes. This will not alter your login email."
          />
          <FormItem
            label="Phone Number"
            required
            value={userData.userProfileData.contactInformation.phoneNumber}
            onChange={(e) => {
              userData.userProfileData = {
                ...userData.userProfileData,
                contactInformation: {
                  ...userData.userProfileData.contactInformation,
                  phoneNumber: e,
                },
              };
              setUserData({ ...userData });
            }}
            validation={validatePhone}
          />
        </div>
        <div className="nait-form-group-row">
          <div className="nait-form-group state-default">
            <label className="nait-form-label">Contact Options</label>
            {/* <CheckBoxField
              style={{ marginLeft: 20 }}
              label="I wish to be notified by email about the results at the
              conclusion of each round."
              state={
                userData.userProfileData.notificationOptions?.roundConcluded
                  ? "checked"
                  : "unchecked"
              }
              onClick={() => {
                var opts = userData.userProfileData.notificationOptions || {};

                userData.userProfileData = {
                  ...userData.userProfileData,
                  notificationOptions: {
                    ...opts,
                    roundConcluded: !opts.roundConcluded,
                  },
                };
                console.log(userData);
                setUserData({ ...userData });
              }}
            /> */}
            <CheckBoxField
              style={{ marginLeft: 20 }}
              label="I wish to be contacted by the NAIT Foundation about next years
              draft."
              state={
                userData.userProfileData.notificationOptions?.nextYearDraft
                  ? "checked"
                  : "unchecked"
              }
              onClick={() => {
                var opts = userData.userProfileData.notificationOptions || {};

                userData.userProfileData = {
                  ...userData.userProfileData,
                  notificationOptions: {
                    ...opts,
                    nextYearDraft: !opts.nextYearDraft,
                  },
                };

                setUserData({ ...userData });
              }}
            />
          </div>
        </div>

        <div className="nait-disclaimer" style={{ padding: 0 }}>
          <div style={{ padding: "10px 0" }}>
            The NAIT Foundation respects your privacy. We do not rent, sell, or
            trade our mailing lists. The personal information collected by the
            NAIT Foundation is used to solely to notify winners and to contact
            you about next year’s project.
          </div>
          <div style={{ padding: "10px 0" }}>
            Due to AGLC licensing, the World's Richest Playoff Hockey Draft is
            available to Alberta residents only.
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            disabled={isSaving}
            className="nait-button nait-button--primary state-default"
            onClick={() => {
              if (canSubmit(userData)) {
                onSave(userData);
              } else {
                setShowCannotSubmit(true);
              }
            }}
          >
            Update Profile
          </button>
        </div>
      </FormContent>
    </div>
  );
};

const AccountNote = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #8f959f;
  margin: 10px;
`;

type FormItemProps = {
  value?: string;
  label: string;
  fieldMessage?: string;
  required?: boolean;
  disabled?: boolean;
  validation?(value: string): string;
  onChange(value: string): void;
};

const FormItem = (props: FormItemProps) => {
  const {
    value = "",
    label,
    required = true,
    disabled = false,
    fieldMessage,
    validation = () => "",
    onChange,
  } = props;

  var error = required && !value ? "Required" : validation(value);

  return (
    <div
      className={`nait-form-group ${
        error ? "nait-error-state" : "state-default"
      }`}
    >
      <label className={`nait-form-label ${required && "required"}`}>
        {label}
      </label>
      <input
        type="text"
        className="nait-input"
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {error && <span className="nait-field-message">{error}</span>}
      {!error && fieldMessage && (
        <span className="nait-field-message">{fieldMessage}</span>
      )}
    </div>
  );
};

const FormContent = styled.div`
  padding: 20px;
  max-width: 600px;
  //   margin: 20px auto;
  border: 1px solid #a9a9a9;
`;

function canSubmit(userData: GetUserReturn) {
  if (!userData.displayName) return false;
  if (!userData.teamName) return false;
  if (validateEmail(userData.emailAddress || "")) return false;

  var ci = userData.userProfileData.contactInformation;

  if (!ci.address) return false;
  if (!ci.city) return false;

  if (validatePhone(ci.phoneNumber || "")) return false;
  if (validatePostalCode(ci.postalCode || "")) return false;

  return true;
}

function validatePhone(value: string) {
  var regEx = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;

  if (regEx.test(value)) return "";
  else {
    return "Invalid Phone Number";
  }
}

function validateEmail(value: string) {
  var regEx = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/;

  if (regEx.test(value)) return "";
  else {
    return "Invalid Email";
  }
}

function validatePostalCode(value: string) {
  var tmp = value.replace("-", "").replace(" ", "");

  var regEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;

  if (regEx.test(value) && tmp.length === 6) return "";
  else {
    return "Invalid Postal Code";
  }
}

// const HeardAboutUsField = (props: {
//   id: string;
//   label: string;
//   userData: GetUserReturn;
//   onChange: (userData: GetUserReturn) => void;
//   style?: React.CSSProperties;
// }) => {
//   const { id, label, userData, onChange, style = {} } = props;

//   return (
//     <CheckBoxField
//       style={{ marginLeft: 20, ...style }}
//       label={label}
//       state={
//         isHeardAboutChecked(
//           id,
//           userData.userProfileData.howDidYouHearAboutUs || ""
//         )
//           ? "checked"
//           : "unchecked"
//       }
//       onClick={() => {
//         var item = userData.userProfileData.howDidYouHearAboutUs || "";
//         if (isHeardAboutChecked(id, item)) {
//           item = removeHeardAboutOption(id, item);
//         } else {
//           item = addHeardAboutOption(id, item);
//         }

//         userData.userProfileData = {
//           ...userData.userProfileData,
//           howDidYouHearAboutUs: item,
//         };
//         onChange({ ...userData });
//       }}
//     />
//   );
// };

const CheckBoxField = (props: {
  style?: React.CSSProperties;
  label: string;
  state?: "checked" | "unchecked" | "loading";
  onClick?: () => void;
}) => {
  const { style = {}, label, state = "unchecked", onClick = () => {} } = props;
  return (
    <div style={{ display: "flex", ...style }}>
      <div>
        <CheckboxButton state={state} onClick={onClick} />
      </div>
      <div style={{ marginLeft: 10 }}>{label}</div>
    </div>
  );
};

// function isHeardAboutChecked(option: string, fullString: string) {
//   if (fullString.indexOf(`-${option}-`) >= 0) {
//     return true;
//   }

//   return false;
// }

// function addHeardAboutOption(option: string, fullString: string) {
//   if (fullString.indexOf(`-${option}-`) >= 0) {
//     return fullString;
//   }

//   return `${fullString}-${option}-`;
// }

// function removeHeardAboutOption(option: string, fullString: string) {
//   if (fullString.indexOf(`-${option}-`) < 0) {
//     return fullString;
//   }

//   return fullString.replace(`-${option}-`, "");
// }

export default Profile;
