import MainTemplate from "components/MainTemplate/MainTemplate";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { useEffect } from "react";
import Question, { QuestionProps } from "./components/Question";
import moment from "moment-timezone";
import { useActions, useReduxState } from "store";
import IncludedTeams from "./components/IncludedTeams";

export type Props = {};
type State = {
  mdFaq: string;
};

type QuestionSection = {
  title: string;
  questions: QuestionProps[];
};

const SiteLink = (
  <a href="https://www.worldsrichestdraft.com" target={"_blank"}>
    www.worldsrichestdraft.com
  </a>
);

const SiteMailTo = (
  <a href="mailto:info@worldsrichestdraft.com">info@worldsrichestdraft.com</a>
);

const FAQ = (props: Props) => {
  // const [state, setState] = useState<State>({
  //   mdFaq: "Loading...",
  // });

  // useEffect(() => {
  //   fetch("/mdFiles/faq.md")
  //     .then((response) => response.text())
  //     .then((text) => {
  //       setState({ ...state, mdFaq: text });
  //     });
  // }, []);
  const state = useReduxState((e) => e.faq);
  const actions = useActions().faq;
  const settingsState = useReduxState((e) => e.mainTemplate.getSettings);

  useEffect(() => {
    actions.getIncludedTeams({});
  }, []);

  const endDateString = settingsState.data
    ? `${moment(settingsState.data.settings.entryCloseDateGMT)
        .tz("America/Edmonton")
        .format(
          "MMMM DD, YYYY, h:mm A"
        )} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const endDateString = "TBD";
  const endDateDayOfWeek = settingsState.data
    ? moment(settingsState.data.settings.entryCloseDateGMT)
        .tz("America/Edmonton")
        .format("dddd")
    : "---";

  const startDateString = settingsState.data
    ? moment(settingsState.data.settings.entryOpenDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY, h:mm A")
    : "TBA";
  const startDateDayOfWeek = settingsState.data
    ? moment(settingsState.data.settings.entryOpenDateGMT)
        .tz("America/Edmonton")
        .format("dddd")
    : "---";

  const playerSelectionDeadline = settingsState.data
    ? `${moment(settingsState.data.settings.playerPickCloseDateGMT)
        .tz("America/Edmonton")
        .format(
          "MMMM DD, YYYY, h:mm A"
        )} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const playerSelectionDeadline = "TBD";
  const playerSelectionDeadlineDayOfWeek = settingsState.data
    ? moment(settingsState.data.settings.playerPickCloseDateGMT)
        .tz("America/Edmonton")
        .format("dddd")
    : "---";

  const questions: QuestionSection[] = [
    {
      title: "Tickets",
      questions: [
        {
          question: "How much are the entries?",
          answer: "1 entry for $50 or 3 entries for $100.",
        },
        {
          question: "How can I purchase entries?",
          answer: (
            <div>
              Entries may be purchased online at {SiteLink} and will be
              available starting {startDateString}, until {endDateString}.
            </div>
          ),
        },
        {
          question: "What payment methods can I use?",
          answer: "Visa, MasterCard, American Express, and Debit Credit Cards.",
        },
        {
          question: "How do I get a receipt?",
          answer:
            "All contestants will receive a receipt for the entry purchase by email. Confirmation letters for each individual entry purchased, including Round 1 statistics, will be sent to the email address provided at the time of purchase. This will be considered the entrant's official receipt for the World’s Richest’s Playoff Hockey Draft.",
        },
        {
          question: "What is the total number of entries accepted?",
          answer:
            "Limited to the first 7,999 (1,000 entries @ $50 and 6,999 entries @ 3 for $100).",
        },
        {
          question: "Is it safe to use my credit card online?",
          answer:
            "The website is secured using an SSL certificate. All entries purchased online go through a certified third-party provider, which is PCI compliant. Access to payment information is secured and encrypted online; whereas, the credit card data is not stored on a system at the conclusion of the process.",
        },
        {
          question:
            "How many entries can be purchased by an individual or household?",
          answer:
            "There is no limiton the number of entries purchased per individual or household.",
        },
        {
          question: "Is there an age restriction on purchasing entries?",
          answer: "Yes. Purchaser must be 18 years of age or older.",
        },
        {
          question:
            "If I am not a resident of Alberta can I still purchase entries?",
          answer:
            "Yes, however you must be in Alberta at the time of purchase or have a valid Alberta address.",
        },
        {
          question: "Is my purchase tax deductible / do I get a tax receipt?",
          answer:
            "No, there is no tax receipt issued as Revenue Canada deems that the purchase of an entry is not a donation. All winnings are tax free.",
        },
        {
          question: "Can my purchase be refunded?",
          answer:
            "All draft sales are final; no refunds shall be made except in the event the draft is cancelled.",
        },
        {
          question: "Who is prohibited from purchasing entries?",
          answer: (
            <div>
              <div>
                Raffle rules prohibit the following people from purchasing
                entries:
              </div>
              <ul style={{ marginLeft: 20 }}>
                <li>NAIT Foundation trustees and officers</li>
                <li>World Richest Draft Playoff Draft Committee</li>
                <li>Immediate family members of all those listed above</li>
              </ul>
            </div>
          ),
        },
        {
          question: "When is the Early Bird Draw?",
          answer: (
            <div>
              The Early Bird runs from {startDateString} to March 31, 2024,
              11:59 PM Mountain Time. Participants that purchased entries during
              this time will be entered into a random draw for a prize basket
              provided by NAIT Athletics.
            </div>
          ),
        },
        {
          question: "What is in the Early Bird Prize Basket?",
          answer: (
            <div>
              <ul style={{ marginLeft: 20 }}>
                <li>NAIT Ooks Hoodie</li>
                <li>NAIT Ooks Hat</li>
                <li>2024/2025 NAIT Ooks Season Pass x 2</li>
              </ul>
              <div>Approximate Value: $200</div>
            </div>
          ),
        },
        {
          question: "How do I collect the Early Bird Prize Basket?",
          answer:
            "The winner of the Early Bird Prize Basket will be notified by email along with instructions on pick up location at NAIT Main Campus.",
        },
        {
          question: "What is done with my personal information?",
          answer: (
            <div>
              <div>
                The NAIT Foundation respects your privacy. We do not rent, sell,
                or trade our mailing lists. The personal information collected
                by the NAIT Foundation is used to solely to notify winners and
                to contact you about next year's project.
              </div>
              <div>
                As per CASL -{" "}
                <a
                  href="https://fightspam-combattrelepourriel.ised-isde.canada.ca/site/canada-anti-spam-legislation/en"
                  target={"_blank"}
                >
                  Canadian anti-spam legislature
                </a>{" "}
                - any contestants who wish to opt out of receiving confirmation
                letters to their email addresses (because of security concerns)
                can do so during the registration process.
              </div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Draft Deadlines",
      questions: [
        {
          question: "When is the entry deadline to purchase entries?",
          answer: (
            <div>
              The deadline is {endDateDayOfWeek}, {endDateString}.
            </div>
          ),
        },
        {
          question: "When is the deadline to select players?",
          answer: (
            <div>
              The deadline is {playerSelectionDeadlineDayOfWeek},{" "}
              {playerSelectionDeadline}.
            </div>
          ),
        },
        {
          question: "When is the first playoff game played?",
          answer: "The first playoff game will be played on TBD",
        },
        {
          question: "When is Round 1, Round 2, and Round 3 completed?",
          answer:
            "Exact dates are unavailable at this time as rounds can be completed in 4-7 games (4 out of 7 wins the round).",
        },
      ],
    },
    {
      title: "Draft Rules",
      questions: [
        {
          question: "How many players can I pick?",
          answer: (
            <div>
              Select {settingsState.data?.settings.maxPicksPerEntry} players for
              each entry.
            </div>
          ),
        },
        {
          question:
            "Can changes to my player selections be made after the player selection deadline date?",
          answer: (
            <div>
              Unfortunately, no changes can be made to player selections after
              the player selection deadline of {playerSelectionDeadline}.
            </div>
          ),
        },
        {
          question: "How are points awarded?",
          answer:
            "Only official National Hockey League statistics will be used. One (1) point awarded for each goal or assist scored during the 2024 Stanley Cup Playoffs.",
        },
        {
          question: "When will stats be run?",
          answer: (
            <div>
              Standings will be updated daily and posted on the draft website at{" "}
              {SiteLink}.
            </div>
          ),
        },
        {
          question: "Can I name my entry?",
          answer:
            "You can name/ rename your entries from the player selection page until the player selection deadline.",
        },
        {
          question: "Where can I find out my standings?",
          answer: (
            <div>
              Go to the website at {SiteLink}. Your entry will be listed using
              your Team Name (and your entry name if you choose to name your
              entry).
            </div>
          ),
        },
        {
          question: "What is the process of any discrepancies?",
          answer: (
            <div>
              Any discrepancies are to be reported in writing by email to the
              NAIT Foundation at {SiteMailTo}. In the event of the reported
              discrepancy, the data file will be pulled from the back up that
              was conducted after the deadline, reviewed by the date/time stamp
              and compared with the confirmation letter. The decision of the
              World's Richest Playoff Draft Committee regarding discrepancies
              will be final and binding.
            </div>
          ),
        },
        {
          question: "Who do I contact for standings and/or general inquiries?",
          answer: (
            <div>
              Standings and general inquiries will be handled by the NAIT
              Foundation. Contestants can use the hotline at 1-800-875-5871,
              email {SiteMailTo} or visit the website at {SiteLink}.
            </div>
          ),
        },
        {
          question: "What happens if there is a tie?",
          answer:
            "In the event of ties, contestants will split the earnings awarded to all finishing positions from their finisihing position plus the number of contestants with whom they are tied. For example, two contestants tie for second, they split the money paid out to 2nd place and 3rd place. The prize value cannot be less than $50.",
        },
      ],
    },
    {
      title: "Winners and Prizes",
      questions: [
        {
          question: "How will the winners be notified?",
          answer:
            "Round 1, 2 & 3 and the top 10 final winners will be notified by phone.",
        },
        {
          question: "Where can I find a listing of the winners?",
          answer: <div>Go to the website at {SiteLink}.</div>,
        },
        {
          question: "How and when will the winners name be available?",
          answer:
            "The winners will be posted shortly after the stats have been validated after Round 1, Round 2, Round 3, and Stanley Cup finals.",
        },
        {
          question: "What are the prizes for this draft?",
          answer: (
            <div>
              You can view the Prizes in the{" "}
              <a href="/About" target={"_blank"}>
                About
              </a>{" "}
              page. The Rules and Regulations section outlines the full prize
              structure/ breakdown.
            </div>
          ),
        },
        {
          question: "What is the license number for the World’s Richest Draft?",
          answer: "The licence number is 676188.",
        },
      ],
    },
    {
      title: "Other",
      questions: [
        {
          question: "Can I use my username and password from last year?",
          answer: `The website has been redesigned in 2022 to allow contestants to login using their Google, Apple, Facebook or NAIT accounts.
             Due to an update to NAIT's authentication system, all users participating in the 2024 World's Richest Playoff Hockey Draft 
             will need to recreate their account profiles. Those using custom or non-social logins will also need to recreate their username and password. For the 2025 World’s Richest Playoff Hockey Draft, you will be able to use previous credentials to log in.`,
        },
        {
          question: "I purchased entries, but why can I not see them?",
          answer: (
            <div>
              Your session may have timed out. Please try logging our and
              logging back in. If you're still not seeing your entries, you can
              either call the hotline at 1-800-875-5871, or email {SiteMailTo}.
            </div>
          ),
        },
        {
          question:
            "Is there a brochure with the listing of teams and players?",
          answer: (
            <div>
              <div>
                There is no brochure with the listing of teams and players.
                Visit{" "}
                <a href="https://www.nhl.com" target="_blank">
                  www.nhl.com
                </a>{" "}
                for team and player information.
              </div>
              <IncludedTeams teams={state.getIncludedTeams.data?.teams ?? []} />
            </div>
          ),
        },
        {
          question: "What are the benefits of entering online?",
          answer: (
            <div>
              By entering online, changes to player selections can be made up
              until the last minute and must be submitted by the deadline of{" "}
              {playerSelectionDeadline} (the day of the first playoff game).
            </div>
          ),
        },
        {
          question: "How will the proceeds be used?",
          answer:
            "The proceeds of the draft will be used to support NAIT Athletic Scholarships.",
        },
        {
          question:
            "If I want to donate to NAIT to support NAIT athletes, what do I do?",
          answer: (
            <div>
              Go to our online donation form at{" "}
              <a href="https://www.nait.ca/giving" target="_blank">
                www.nait.ca/giving
              </a>
              . Please indicate where you would like your donation directed.
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <div>
      <MainTemplate
        body={
          <div>
            {/* <StyledReactMarkdown skipHtml>{state.mdFaq}</StyledReactMarkdown> */}
            <h1>Frequently Asked Questions</h1>
            {questions.map((section) => {
              return (
                <div style={{ marginBottom: 50 }}>
                  <h2>{section.title}</h2>
                  {section.questions.map((question) => {
                    return (
                      <Question
                        question={question.question}
                        answer={question.answer}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

const StyledReactMarkdown = styled(ReactMarkdown)`
  ol,
  ul {
    margin-left: 20px;
    margin-top: -10px;
  }

  li {
    padding: 3px 0;
  }

  em {
    font-style: italic !important;
  }
  strong {
    font-weight: bold !important;
  }
`;

export default FAQ;
