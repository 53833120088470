import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { performLogin } from "store/getAuthBearerToken";

export type Props = {};

const AdminLogin = (props: Props) => {
  var isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  return (
    <div>
      <button
        className="nait-button nait-button--primary dropdown-button"
        onClick={() => {
          if (!isAuthenticated) {
            performLogin(instance);
          }
        }}
      >
        <span className="i-login"></span> Log In
      </button>
      <button
        className="nait-button nait-button--primary dropdown-button"
        onClick={() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }}
      >
        <span className="i-logout"></span> Log Out
      </button>
    </div>
  );
};

export default AdminLogin;
