import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import getAuthBearerToken from "store/getAuthBearerToken";
import { EntryItem } from "pages/Standings/standings.duck";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "userEntries";

type GetUserEntriesWithPlayersReturn = {
  entries: {
    entryId: number;
    entryName: string;
    playerIds: number[];
  }[];
  teamName: string;
  displayName: string;
  maxPicksPerEntry: number;
  standings: EntryItem[];
};

var getUserEntriesWithPlayers = createAsyncAction<
  GetUserEntriesWithPlayersReturn, //return type
  { userId?: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getUserEntriesWithPlayers",
  url: `${process.env.REACT_APP_API_BASE}/Entries/GetUserEntriesWithPlayers`,
  getAuthBearerToken,
  transformUrl: (url, props) => {
    return `${url}/${props.userId ?? 0}`;
  },
  pending: (state, action) => {
    state.getUserEntries = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getUserEntries.isLoading = false;
    state.getUserEntries.data = action.payload;
  },
  rejected: (state, action) => {
    state.getUserEntries.isLoading = false;
    state.getUserEntries.error = action.payload;
  },
});

var toggleUserEntryPlayer = createAsyncAction<
  {},
  { entryId: number; playerId: number; isSelected: boolean },
  State
>({
  actionPrefix: controlName,
  actionName: "toggleUserEntryPlayer",
  url: `${process.env.REACT_APP_API_BASE}/Entries/UpdateUserEntryPlayerSelection`,
  transformUrl: (url, props) => {
    return `${url}/${props.entryId}/${
      props.playerId
    }/${props.isSelected.toString()}`;
  },
  getAuthBearerToken,
  pending: (state, action) => {
    state.toggleUserEntryPlayer = { isLoading: true };

    const { playerId, entryId, isSelected } = action.params;

    if (
      !state.toggleUserEntryPlayer.data?.find(
        (e) =>
          e.entryId === entryId &&
          e.playerId === playerId &&
          e.isSelected === isSelected
      )
    ) {
      state.toggleUserEntryPlayer.data = [
        ...(state.toggleUserEntryPlayer.data || []),
        {
          playerId,
          entryId,
          isSelected,
        },
      ];
    }

    //update the player list
    var found = state.getUserEntries.data?.entries.find(
      (e) => e.entryId === entryId
    );

    if (found) {
      if (isSelected && !found.playerIds.find((e) => e === playerId)) {
        found.playerIds.push(playerId);
      } else if (!isSelected) {
        var foundIndex = found.playerIds.indexOf(playerId);

        if (foundIndex >= 0) {
          found.playerIds.splice(foundIndex, 1);
        }
      }
    }
  },
  fulfilled: (state, action) => {
    const { playerId, entryId, isSelected } = action.params;

    state.toggleUserEntryPlayer.isLoading = false;

    var found = state.toggleUserEntryPlayer.data?.find(
      (e) =>
        e.entryId === entryId &&
        e.playerId === playerId &&
        e.isSelected === isSelected
    );

    if (found) {
      var data = [...(state.toggleUserEntryPlayer.data || [])];

      var i = data.indexOf(found);
      data.splice(i, 1);
      state.toggleUserEntryPlayer.data = data;
    }
  },
  rejected: (state, action) => {
    state.toggleUserEntryPlayer.isLoading = false;
    state.toggleUserEntryPlayer.error = action.payload;
  },
});

type updateEntryNameReturn = {
  entryId: number;
  entryName: string;
};

var updateEntryName = createAsyncAction<
  updateEntryNameReturn, //return type
  {entryId: number, entryName:string}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "updateEntryName",
  url: `${process.env.REACT_APP_API_BASE}/Entries/UpdateEntryName`,
  getAuthBearerToken,
  postAsJson: true,
  pending: (state, action) => {
    state.updateEntryName = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.updateEntryName.isLoading = false;
    state.updateEntryName.data = action.payload;

    //update the player list
    var found = state.getUserEntries.data?.entries.find(
      (e) => e.entryId === action.payload.entryId
    );

    if (found) {
      found.entryName = action.payload.entryName;
    }
  },
  rejected: (state, action) => {
    state.updateEntryName.isLoading = false;
    state.updateEntryName.error = action.payload;
  },
});

type State = {
  getUserEntries: ReducerState<GetUserEntriesWithPlayersReturn>;
  updateEntryName: ReducerState<updateEntryNameReturn>;
  toggleUserEntryPlayer: ReducerState<
    {
      playerId: number;
      entryId: number;
      isSelected: boolean;
    }[]
  >;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getUserEntries: {
      isLoading: false,
    },
    toggleUserEntryPlayer: {
      isLoading: false,
      data: [],
    },
    updateEntryName: {
      isLoading: false,
    },
  } as State,
  reducers: {
    addDummyEntry: (state) => {
      if (state.getUserEntries.data?.entries.length === 0)
        return {
          ...state,
          getUserEntries: {
            ...state.getUserEntries,
            data: {
              ...state.getUserEntries.data,
              entries: [{ entryId: 0.999, entryName: "", playerIds: [] }],
            },
          },
        };
      return state;
    },
    removeDummyEntry: (state) => {
      if (state.getUserEntries.data?.entries.find((e) => e.entryId === 0.999))
        return {
          ...state,
          getUserEntries: {
            ...state.getUserEntries,
            data: { ...state.getUserEntries.data, entries: [] },
          },
        };
      return state;
    },
  },
  extraReducers: {
    ...getUserEntriesWithPlayers.reducer,
    ...toggleUserEntryPlayer.reducer,
    ...updateEntryName.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getUserEntriesWithPlayers: getUserEntriesWithPlayers.action,
      toggleUserEntryPlayer: toggleUserEntryPlayer.action,
      updateEntryName: updateEntryName.action,
    },
  },
};

export default duck;
