import styled from "styled-components";
import MainTemplate from "components/MainTemplate/MainTemplate";
import React, { useEffect, useRef, useState } from "react";
import { useActions, useReduxState } from "store";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import moment from "moment";
import { Winner } from "./winners.duck";
import { useReactToPrint } from "react-to-print";
import { Article, Print } from "@mui/icons-material";
import { openFile } from "../Reports/Reports";

export type Props = {};

const Winners = (props: Props) => {
  const state = useReduxState((e) => e.winners);
  const actions = useActions().winners;
  const [date, setDate] = useState<string>("");
  const [isDownloadingExcel, setIsDownloadingExcel] = useState<boolean>(false);

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  useEffect(() => {
    return () => {
      actions.reset();
    };
  }, []);

  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  return (
    <div>
      <MainTemplate
        body={
          <div>
            <h1>Winners</h1>
            <div>
              List winners (entries with rank less or equal than 100) on the
              following date:{" "}
            </div>
            <TextField
              style={{ width: 200, marginTop: 5 }}
              label="Date"
              variant="filled"
              value={moment(date).local().format("YYYY-MM-DD")}
              type="date"
              onChange={(e) => {
                const dateString = moment(e.target.value)
                  .endOf("day")
                  .toISOString();
                setDate(dateString);
                actions.loadWinners({ date: dateString });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div ref={printRef} style={{ padding: "40px 0" }}>
              {state.loadWinners.isLoading && <CircularProgress />}
              {!state.loadWinners.isLoading && (
                <>
                  {state.loadWinners.data?.winners && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handlePrint}
                        endIcon={<Print />}
                      >
                        Print All
                      </Button>
                      <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        disabled={isDownloadingExcel}
                        onClick={() => {
                          setIsDownloadingExcel(true);
                          openFile(
                            `${process.env.REACT_APP_API_BASE}/Standings/DownloadWinnersExcel`,
                            "WRD-Winners.xlsx",
                            () => {
                              setIsDownloadingExcel(false);
                            }
                          );
                        }}
                        endIcon={<Article />}
                      >
                        Excel Summary
                      </Button>
                    </>
                  )}
                  {state.loadWinners.data &&
                    state.loadWinners.data.winners.map((c, index) => {
                      return <WinnerItem key={index} winner={c} />;
                    })}
                </>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export type ItemProps = {
  winner: Winner;
};

const WinnerItem = (props: ItemProps) => {
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <WinnerItemContainer ref={printRef}>
      <div style={{ marginBottom: 20, position: "relative" }}>
        <IconButton
          style={{ marginLeft: 5, position: "absolute", left: 175 }}
          onClick={handlePrint}
        >
          <Print />
        </IconButton>
        <Table>
          <tbody>
            <ContactTR>
              <td>Rank</td>
              <td>{props.winner.rank} </td>
            </ContactTR>
            <ContactTR>
              <td>Total Points</td>
              <td>
                <div>{props.winner.total}</div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Team Name</td>
              <td>{props.winner.user.teamName}</td>
            </ContactTR>
            <ContactTR>
              <td>User Name</td>
              <td>
                <div>{props.winner.user.fullName}</div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Address</td>
              <td>
                <div>{props.winner.user.address}</div>
                <div>
                  {props.winner.user.city}, AB, {props.winner.user.postalCode}
                </div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Email</td>
              <td>{props.winner.user.email}</td>
            </ContactTR>
            <ContactTR>
              <td>Phone</td>
              <td>{props.winner.user.phone}</td>
            </ContactTR>
          </tbody>
        </Table>
      </div>
      <Table>
        <thead>
          <TR>
            <TH>Player</TH>
            <TH>Team</TH>
            <TH>Goals</TH>
            <TH>Assists</TH>
            <TH>Total</TH>
          </TR>
        </thead>
        <tbody>
          {props.winner.players.map((c, index) => {
            return (
              <TR key={index}>
                <TD width={175} center={false}>
                  {c.name}
                </TD>
                <TD width={175} center={false}>
                  {c.teamName}
                </TD>
                <TD>{c.goals}</TD>
                <TD>{c.assists}</TD>
                <TD>{c.total}</TD>
              </TR>
            );
          })}
          <TR>
            <TD></TD>
            <TD center={false} style={{ fontWeight: "bold" }}>
              Total
            </TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.goals}</TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.assists}</TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.total}</TD>
          </TR>
        </tbody>
      </Table>
    </WinnerItemContainer>
  );
};

const WinnerItemContainer = styled.div`
  padding: 20px 0;
  width: fit-content;
  border-bottom: dotted 5px black;
  @media print {
    page-break-after: always;
    margin: 20px;
  }
`;

const Table = styled.table`
  font-size: 16px;
  // width: 600px;
`;
const TR = styled.tr<{ noBorder?: boolean }>`
  ${(p) =>
    !p.noBorder &&
    `
      border-bottom: 1px solid #c1c1c1;    
    `}
`;

const ContactTR = styled.tr`
  > td:first-child {
    font-weight: bold;
    width: 110px;
  }
`;
const TD = styled.td<{ width?: number; center?: boolean }>`
  padding: 5px;
  vertical-align: middle;
  ${(p) => `width: ${p.width ? p.width : "100"}px;`}
  ${({ center = true }) => center && `text-align: center`}
`;

const TH = styled.th`
  font-weight: bold;
  padding: 5px;
  text-align: center;
  background-color: #33b3e5;
  color: #fff;
`;

export default Winners;
