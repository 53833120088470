import { createSlice } from "@reduxjs/toolkit";
// import { createAsyncAction, ReducerState } from "components/npmFetch/reduxToolkitUtilities";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import getAuthBearerToken from "store/getAuthBearerToken";
import { GetUserReturn } from "pages/My/userProfile.duck";

const controlName = "profile";

var getUser = createAsyncAction<
  GetUserReturn, //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getUser",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetUser`,
  getAuthBearerToken,
  pending: (state, action) => {
    state.getUser = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.data = action.payload;

    if (!state.getUser.data.userProfileData.contactInformation) {
      state.getUser.data.userProfileData.contactInformation = {};
    }
  },
  rejected: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.error = action.payload;
  },
});

export type GetUserSimpleReturn = {
  id: number;
  displayName: string;
  teamName: string;
  emailAddress?: string;
  profileComplete: boolean;
  isAdmin: boolean;
  isMedia: boolean;
  nhlSeason: number;
};

var getUserSimple = createAsyncAction<
  GetUserSimpleReturn, //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getUserSimple",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetUserSimple`,
  getAuthBearerToken,
  pending: (state, action) => {
    state.getUserSimple = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getUserSimple.isLoading = false;
    state.getUserSimple.data = action.payload;
  },
  rejected: (state, action) => {
    state.getUserSimple.isLoading = false;
    state.getUserSimple.error = action.payload;
  },
});

type UpdateUserProfileReturn = {};

var updateUserProfile = createAsyncAction<
  UpdateUserProfileReturn, //return type
  { data: GetUserReturn; onComplete?: () => void }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "updateUserProfile",
  url: `${process.env.REACT_APP_API_BASE}/Account/UpdateUserProfile`,
  getAuthBearerToken,
  postAsJson: true,
  transformQueryParams: (params) => {
    return params.data;
  },
  pending: (state, action) => {
    state.updateUserProfile = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.updateUserProfile.isLoading = false;
    state.updateUserProfile.data = action.payload;
    state.getUserSimple = {
      isLoading: false,
    };

    action.params.onComplete?.();
  },
  rejected: (state, action) => {
    state.updateUserProfile.isLoading = false;
    state.updateUserProfile.error = action.payload;
  },
});

type State = {
  getUser: ReducerState<GetUserReturn>;
  getUserSimple: ReducerState<GetUserSimpleReturn>;
  updateUserProfile: ReducerState<UpdateUserProfileReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getUser: {
      isLoading: false,
    },
    getUserSimple: {
      isLoading: false,
    },
    updateUserProfile: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getUser.reducer,
    ...updateUserProfile.reducer,
    ...getUserSimple.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getUser: getUser.action,
      updateUserProfile: updateUserProfile.action,
      getUserSimple: getUserSimple.action,
    },
  },
};

export default duck;
