import styled from "styled-components";

export type Props = {
  playerId: number;
  teamId?: number;
  eagerLoading?: boolean;
  size?: "sm" | "md" | "lg";
};

var teamAbbreviations = [{
  id: 1,
  abbreviation: "NJD",
}, {
  id: 2,
  abbreviation: "NYI",
}, {
  id: 3,
  abbreviation: "NYR",
}, {
  id: 4,
  abbreviation: "PHI",
}, {
  id: 5,
  abbreviation: "PIT",
}, {
  id: 6,
  abbreviation: "BOS",
}, {
  id: 7,
  abbreviation: "BUF",
}, {
  id: 8,
  abbreviation: "MTL",
}, {
  id: 9,
  abbreviation: "OTT",
}, {
  id: 10,
  abbreviation: "TOR",
}, {
  id: 12,
  abbreviation: "CAR",
}, {
  id: 13,
  abbreviation: "FLA",
}, {
  id: 14,
  abbreviation: "TBL",
}, {
  id: 15,
  abbreviation: "WSH",
}, {
  id: 16,
  abbreviation: "CHI",
}, {
  id: 17,
  abbreviation: "DET",
}, {
  id: 18,
  abbreviation: "NSH",
}, {
  id: 19,
  abbreviation: "STL",
}, {
  id: 20,
  abbreviation: "CGY",
}, {
  id: 21,
  abbreviation: "COL",
}, {
  id: 22,
  abbreviation: "EDM",
}, {
  id: 23,
  abbreviation: "VAN",
}, {
  id: 24,
  abbreviation: "ANA",
}, {
  id: 25,
  abbreviation: "DAL",
}, {
  id: 26,
  abbreviation: "LAK",
}, {
  id: 28,
  abbreviation: "SJS",
}, {
  id: 29,
  abbreviation: "CBJ",
}, {
  id: 30,
  abbreviation: "MIN",
}, {
  id: 52,
  abbreviation: "WPG",
}, {
  id: 53,
  abbreviation: "ARI",
}, {
  id: 54,
  abbreviation: "VGK",
},{
  id: 55,
  abbreviation: "SEA",
}];

const PlayerAvatar = (props: Props) => {
  const { teamId, playerId, size = "sm" } = props;

  return (
    <div style={{ whiteSpace: "nowrap", display: "flex" }}>
      <div>
        <PlayerAvatarImage
          loading={props.eagerLoading ? "eager" : "lazy"}
          size={size === "sm" ? 40 : size === "md" ? 60 : 100}
          src={`https://assets.nhle.com/mugs/nhl/latest/${playerId}.png`}
        />
      </div>
      {teamId && (
        <div
          style={{
            marginLeft: size === "sm" ? -20 : size === "md" ? -30 : -40,
            marginTop: size === "sm" ? 10 : size === "md" ? 20 : 60,
            marginBottom: -10,
          }}
        >
          <TeamAvatar
            teamId={teamId}
            size={size === "sm" ? "sm" : "md"}
            eagerLoading={props.eagerLoading}
          />
        </div>
      )}
    </div>
  );
};

const TeamAvatar = ({
  teamId,
  eagerLoading,
  size = "sm",
}: {
  teamId: number;
  eagerLoading?: boolean;
  size?: "sm" | "md" | "lg";
}) => {
  return (
    <TeamLogoImage
      loading={eagerLoading ? "eager" : "lazy"}
      size={size === "sm" ? 40 : size === "md" ? 60 : 100}
      // src={`https://www-league.nhlstatic.com/images/logos/teams-current-primary-light/${teamId}.svg`}
      src={`https://assets.nhle.com/logos/nhl/svg/${teamAbbreviations.find(e=>e.id == teamId)?.abbreviation}_light.svg`}
    />
  );
};

const TeamLogoImage = styled.img<{ size?: number }>`
  height: ${({ size = 40 }) => size}px;
  width: ${({ size = 40 }) => size}px;
`;

const PlayerAvatarImage = styled.img<{ size?: number }>`
  height: ${({ size = 40 }) => size}px;
  width: ${({ size = 40 }) => size}px;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #999999;
  background: #efefef;
  // background-size: 100%;
  // display: inline-block;
  //   height: 28px;
  //   width: 28px;
  // vertical-align: middle;
  // background-position: center;
`;

export { TeamAvatar };

export default PlayerAvatar;
