import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import JSONPretty from "react-json-pretty";
import { Account } from "../accounts.duck";
import { DetailRow, Table, TR } from "pages/Admin/sharedStyledComponents";

type Props = {
  accounts: Account[];
  isLoading: boolean;
  onChangeUserRole: (userId: number, role: number) => void;
};

const AccountsTable = (props: Props) => {
  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Auth</th>
          </tr>
        </thead>
        <tbody>
          {props.isLoading && (
            <tr>
              <td colSpan={6}>
                <CircularProgress />
              </td>
            </tr>
          )}
          {!props.isLoading &&
            props.accounts.map((c) => {
              const expanded = expandedIds.indexOf(c.id) >= 0;
              return (
                <>
                  <TR
                    key={c.id}
                    onClick={() => {
                      expanded
                        ? setExpandedIds(expandedIds.filter((d) => d !== c.id))
                        : setExpandedIds([...expandedIds, c.id]);
                    }}
                  >
                    <td>{expanded ? <ExpandLess /> : <ExpandMore />}</td>
                    <td>{c.id}</td>
                    <td>{c.displayName}</td>
                    <td>{c.email}</td>
                    <td>
                      {c.role === 0
                        ? "Contestant"
                        : c.role === 1
                        ? "Admin"
                        : c.role === 2
                        ? "Media"
                        : "undefined"}
                    </td>
                    <td>{c.authType}</td>
                  </TR>
                  {expanded && (
                    <tr style={{borderBottom:'solid 1px gray'}}>
                      <td colSpan={7} style={{ paddingLeft: 40 }}>
                        <DetailRow>
                          <div>Role:</div>
                          <div>
                            <select
                              className="nait-select"
                              value={c.role}
                              onChange={(e) => {
                                  props.onChangeUserRole(c.id, parseInt(e.target.value));
                              }}
                            >
                              <option value={0}>Contestant</option>
                              <option value={1}>Admin</option>
                              <option value={2}>Media</option>
                            </select>
                          </div>
                        </DetailRow>
                        <DetailRow>
                          <div>External Id:</div>
                          <div>{c.externalUserId}</div>
                        </DetailRow>
                        <DetailRow>
                          <div>Profile Json:</div>
                          <div>
                            <JSONPretty data={c.profileDataJson} />
                          </div>
                        </DetailRow>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default AccountsTable;

