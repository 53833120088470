import styled from "styled-components";

export type Props = {
  ticketNumber?: number | string;
  complete?: boolean;
  size?: "sm" | "md" | "lg";
};

const digits = 3;

const Ticket = (props: Props) => {
  const { ticketNumber = 0, complete = false, size = "sm" } = props;

  var ts = ticketNumber.toString();

  //if number, then pad 0's
  var val = +ts === +ticketNumber ? ts.padStart(digits, "0") : ticketNumber;

  return (
    <>
      <Stub complete={complete} size={size}>
        <div>{val}</div>
      </Stub>
    </>
  );
};

const Stub = styled.div<{ complete: boolean; size: "sm" | "md" | "lg" }>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='100%' viewBox='0 0 45 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.617188 2.88218C0.617188 1.69433 1.58013 0.731384 2.76798 0.731384H41.9863C43.1741 0.731384 44.1371 1.69433 44.1371 2.88218V8.24314C41.6995 9.55177 40.0421 12.1249 40.0421 15.0852C40.0421 18.0455 41.6995 20.6186 44.1371 21.9272V27.2881C44.1371 28.4759 43.1741 29.4389 41.9863 29.4389H2.76798C1.58013 29.4389 0.617188 28.4759 0.617188 27.2881V21.8284C2.95679 20.4917 4.53369 17.9726 4.53369 15.0852C4.53369 12.1978 2.95679 9.67863 0.617188 8.34202V2.88218Z' 
    fill='%23${({ complete }) => (complete ? `17A500` : `C4C4C4`)}'
    /%3E%3C/svg%3E");

  background-repeat: no-repeat;
  background-size: cover;

  user-select: none;

  ${({ size }) =>
    size === "sm"
      ? `
        width:45px;
        height: 30px;
        font-size: 12px;
      `
      : size === "md"
      ? `
        width:55px;
        height: 37px;
        font-size: 14px;
      `
      : `
        width:65px;
        height: 43px;
        font-size: 16px;
      `}

  ${({ complete }) =>
    complete
      ? `
      color: white;
    `
      : `
      color: #666666;
  `}
`;

export default Ticket;
