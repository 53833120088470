import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

//uncomment if you need msal auth

const controlName = "userProfile";

type UserProfileData = {
  notificationOptions?: {
    roundConcluded?: boolean;
    nextYearDraft?: boolean;
  };
  contactInformation: UserContactInformation;
  howDidYouHearAboutUs?: string;
};

export type UserContactInformation = {
  city?: string;
  province?: string;
  postalCode?: string;
  phoneNumber?: string;
  address?: string;
  addressLine2?: string;
};

export type UserOrder = {
  id: number;
  total: number;
  taxes: number;
  subtotal: number;
  dateCreated: Date;
  transactionId: string;
  numberOfEntries: number;
  entryIds: number[];
};

export type GetUserReturn = {
  id: number;
  displayName?: string;
  teamName?: string;
  emailAddress?: string;
  userProfileData: UserProfileData;
  userOrders: UserOrder[];
  providerName: "NAIT" | "Google" | "Custom";
};

var getUser = createAsyncAction<
  GetUserReturn, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getUser",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetUser`,
  getAuthBearerToken,
  pending: (state, action) => {
    state.getUser = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.data = action.payload;
  },
  rejected: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.error = action.payload;
    console.log(action.payload);
  },
});

type State = { getUser: ReducerState<GetUserReturn> };

var slice = createSlice({
  name: controlName,
  initialState: {
    getUser: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getUser.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getUser: getUser.action,
    },
  },
};

export default duck;
