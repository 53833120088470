import { useDeviceSize } from "hooks/useWindowSize";
import styled from "styled-components";

export type Props = {};

const Prizes = (props: Props) => {
  const deviceSize = useDeviceSize(1000, 600);

  return (
    <PrizeWrapper smaller={deviceSize.small}>
      <Section style={{ borderRadius: 10 }}>
        <PrizeItemRow left="Round 1 Leader" prizeAmount="$1,000" />
        <PrizeItemRow left="Round 2 Leader" prizeAmount="$1,000" />
        <PrizeItemRow left="Round 3 Leader" prizeAmount="$1,000" />
      </Section>
      <Section>
        <BigPrizes>
          <div>
            <GrandPrizeHeading>Grand Prize</GrandPrizeHeading>
            <GrandPrizeValue>$50,000</GrandPrizeValue>
          </div>
          <SecondaryPrizeRow>
            <SecondaryPrizeSection>
              <SecondaryPrizeHeading>2nd Prize</SecondaryPrizeHeading>
              <SecondaryPrizeValue>$10,000</SecondaryPrizeValue>
            </SecondaryPrizeSection>
            <SecondaryPrizeSection>
              <SecondaryPrizeHeading>3rd Prize</SecondaryPrizeHeading>
              <SecondaryPrizeValue>$5,000</SecondaryPrizeValue>
            </SecondaryPrizeSection>
          </SecondaryPrizeRow>
        </BigPrizes>
      </Section>
      <Section>
        <PrizeItemRow
          left={
            <div>
              4
              <sup>
                <small>th</small>
              </sup>
              -10
              <sup>
                <small>th</small>
              </sup>{" "}
            </div>
          }
          prizeAmount="$1,000"
        />
        <PrizeItemRow
          left={
            <div>
              11
              <sup>
                <small>th</small>
              </sup>
              -20
              <sup>
                <small>th</small>
              </sup>{" "}
            </div>
          }
          prizeAmount="$500"
        />
        <PrizeItemRow
          left={
            <div>
              21
              <sup>
                <small>st</small>
              </sup>
              -30
              <sup>
                <small>th</small>
              </sup>{" "}
            </div>
          }
          prizeAmount="$250"
        />
        <PrizeItemRow
          left={
            <div>
              31
              <sup>
                <small>st</small>
              </sup>
              -60
              <sup>
                <small>th</small>
              </sup>{" "}
            </div>
          }
          prizeAmount="$100"
        />
        <PrizeItemRow
          left={
            <div>
              61
              <sup>
                <small>st</small>
              </sup>
              -100
              <sup>
                <small>th</small>
              </sup>{" "}
            </div>
          }
          prizeAmount="$50"
        />
      </Section>
    </PrizeWrapper>
  );
};

const PrizeItemRow = (props: {
  left: string | JSX.Element;
  prizeAmount: string;
}) => {
  const { left, prizeAmount } = props;
  return (
    <PrizeRow>
      <PrizeRowLeft>{left}</PrizeRowLeft>
      <PrizeRowRight>{prizeAmount}</PrizeRowRight>
    </PrizeRow>
  );
};

const GrandPrizeHeading = styled.div`
  font-size: 40px;
`;
const GrandPrizeValue = styled.div`
  font-size: 40px;
  color: #efcc11;
`;

const SecondaryPrizeRow = styled.div`
  font-size: 25px;
  display: flex;
  border-top: 3px solid #019aff;
  padding-top: 10px;
`;

const SecondaryPrizeSection = styled.div`
  width: 50%;
`;
const SecondaryPrizeHeading = styled.div``;
const SecondaryPrizeValue = styled.div`
  color: #efcc11;
`;

const BigPrizes = styled.div`
  font-family: "Industry", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  background-color: #014b8e;
  padding: 20px;
`;

const PrizeRowLeft = styled.div`
  width: 100%;
`;

const PrizeRowRight = styled.div``;

const PrizeRow = styled.div`
  display: flex;
  font-size: 20px;
  padding: 10px 20px;
`;

const Section = styled.div`
  border-top: 8px solid #efcd13;
`;

const PrizeWrapper = styled.div<{smaller?:boolean}>`
  background-color: #009aff;
  color: white;
  max-width: ${props => props.smaller ? '300px': '400px'};
  border-radius: 10px;
`;

export default Prizes;
