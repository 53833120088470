import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "adminReports";

export type SalesSummary = {
  totalSales: number;
  bundlesCount: number;
  singlesCount: number;
  userCount: number;
};

var getSalesSummary = createAsyncAction<
  SalesSummary, //return type
  { season?:number}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getSalesSummary",
  getAuthBearerToken,
  postAsJson:true,
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetSalesSummary`,
  pending: (state, action) => {
    state.salesSummary = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.salesSummary.isLoading = false;
    state.salesSummary.data = action.payload;
  },
  rejected: (state, action) => {
    state.salesSummary.isLoading = false;
    state.salesSummary.error = action.payload;
  },
});

var getAvailableSeasons = createAsyncAction<
  { availableSeasons:number[] }, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getAvailableSeasons",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetAvailableSeasons`,
  pending: (state, action) => {
  },
  fulfilled: (state, action) => {
    state.availableSeasons = action.payload.availableSeasons;
  },
  rejected: (state, action) => {
  },
});

type State = {
  salesSummary: ReducerState<SalesSummary>;
  availableSeasons: number[];
};

var slice = createSlice({
  name: controlName,
  initialState: {
    salesSummary: {
      isLoading: false,
    },
    availableSeasons: [],
  } as State,
  reducers: {},
  extraReducers: {
    ...getSalesSummary.reducer,
    ...getAvailableSeasons.reducer,
  }
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getSalesSummary: getSalesSummary.action,
      getAvailableSeasons: getAvailableSeasons.action,
    },
  },
};

export default duck;
