import { SearchTextField } from "@nait-aits/ui";
// import CheckboxButton from "components/CheckboxButton/CheckboxButton";
import { Player } from "components/PlayerGrid/PlayerGridTypes";
import { useEffect, useState } from "react";

export type SearchOptions = "MYENTRIES" | "ALL";

export type Props = {
  searchText?: string;
  onSearchTextChange?(value: string, searchOptions: SearchOptions): void;
  allPlayers: Player[];
};

const SearchField = (props: Props) => {
  const { onSearchTextChange, searchText } = props;

  // const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [query, setQuery] = useState(searchText);

  // const [searchPlayerOptions, setSearchPlayerOptions] =
  //   useState<SearchOptions>("ALL");

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query !== searchText) {
        onSearchTextChange?.(query || "", "ALL");
      }
    }, 500);

    return () => clearTimeout(timeOutId);
  }, [query, onSearchTextChange, "ALL"]);

  return (
    <div id="divSearchPlayerTeam">
      <SearchTextField
        value={query || ""}
        placeholder="Search by Player or Team..."
        onChange={(e) => {
          setQuery(e);
          //   onSearchTextChange?.(e, searchPlayerOptions);
        }}
        closeOnEnterKey={true}
        // flyoutPanel={{
        //   //   show: true,
        //   stayOn: false,
        //   content: (
        //     // <FlyoutPanel
        //     //   players={allPlayers.filter((e) => e.isAvailable)}
        //     //   searchString={query || ""}
        //     //   onApplySearchFilter={(filter) => {
        //     //     setQuery(filter);
        //     //     // setAppliedSearchText(filter);
        //     //     // setSearchText(filter);
        //     //   }}
        //     // />
        //     <div style={{ padding: 20, zIndex: 1 }}>
        //       <div>Search Players From:</div>
        //       <div style={{ margin: 10 }}>
        //         <div style={{ display: "flex", fontSize: 14 }}>
        //           <div>
        //             <CheckboxButton
        //               state={
        //                 searchPlayerOptions === "MYENTRIES"
        //                   ? "checked"
        //                   : "unchecked"
        //               }
        //               onClick={() => {
        //                 setSearchPlayerOptions("MYENTRIES");

        //                 onSearchTextChange?.(searchText || "", "MYENTRIES");
        //               }}
        //             />
        //           </div>
        //           <div style={{ margin: "auto 0 auto 10px" }}>
        //             All My Entries
        //           </div>
        //         </div>
        //         <div style={{ display: "flex", fontSize: 14 }}>
        //           <div>
        //             <CheckboxButton
        //               state={
        //                 searchPlayerOptions === "ALL" ? "checked" : "unchecked"
        //               }
        //               onClick={() => {
        //                 setSearchPlayerOptions("ALL");
        //                 onSearchTextChange?.(searchText || "", "ALL");
        //               }}
        //             />
        //           </div>
        //           <div style={{ margin: "auto 0 auto 10px" }}>All Players</div>
        //         </div>
        //       </div>
        //       <div style={{ color: "#acacac", fontSize: 14 }}>
        //         Search results limited to 100 players
        //       </div>
        //     </div>
        //   ),
        // }}
        onFocusChanged={(b) => {
          //   console.log("a", b);
          //   setShowSearchOptions(false);
        }}
      />
    </div>
  );
};

export default SearchField;
