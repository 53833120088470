import { createSlice } from "@reduxjs/toolkit";
import { ReducerState, createAsyncAction } from "@nait-aits/redux";
import { PlayerJson } from "./apiTypes";

function getYearStats(state: State, year: number) {
  var found = state.playerStats.find((e) => e.id === year);

  if (!found) {
    found = {
      id: year,
      data: {
        isLoading: false,
      },
    };
    state.playerStats.push(found);
  }

  return found;
}

const controlName = "playerListing";

var getPlayerStats = createAsyncAction<
  PlayerJson[], //return type
  { year: number; playerIds?: number[] }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "GetPlayerStatsForYear",
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetPlayerStatsForYear`,
  transformUrl: (url, params) => {
    return `${url}/${params.year}`;
  },
  pending: (state, action) => {
    var found = getYearStats(state, action.params.year);

    found.data.isLoading = true;
  },
  fulfilled: (state, action) => {
    var found = getYearStats(state, action.params.year);
    found.data = {
      isLoading: false,
      data: action.payload,
    };
  },
  rejected: (state, action) => {
    var found = getYearStats(state, action.params.year);
    found.data = {
      isLoading: false,
      error: action.payload,
    };
  },
  // postAsJson: true
});

type State = {
  playerStats: {
    id: number;
    data: ReducerState<PlayerJson[]>;
  }[];
};

var slice = createSlice({
  name: controlName,
  initialState: {
    playerStats: [],
  } as State,
  reducers: {},
  extraReducers: {
    ...getPlayerStats.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getPlayerStats: getPlayerStats.action,
    },
  },
};

export default duck;
