import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";

const controlName = "mainTemplate";

type State = {
  getSettings: ReducerState<GetSettingsReturn>;
};

// const baseURL = `${process.env.REACT_APP_API_BASE}/entry`;

type GetSettingsReturn = {
  settings: {
    season: number;
    entryOpenDateGMT: string;
    entryCloseDateGMT: string;
    playerPickCloseDateGMT: string;
    maxPicksPerEntry: number;
    maxSingleEntries: number;
    maxBundles: number;
    singleEntryPrice: number;
    bundleEntryPrice: number;
    allowedTeamIds: number[];
    isDraftOpen:boolean;
    isPlayerPickOpen:boolean;
    draftHasNotStarted:boolean;
    draftHasEnded:boolean;
  };
};

var getSettings = createAsyncAction<GetSettingsReturn, {}, State>({
  actionPrefix: controlName,
  actionName: "getSettings",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetSettings`,
  pending: (state, action) => {
    state.getSettings.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.getSettings.isLoading = false;
    state.getSettings.data = action.payload;
  },
  rejected: (state, action) => {},
});

var slice = createSlice({
  name: controlName,
  initialState: {
    getSettings: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers: {
    ...getSettings.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getSettings: getSettings.action,
    },
  },
};
export default ret;
