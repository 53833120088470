//The commented items show how to include ducks
import settings from "./Setup/settings.duck";
import contestantListing from "./ContestantListing/contestantListing.duck";
import reports from "./Reports/reports.duck";
import beta from "./Beta/reducer";
import orders from "./Orders/orders.duck";
import winners from "./Winners/winners.duck";
import accounts from "./Accounts/accounts.duck";
import logs from "./Logs/logs.duck";

const index = {
  reducer: {
    ...settings.reducer,
    ...reports.reducer,
    ...contestantListing.reducer,
    ...beta.reducer,
    ...orders.reducer,
    ...winners.reducer,
    ...accounts.reducer,
    ...logs.reducer,
  },
  actions: {
    ...settings.actions,
    ...reports.actions,
    ...contestantListing.actions,
    ...beta.actions,
    ...orders.actions,
    ...winners.actions,
    ...accounts.actions,
    ...logs.actions,
  },
};

export default index;
