import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "goal";

export type Goal = {
  totalRaised: number;
  bundles: number;
  singles: number;
};

var loadGoals = createAsyncAction<
  Goal, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadGoal",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetGoal`,
  pending: (state, action) => {
    state.goal = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.goal.isLoading = false;
    state.goal.data = action.payload;
  },
  rejected: (state, action) => {
    state.goal.isLoading = false;
    state.goal.error = action.payload;
  },
});

type State = {
  goal: ReducerState<Goal>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    goal: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadGoals.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadGoals: loadGoals.action,
    },
  },
};

export default duck;
