import { MenuItem, Select, TextField } from "@mui/material";
import MainTemplate from "components/MainTemplate/MainTemplate";
import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import { NavButton, NavPageText, Nav } from "../sharedStyledComponents";
import LogsTable from "./components/LogsTable";

export type Props = {};

const Logs = (props: Props) => {
  const state = useReduxState((e) => e.logs);
  const actions = useActions().logs;
  const [searchText, setSearchText] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [logTypeFilter, setLogTypeFilter] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);

  var searchDelay: NodeJS.Timeout;

  useEffect(() => {
    actions.getLogTypes({});
  }, []);

  useEffect(() => {
    searchDelay = setTimeout(() => {
      actions.getLogs({
        searchText,
        fromDate,
        toDate,
        page,
        logType: logTypeFilter,
      });
    }, 300);
    return () => {
      clearTimeout(searchDelay);
    };
  }, [searchText]);

  const totalRecords = state.getLogs.data?.totalRecords ?? 0;
  const pageTotal = Math.ceil(totalRecords / 20);

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  return (
    <MainTemplate
      body={
        <div>
          <h1>Logs</h1>
          <div style={{ marginBottom: 20 }}>
            <TextField
              style={{ width: 400, marginRight: 10, marginBottom: 10 }}
              label="Search by id, reference"
              variant="filled"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Select
              style={{ marginRight: 10 }}
              value={logTypeFilter}
              onChange={(e) => {
                setLogTypeFilter(e.target.value as number);
                actions.getLogs({
                  searchText,
                  fromDate,
                  toDate,
                  page,
                  logType: e.target.value as number,
                });
              }}
            >
              <MenuItem value={-1}>All Types</MenuItem>
              {state.getLogTypes.data?.logTypes.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              style={{ width: 200, marginRight: 10 }}
              label="From"
              variant="filled"
              value={fromDate}
              type="date"
              onChange={(e) => {
                setFromDate(e.target.value);
                actions.getLogs({
                  searchText,
                  page,
                  fromDate: e.target.value,
                  toDate,
                  logType: logTypeFilter,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              style={{ width: 200 }}
              label="To"
              variant="filled"
              value={toDate}
              type="date"
              onChange={(e) => {
                setToDate(e.target.value);
                actions.getLogs({
                  searchText,
                  page,
                  fromDate,
                  toDate: e.target.value,
                  logType: logTypeFilter,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <LogsTable
            logs={state.getLogs.data?.logs || []}
            isLoading={state.getLogs.isLoading}
            logTypeList={state.getLogTypes.data?.logTypes || []}
          />
          <Nav>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === 1}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page - 1);
                  actions.getLogs({
                    searchText,
                    fromDate,
                    toDate,
                    page: page - 1,
                    logType: logTypeFilter,
                  });
                }}
              >
                Previous
              </button>
            </NavButton>
            <NavPageText>
              Page {page} of {pageTotal} ({totalRecords} records)
            </NavPageText>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === pageTotal || pageTotal === 0}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page + 1);
                  actions.getLogs({
                    searchText,
                    fromDate,
                    toDate,
                    page: page + 1,
                    logType: logTypeFilter,
                  });
                }}
              >
                Next
              </button>
            </NavButton>
          </Nav>
        </div>
      }
    />
  );
};

export default Logs;
