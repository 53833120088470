import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "accounts";

export type Account = {
  id: number;
  email: string;
  role: number;
  displayName: string;
  authType: string;
  profileDataJson: string;
  externalUserId: string;
};

type SearchUserReturn = {
  users: Account[];
  totalRecords: number;
};

var searchUser = createAsyncAction<
  SearchUserReturn, //return type
  { searchText: string; 
    includeDetails: boolean,
    role: number,
    authType?: string,
    entryYear?: number, 
    page: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "searchUser",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/GetUsers`,
  pending: (state, action) => {
    state.searchUser = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.searchUser.isLoading = false;
    state.searchUser.data = action.payload;
  },
  rejected: (state, action) => {
    state.searchUser.isLoading = false;
    state.searchUser.error = action.payload;
  },
});

type changeUserRoleReturn = {
  userId: number;
  role: number;
};

var changeUserRole = createAsyncAction<
  changeUserRoleReturn, //return type
  { userId: number; role: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "changeUserRole",
  url: `${process.env.REACT_APP_API_BASE}/AdminHelper/ChangeUserRole`,
  getAuthBearerToken,
  postAsJson: true,
  pending: (state, action) => {
    state.changeUserRole = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.changeUserRole.isLoading = false;
    state.changeUserRole.data = action.payload;
  },
  rejected: (state, action) => {
    state.changeUserRole.isLoading = false;
    state.changeUserRole.error = action.payload;
  },
});

type State = {
  searchUser: ReducerState<SearchUserReturn>;
  changeUserRole: ReducerState<changeUserRoleReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    searchUser: {
      isLoading: false,
    },
    changeUserRole: {
        isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...searchUser.reducer,
    ...changeUserRole.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      searchUser: searchUser.action,
      changeUserRole: changeUserRole.action,
    },
  },
};

export default duck;
