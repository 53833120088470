import styled from "styled-components";
// import moment from "moment";
import { useDeviceSize } from "hooks/useWindowSize";
import { useReduxState } from "store";
import moment from "moment-timezone";

export type Props = {
  draftMaxPicks: number;
  draftEndDate: string;
  draftStartDate: string;
  season: number;
};

const Rules = ({ draftMaxPicks, season }: Props) => {
  const settingsState = useReduxState((e) => e.mainTemplate.getSettings);
  const deviceSize = useDeviceSize(1000, 600);
  const startDateString = settingsState.data
    ? moment(settingsState.data.settings.entryOpenDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY")
    : "TBA";
  const endDateString = settingsState.data
    ? `${moment(settingsState.data.settings.entryCloseDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY hh:mm A")} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const endDateString = "TBD";
  const playerPickEndDateString = settingsState.data
    ? `${moment(settingsState.data.settings.playerPickCloseDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY h:mm A")} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const playerPickEndDateString = "TBD";

  return (
    <Wrapper>
      <Section>
        <h2>Entry Form Requirements and Sales</h2>
        <ol>
          <li>
            Limited to the first 7,999 entries (1,000 entries @ $50 and 6,999
            entries @ 3 for $100).
          </li>
          <li>
            Online entries can be purchased at{" "}
            <a href="https://www.worldsrichestdraft.com">
              www.worldsrichestdraft.com
            </a>{" "}
            and will be available for purchase starting {startDateString} until{" "}
            {endDateString}.
          </li>
          <li>
            Online entry selection can be made until the deadline of{" "}
            {playerPickEndDateString}.
          </li>
          <li>
            Participants are entered into an Early Bird Draw if they have
            purchased at least one (1) entry by the Early Bird Deadline.
          </li>
          <li>
            Early Bird purchase dates run from {startDateString} to 11:59pm on
            March 31, 2024. Any participant that purchases at least one (1)
            entry during that time will be entered to win a prize basket of NAIT
            branded items, donated by the NAIT Athletics Department. A random
            draw will be held on April 2, 2024 to determine the winner of the
            early bird prize basket. The winner will be notified by email along
            with instructions for pick up.
          </li>
          <li>
            Entry fees are: 1 for $50 or 3 for $100. Acceptable payment methods
            are: Visa, MasterCard, American Express, and Debit Credit Cards.
          </li>
          <li>
            There is no limit on the number of entries permitted per individual
            or household.
          </li>
          <li>
            Online entries shall not be sold to a person under the age of 18
            years. The NAIT Foundation will advise entry buyers to refrain from
            including the name of a person under the age of 18 years when
            purchasing online entries due to the requirements of the Public
            Trustee Act.
          </li>
          <li>
            The NAIT Foundation trustees and officers, World's Richest Playoff
            Draft Committee, together with members of their immediate family are
            not eligible to purchase entries.
          </li>
          <li>
            The NAIT Foundation reserves the right to cancel the Draft and
            refund all entry fees if less than 4000 entries have been confirmed
            by the entry deadline.
          </li>
        </ol>
      </Section>

      <Section>
        <h2>Draft Operations and Statistics</h2>
        <ol>
          <li>
            The World's Richest Playoff Draft Committee members are responsible
            for sales, management, and review of the hockey draft are:
          </li>
          <br />
          Edward Elyn, Application Development
          <br />
          Dimas Ariawan, Application Development
          <br />
          Andy MacIver, NAIT Department of Athletics
          <br />
          Sarah Mattiello, NAIT Department of Athletics
          <br />
          Sara Roper, NAIT Foundation
          <br />
          Katie Marshman, NAIT Foundation
          <br />
          <br />
          {/* <li>
            NHL teams and players will be listed in alphabetical order.
            Contestants are required to select {draftMaxPicks} players of their
            choice from the list provided.
          </li> */}
          <li>
            NHL players will initially be listed and sorted based on their
            regular season points on the entry editor page. Additional search
            and sort options are available by player name and team name.{" "}
          </li>
          <li>
            <b>
              Under no circumstances shall online entries be changed or modified
              after the deadline - {playerPickEndDateString}.
            </b>
          </li>
          <li>
            Only official National Hockey League statistics will be used. One
            (1) point awarded for each goal or assist scored during the{" "}
            {season + 1} Stanley Cup Playoffs. Each individual entry standing is
            computer generated by a designated employee of the NAIT Foundation.
            Standings are updated daily and posted on the draft website within
            24 hours of receiving the NHL stats. Exact dates are unavailable as
            rounds can be completed in 4-7 games.
          </li>
          <li>
            The NAIT Foundation is not responsible for cancelled games that may
            affect player selections and points.
          </li>
          <li>
            In the event of ties, contestants will split the earnings awarded to
            all finishing positions from their finisihing position plus the
            number of contestants with whom they are tied. For example, two
            contestants tie for second, they split the money paid out to 2nd
            place and 3rd place. The prize value cannot be less than $50.
          </li>
          <li>
            The decision of the World's Richest Playoff Draft Committee will be
            final and binding. If a dispute arises from the results provided by
            the NAIT Foundation, an independent accounting firm shall
            investigate it. A report will be submitted to the Alberta Gaming &
            Liquor Commission.
          </li>
          <li>
            All contestants will receive confirmation letters for <u>each</u>{" "}
            individual entry purchased including Round 1 statistics. This will
            be considered the entrant's receipt. All confirmation letters will
            be sent to the email address provided upon registration.
            <br />
            As per CASL -{" "}
            <a
              href="https://www.fightspam.gc.ca/eic/site/030.nsf/eng/home"
              target="_blank"
              rel="noreferrer"
            >
              Canadian anti-spam legislature
            </a>
            - any contestants who wish to opt out of sending confirmation
            letters to their email addresses (because of security concerns) can
            do so during the registration process.
            <br />
            <br />
            Any discrepancies are to be reported in writing to:
            <br />
            <b>NAIT Foundation</b>
            <br />
            <b>11762 106 Street NW</b>
            <br />
            <b>Edmonton, Alberta T5G 2R1</b>
            <br />
            <br />
            In the event of the reported discrepancy, the data file will be
            pulled from the back up that was conducted after the deadline,
            reviewed by the date/time stamp and compared with the confirmation
            letter.
            <br />
            The decision of the World's Richest Playoff Draft Committee
            regarding discrepancies will be final and binding.
          </li>
          <li>
            Standings and general inquiries will be handled by the NAIT
            Foundation. Contestants can use the hotline at 1-800-875-5871 or
            visit the website at{" "}
            <a href="https://www.worldsrichestdraft.com">
              www.worldsrichestdraft.com
            </a>
            .
          </li>
          {/* <%--<br />
            <br />
            A list of all winners will also be posted on a wall by the entrance door to the NAIT Department of Athletics located at:
            <br />
            <b>Room S105</b><br />
            <b>11762 106 Street NW</b><br />
            <b>Edmonton, Alberta T5G 2R1</b><br />
            <br />
            The list will include entry number, rank, team name, total points accumulated and the prize value. If the team name is not provided on an entry form, the entry number will be listed in that field. Round 1 & 2 and the top 10 final winners will be notified by phone.</li>--%> */}
          <li>
            The NAIT Foundation will provide a copy of any standings or results
            posted for the draft to any contestant who makes a specific request
            in writing. The standings or results will be sent to the contestant
            by the email provided at registration.
          </li>
        </ol>
      </Section>

      <Section>
        <h2>Prize Definition and Structure</h2>
        <div style={{ marginBottom: 20 }}>
          <PrizeTitle>First Round Leader ..... $1,000.00</PrizeTitle>
          <PrizeDescription>
            Entrant with the most points accumulated from the start of the{" "}
            {season + 1} NHL Playoffs until the completion of the first round.
          </PrizeDescription>
          <PrizeTitle>Second Round Leader ..... $1,000.00</PrizeTitle>
          <PrizeDescription>
            Entrant with the most points accumulated from the start of the{" "}
            {season + 1} NHL Playoffs until the completion of the second round.
          </PrizeDescription>
          <PrizeTitle>Third Round Leader ..... $1,000.00</PrizeTitle>
          <PrizeDescription>
            Entrant with the most points accumulated from the start of the{" "}
            {season + 1} NHL Playoffs until the completion of the third round.
          </PrizeDescription>
          <PrizeTitle>Final Standing Winners ..... 1st to 100th</PrizeTitle>
          <PrizeDescription>
            Entrants with the most points accumulated from the start of the{" "}
            {season + 1} NHL Playoffs up to and including the final game.
          </PrizeDescription>
        </div>

        <div>
          <div style={{ marginBottom: 20 }}>
            <PrizeOverviewTable>
              <tr>
                <td>Round 1, 2, 3 Prize Total</td>
                <td>$3,000</td>
              </tr>
              <tr>
                <td>Final Standing Prize Total</td>
                <td>$84,500</td>
              </tr>
              <tr>
                <td>Total Prizes Total</td>
                <td>$87,500</td>
              </tr>
              <tr>
                <td>Number of Winners in the "World's Richest Draft"</td>
                <td>103</td>
              </tr>
            </PrizeOverviewTable>
          </div>

          <div style={{ fontWeight: "bold", marginBottom: 5 }}>
            Prize Structure
          </div>
          <div>
            <PrizeStructureTable>
              <tr>
                <th></th>
                <th title="Number of Winners">
                  {deviceSize.small ? "#" : "Number of Winners"}
                </th>
                <th>Each</th>
                <th>Amount</th>
              </tr>
              <tr>
                <td>Round 1 Winner</td>
                <td>1</td>
                <td>$1,000</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td>Round 2 Winner</td>
                <td>1</td>
                <td>$1,000</td>
                <td>$1,000</td>
              </tr>
              <tr>
                <td>Round 3 Winner</td>
                <td>1</td>
                <td>$1,000</td>
                <td>$1,000</td>
              </tr>

              <tr>
                <td>Grand Prize Winner</td>
                <td>1</td>
                <td>$50,000</td>
                <td>$50,000</td>
              </tr>
              <tr>
                <td>
                  2<sup>nd</sup> Place
                </td>
                <td>1</td>
                <td>$10,000</td>
                <td>$10,000</td>
              </tr>
              <tr>
                <td>
                  3<sup>rd</sup> Place
                </td>
                <td>1</td>
                <td>$5,000</td>
                <td>$5,000</td>
              </tr>
              <tr>
                <td>
                  4<sup>th</sup> to 10<sup>th</sup> Place
                </td>
                <td>5</td>
                <td>$1,000</td>
                <td>$7,000</td>
              </tr>
              <tr>
                <td>
                  11<sup>th</sup> to 20<sup>th</sup> Place
                </td>
                <td>10</td>
                <td>$500</td>
                <td>$5,000</td>
              </tr>
              <tr>
                <td>
                  21<sup>st</sup> to 30<sup>th</sup> Place
                </td>
                <td>10</td>
                <td>$250</td>
                <td>$2,500</td>
              </tr>
              <tr>
                <td>
                  31<sup>st</sup> to 60<sup>th</sup> Place
                </td>
                <td>30</td>
                <td>$100</td>
                <td>$3,000</td>
              </tr>
              <tr>
                <td>
                  61<sup>st</sup> to 100<sup>th</sup> Place
                </td>
                <td>40</td>
                <td>$50</td>
                <td>$2,000</td>
              </tr>
              <tr>
                <td>Total prize value</td>
                <td>103</td>
                <td></td>
                <td>$87,500</td>
              </tr>
            </PrizeStructureTable>
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <div style={{ fontWeight: "bold", padding: "10px 0" }}>
            Estimated dates to declare the winners are (subject to change
            dependent on NHL Playoff schedule):
          </div>
          <TextPadding3>Round 1 - April 29, 2024</TextPadding3>
          <TextPadding3>Round 2 - May 13, 2024</TextPadding3>
          <TextPadding3>Round 3 - May 27, 2024</TextPadding3>
          <TextPadding3>Final Round - June 10, 2024</TextPadding3>
          <TextPadding3>
            Exact dates are unavailable at this time as rounds can be completed
            in 4 to 7 games (4 out of 7 wins the round).
          </TextPadding3>
          <TextPadding3>
            All prizes are cash. Winners can claim their prizes by mail to the
            address shown at the time of purchase online.
          </TextPadding3>
        </div>
      </Section>

      <Section>
        <h2>Disclaimer</h2>
        <p></p>
        <ol>
          <li>
            All draft sales are final; no refunds shall be made except in the
            event the draft is cancelled for any reason.
          </li>
          <li>
            Online entries can be accessed and changed until the deadline -{" "}
            {playerPickEndDateString}.
          </li>
          <li>
            The NAIT Foundation is not responsible for cancelled games that may
            affect player selections and points.
          </li>
          <li>
            Standings are updated daily and are posted on draft website within
            24 hours of receiving the NHL stats. Round 1, 2 and 3 and the top 100
            final winners will be notified by email.
          </li>
          {/*             <li>In the event a winning draft entry has more than one name on it, the NAIT Foundation shall award the prize to only one of the individuals identified on the draft form. The NAIT Foundation and the Alberta Gaming & Liquor Commission are not responsible for any disputes which may arise between the different individuals whose names appear on the draft entry.</li>
            <li>Names of all winners will be placed on the draft website at <a href="https://www.worldsrichestdraft.com">www.worldsrichestdraft.com</a>. <br />
                Round 1 & 2 and the top 10 final winners will be notified by phone.<br />
                <br />
                A list of all winners will also be posted on a wall by the entrance door to the NAIT Department of Athletics located at:<br />

                <b>Room S105</b><br />
                <b>11762 106 Street NW</b><br />
                <b>Edmonton, Alberta T5G 2R1</b><br />

            </li> */}
          <li>
            After the announcement of the winner names, 2 weeks shall elapse
            before any prizes are awarded and mailed, to allow for any
            challenges to the individual standings.
          </li>
          <li>
            Any/all winner(s) have 3 months from the date of the draw to claim
            their prizes. After that time NAIT Foundation will notify the
            Alberta Gaming & Liquor Commission of any outstanding prize money. A
            decision will be made by the World's Richest Playoff Draft Committee
            to donate the unclaimed prize money either to NAIT or to another
            charity.
          </li>
          <li>
            In the event of a complaint or dispute, contact the Draft
            Chairperson, Katie Marshland by phone: (780) 378-5904 or email:{" "}
            <a href="mailto:katiem@nait.ca">katiem@nait.ca</a>.
          </li>
        </ol>
      </Section>

      {/* <Section>
        <h2>Promotional Activities</h2>
        <p></p>
        <ol>
          <li>
            All promotional materials will carry the Alberta Gaming and Liquor
            Commission license number.
          </li>
          <li>
            All promotional materials will clearly indicate online entries will
            only be sold in the Province of Alberta.
          </li>
          <br />
          <ul>
            Online entry forms will clearly indicate that entries are only
            accepted from Alberta residents. The Province field will state{" "}
            <b>AB (Alberta)</b> and cannot be changed.
          </ul>
          <br />
          <li>
            The NAIT Foundation World's Richest Playoff Draft will be advertised
            online through social channels, for example, Facebook, Twitter, and
            Instagram. It will also be promoted on NAIT's website, including
            alumni, athletics and career services.
          </li>
          <li>
            All online draft sales are open to the general public. Each entry
            has the same chance of winning any prize.
          </li>
          <li>
            Standings are updated after the completion of each round and are
            posted on draft website within 24 hours of receiving the NHL stats.
            {/*             Names of all winners will be placed on the draft website at <a href="https://www.worldsrichestdraft.com">www.worldsrichestdraft.com</a> and listed at NAIT.<br />
                <b>Room S105</b><br />
                <b>11762 106 Street NW</b><br />
                <b>Edmonton, Alberta T5G 2R1</b><br /> 
            <br />
            Round 1 & 2 and the top 10 final winners will be notified by phone.
            <br />
          </li>
        </ol>
      </Section> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  b {
    font-weight: bold !important;
  }
  margin-bottom: 20px;
  ol,
  ul {
    margin-left: 20px;
    margin-top: 10px;
  }

  li {
    /* padding: 3px 0; */
  }
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const PrizeTitle = styled.div`
  font-weight: bold;
  padding: 10px 0;
`;

const PrizeDescription = styled.div`
  padding: 3px 0;
`;

const PrizeOverviewTable = styled.table`
  > tr > td {
    border: solid 1px #ddd;
    padding: 10px;
  }
`;

const PrizeStructureTable = styled.table`
  > tr > td,
  > tr > th {
    border: solid 1px #ddd;
    padding: 10px;
  }
  > tr > td {
    text-align: right;
  }
  > tr > td:first-child {
    text-align: left;
  }
  > tr > th {
    font-weight: bold;
  }
  > tr > th:nth-child(2) {
    max-width: 100px;
  }
`;

const TextPadding3 = styled.div`
  padding: 3px 0;
`;

export default Rules;
