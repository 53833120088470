// import styled from "styled-components";
import { IncludedTeam } from "../faq.duck";

export type Props = {
  teams: IncludedTeam[];
};

const IncludedTeams = ({ teams }: Props) => {
  const divisions = teams
    .map((team) => team.division)
    .filter((v, i, a) => a.indexOf(v) === i);

  if (teams.length === 0)
    return <div style={{ padding: "5px 0" }}>List of included teams - TBD</div>;

  return (
    <div>
      {divisions.map((division) => {
        return (
          <div key={division} style={{ marginTop: 10 }}>
            <div style={{ fontWeight: "bold" }}>{division}</div>
            <div>
              {teams
                .filter((team) => team.division === division)
                .map((team) => {
                  return <div>{team.name}</div>;
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IncludedTeams;
