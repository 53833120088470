import { createSlice } from "@reduxjs/toolkit";
// import { createAsyncAction, ReducerState } from "components/npmFetch/reduxToolkitUtilities";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import getAuthBearerToken from "store/getAuthBearerToken";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "loginComplete";

type GetUserReturn = {
  id: number;
  displayName?: string;
  teamName?: string;
  emailAddress?: string;
};

var getUser = createAsyncAction<
  GetUserReturn, //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getUser",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetUser`,
  getAuthBearerToken,
  pending: (state, action) => {
    state.getUser = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.data = action.payload;
  },
  rejected: (state, action) => {
    state.getUser.isLoading = false;
    state.getUser.error = action.payload;
  },
});

// type UpdateUserProfileReturn = {};

// var updateUserProfile = createAsyncAction<
//   UpdateUserProfileReturn, //return type
//   {
//     id: number;
//     emailAddress: string;
//     displayName: string;
//     teamName: string;
//   }, //params
//   State
// >({
//   actionPrefix: controlName,
//   actionName: "updateUserProfile",
//   url: `${process.env.REACT_APP_API_BASE}/Account/UpdateUserProfile`,
//   getAuthBearerToken,
//   postAsJson: true,
//   pending: (state, action) => {
//     state.updateUserProfile = { isLoading: true };
//   },
//   fulfilled: (state, action) => {
//     state.updateUserProfile.isLoading = false;
//     state.updateUserProfile.data = action.payload;
//   },
//   rejected: (state, action) => {
//     state.updateUserProfile.isLoading = false;
//     state.updateUserProfile.error = action.payload;
//   },
// });

type State = {
  getUser: ReducerState<GetUserReturn>;
  // updateUserProfile: ReducerState<UpdateUserProfileReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getUser: {
      isLoading: false,
    },
  } as State,
  reducers: {
    reset: (state) => {
      state.getUser = {
        isLoading: false,
      };
    },
  },
  extraReducers: {
    ...getUser.reducer,
    // ...updateUserProfile.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getUser: getUser.action,
      // updateUserProfile: updateUserProfile.action,
    },
  },
};

export default duck;
