import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MainTemplate from "components/MainTemplate/MainTemplate";
import Prizes from "components/Prizes/Prizes";
import { useState } from "react";
import { useReduxState } from "store";
import styled from "styled-components";
import HowToEnter from "./components/HowToEnter";
import Rules from "./components/Rules";

export type Props = {};

type State = {
  expanded: string[];
};

const About = (props: Props) => {
  const settings = useReduxState((e) => e.mainTemplate.getSettings).data
    ?.settings;
  // const actions = useActions();
  const [state, setState] = useState<State>({
    expanded: [""],
  });

  const sections = [
    {
      key: "prizes",
      title: "Prizes",
      content: (
        <div>
          <Prizes />
        </div>
      ),
    },
    {
      key: "howToEnter",
      title: "How To Enter",
      content: (
        <HowToEnter
          draftMaxPicks={settings?.maxPicksPerEntry ?? 0}
          draftStartDate={settings?.entryOpenDateGMT ?? ""}
          draftEndDate={settings?.entryCloseDateGMT ?? ""}
        />
      ),
    },
    {
      key: "rules",
      title: "Rules and Regulations",
      content: (
        <Rules
          draftMaxPicks={settings?.maxPicksPerEntry ?? 0}
          draftStartDate={settings?.entryOpenDateGMT ?? ""}
          draftEndDate={settings?.entryCloseDateGMT ?? ""}
          season={settings?.season ?? 2022}
        />
      ),
    },
    {
      key: "contact",
      title: "Contact Us",
      content: <ContactInfo />,
    },
  ];

  // const season = settings?.season ?? 2021;

  return (
    <div>
      <MainTemplate
        body={
          <div>
            {/* <div style={{ marginBottom: 40 }}>
              The 32nd Annual World's Richest Hockey Draft is based on the{" "}
              {season}-{season + 1} Stanley Cup Playoffs. Proceeds support NAIT
              Athletic Scholarships.
            </div> */}
            {sections.map((c, index) => {
              return (
                <SectionWrapper>
                  <div
                    key={c.key}
                    style={{
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      state.expanded.indexOf(c.key) >= 0
                        ? setState({
                            ...state,
                            expanded: state.expanded.filter((d) => d !== c.key),
                          })
                        : setState({
                            ...state,
                            expanded: [...state.expanded, c.key],
                          });
                    }}
                  >
                    <div style={{ marginRight: 20, marginTop: 7 }}>
                      {state.expanded.indexOf(c.key) >= 0 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </div>
                    <div>
                      <h1>{c.title}</h1>
                    </div>
                  </div>
                  {state.expanded.indexOf(c.key) >= 0 && (
                    <ContentDiv>{c.content}</ContentDiv>
                  )}
                </SectionWrapper>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

const ContactInfo = () => {
  return (
    <div>
      <ContactRow>
        <div>By Mail</div>
        <div>
          <div>NAIT World's Richest Playoff Hockey Draft</div>
          <div>Room E134</div>
          <div>11762 106 Street NW</div>
          <div>Edmonton, Alberta T5G 2R1</div>
        </div>
      </ContactRow>
      <ContactRow>
        <div>By Phone</div>
        <div>1 800 875 5871</div>
      </ContactRow>
      <ContactRow>
        <div>By Email</div>
        <div>
          <a href="mailto:info@worldsrichestdraft.com">
            info@worldsrichestdraft.com
          </a>
        </div>
      </ContactRow>
    </div>
  );
};

const ContentDiv = styled.div`
  margin: 10px 0 10px 45px;
`;

const SectionWrapper = styled.div`
  margin: 0;
  line-height: normal !important ;
  i {
    font-style: italic !important;
  }
`;

const ContactRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  > div:nth-child(1) {
    width: 125px;
    font-weight: bold;
  }
  > div:nth-child(1) {
    width: 150px;
  }
`;

export default About;
