import { useEffect, useState } from "react";

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useDeviceSize(
  lg: number = 1000,
  md: number = 700
): {
  small: boolean;
  medium: boolean;
  large: boolean;
} {
  const { width } = useWindowSize();

  var ret = {
    small: false,
    medium: false,
    large: false,
  };

  if (!width || width >= lg) {
    ret.large = true;
  } else if (!width || width >= md) {
    ret.medium = true;
  } else {
    ret.small = true;
  }

  return ret;
}

export default useWindowSize;
