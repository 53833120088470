import { ExpandLess, ExpandMore, Print } from "@mui/icons-material";
import Ticket from "components/EntriesTicketSelector/components/Ticket";
import PlayerAvatar, { TeamAvatar } from "components/PlayerAvatar/PlayerAvatar";
import { Player } from "components/PlayerGrid/PlayerGridTypes";
import {
  Entry,
  getEntryIdTicketNumbers,
} from "components/UserEntryEditorGrid/UserEntryEditorGrid";
import { EntryItem } from "pages/Standings/standings.duck";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useDeviceSize } from "hooks/useWindowSize";
import { useReactToPrint } from "react-to-print";
import { IconButton } from "@mui/material";

export type Props = {
  teamName: string;
  entries: Entry[];
  allPlayers: Player[];
  maxPlayersPerEntry?: number;
  standings?: EntryItem[];
  showPoints?: boolean;
};

const UserEntrySummary = (props: Props) => {
  const { entries, allPlayers, maxPlayersPerEntry, standings, showPoints } =
    props;
  const [expandedIds, setExpandedIds] = useState<number[]>([]);
  const entryTicketNumbers = getEntryIdTicketNumbers(entries);

  const deviceSize = useDeviceSize(1000, 600);
  const showAvatar = deviceSize.large || deviceSize.medium;
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Page ref={printRef}>
      <TableWrapper>
        <SpreadsheetTable>
          <thead>
            <tr>
              <TH
                isSmallScreen={!showAvatar}
                isFirst={true}
                style={{ width: 480 }}
              >
                Entries
                <IconButton style={{ marginLeft: 5 }} onClick={handlePrint}>
                  <Print />
                </IconButton>
              </TH>
              {showPoints && (
                <>
                  <TH isSmallScreen={!showAvatar}>Goals</TH>
                  <TH isSmallScreen={!showAvatar}>Assists</TH>
                  <TH isSmallScreen={!showAvatar}>Total</TH>
                </>
              )}
              {/* <TH isSmallScreen={!showAvatar}></TH> */}
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => {
              return (
                <EntryPlayers
                  key={index}
                  entry={entry}
                  allPlayers={allPlayers}
                  entryTicketNumbers={entryTicketNumbers}
                  maxPlayersPerEntry={maxPlayersPerEntry}
                  standings={standings}
                  expanded={
                    expandedIds.find((c) => c === entry.entryId) !== undefined
                  }
                  onExpandClick={() =>
                    expandedIds.find((c) => c === entry.entryId) !== undefined
                      ? setExpandedIds(
                          expandedIds.filter((c) => c !== entry.entryId)
                        )
                      : setExpandedIds([...expandedIds, entry.entryId])
                  }
                  showPoints={showPoints}
                />
              );
            })}
          </tbody>
        </SpreadsheetTable>
      </TableWrapper>
    </Page>
  );
};

type EntryPlayer = {
  teamId: number;
  playerId: number;
  name: string;
  goals: number;
  assists: number;
  total: number;
};
type EntryProps = {
  entry: Entry;
  allPlayers: Player[];
  maxPlayersPerEntry?: number;
  standings?: EntryItem[];
  entryTicketNumbers: { id: number; ticketNumber: number }[];
  expanded: boolean;
  showPoints?: boolean;
  onExpandClick(): void;
};
const EntryPlayers = ({
  entry,
  allPlayers,
  standings,
  maxPlayersPerEntry,
  entryTicketNumbers,
  expanded,
  onExpandClick,
  showPoints,
}: EntryProps) => {
  const entryPlayers: EntryPlayer[] = entry.playerIds
    .map((c) => {
      const player = allPlayers.filter((ap) => ap.playerId === c)[0];
      if (!player) {
        return {
          teamId: 0,
          playerId: c,
          goals: 0,
          assists: 0,
          total: 0,
          name: `${c}`,
        };
      }

      return {
        teamId: player.teamId,
        playerId: player.playerId,
        goals: player.goals,
        assists: player.assists,
        total: player.points ?? 0,
        name: `${player.firstName} ${player.lastName}`,
      };
    })
    .sort((a, b) => (a.total > b.total ? -1 : 1));

  const deviceSize = useDeviceSize(1000, 600);
  const showFirstColumn = deviceSize.large;
  const showAvatar = !deviceSize.small;

  const tixNum = entryTicketNumbers
    .find((f) => f.id === entry.entryId)
    ?.ticketNumber.toString()
    .padStart(3, "0");

  return (
    <>
      <tr>
        <TD>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={onExpandClick}
          >
            <div style={{ marginRight: 20 }}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </div>
            {(showFirstColumn || showAvatar) && (
              <Ticket
                ticketNumber={
                  entryTicketNumbers.find((f) => f.id === entry.entryId)
                    ?.ticketNumber
                }
                size="sm"
                complete={entry.playerIds.length === maxPlayersPerEntry}
              />
            )}
            <div style={{ padding: "0 10px" }}>
              {showAvatar && (
                <TicketNumber>
                  Entry {tixNum} {entry.entryName && `(${entry.entryName})`}{" "}
                </TicketNumber>
              )}
              {!showAvatar && (
                <div>
                  <TicketNumber>
                    {tixNum} {entry.entryName && `(${entry.entryName})`}{" "}
                  </TicketNumber>{" "}
                </div>
              )}
              {showAvatar && (
                <PickCount>
                  {entry.playerIds.length} out of {maxPlayersPerEntry} picked
                </PickCount>
              )}
              {!showAvatar && (
                <PickCount>
                  {entry.playerIds.length}/{maxPlayersPerEntry}
                </PickCount>
              )}
            </div>
          </div>
        </TD>
        {showPoints && (
          <>
            <PointsTD isBold>
              {(standings ?? []).filter((c) => c.id === entry.entryId)[0]
                ?.goals || 0}
            </PointsTD>
            <PointsTD isBold>
              {(standings ?? []).filter((c) => c.id === entry.entryId)[0]
                ?.assists || 0}
            </PointsTD>
            <PointsTD isBold>
              {(standings ?? []).filter((c) => c.id === entry.entryId)[0]
                ?.total || 0}
            </PointsTD>
          </>
        )}
      </tr>
      {expanded &&
        entryPlayers.map((player, pIndex) => {
          //const player = allPlayers.filter((ap) => ap.playerId == p)[0];
          return (
            <tr key={pIndex}>
              <TDContainingTable>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      {showFirstColumn && (
                        <TDBorderLeftBottom style={{ width: 85 }}>
                          <TeamAvatar teamId={player.teamId} eagerLoading />
                        </TDBorderLeftBottom>
                      )}
                      <TDBorderLeftBottom
                        style={{ borderRight: "solid 1px #ddd" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          {showAvatar && (
                            <div style={{ marginRight: 10 }}>
                              <PlayerAvatar
                                playerId={player.playerId}
                                eagerLoading
                                teamId={
                                  !showFirstColumn ? player.teamId : undefined
                                }
                              />
                            </div>
                          )}
                          <div>{player.name}</div>
                        </div>
                      </TDBorderLeftBottom>
                    </tr>
                  </tbody>
                </table>
              </TDContainingTable>
              {showPoints && (
                <>
                  <PointsTDPlayerRow>{player.goals}</PointsTDPlayerRow>
                  <PointsTDPlayerRow>{player.assists}</PointsTDPlayerRow>
                  <PointsTDPlayerRow>{player.total}</PointsTDPlayerRow>
                </>
              )}
            </tr>
          );
        })}
    </>
  );
};

// const TopBar = styled.div`
//   background-color: #0065c8;
// `;

const TD = styled.td`
  padding: 20px;
  vertical-align: middle;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
`;

const TDContainingTable = styled.td`
  padding-left: 20px;
  vertical-align: middle;
  border: none;
`;

const TDBorderLeftBottom = styled.td`
  padding: 5px 10px;
  vertical-align: middle;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;
const PointsTDPlayerRow = styled.td`
  padding: 5px 10px;
  vertical-align: middle;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
`;

const PointsTD = styled.td<{
  isBold?: boolean;
}>`
  padding: 20px;
  vertical-align: middle;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-weight: ${(props) => (props.isBold ? "bold" : "inherit")};
`;

const TicketNumber = styled.div`
  font-weight: bold;
  font-style: italic;
  padding-bottom: 5px;
`;

const PickCount = styled.div`
  font-weight: 500;
  color: #aaa;
  font-size: 10pt; ;
`;

const TH = styled.th<{ isSmallScreen?: boolean; isFirst?: boolean }>`
  white-space: nowrap;
  padding: ${(p) => (p.isSmallScreen ? "10px" : "20px")};
  text-align: center;
  background-color: #fff;
  font-weight: bold;
  position: sticky;
  top: 0;

  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  ${({ isFirst }) =>
    isFirst &&
    `
      border-left: 1px solid #ddd;
  `}
`;

const TableWrapper = styled.div`
  // overflow: auto;
  flex: 1;
  //margin-left: 20px;
  //margin-top: 20px;
  // border-left: 1px solid #ddd;
  margin: 20px auto;
`;

const SpreadsheetTable = styled.table`
  border-collapse: separate;
  table-layout: fixed;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
`;

export default UserEntrySummary;
