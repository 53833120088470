import styled from "styled-components";

import { Player } from "components/PlayerGrid/PlayerGridTypes";
import { GetPlayerList } from "components/helpers/playerEntryBuilder";

import CheckboxButton from "components/CheckboxButton/CheckboxButton";

import React, { useState, useRef, useEffect } from "react";
import PlayerAvatar, { TeamAvatar } from "components/PlayerAvatar/PlayerAvatar";
import Ticket from "components/EntriesTicketSelector/components/Ticket";
import SearchField, { SearchOptions } from "./components/SearchField";
import { EntryItem } from "pages/Standings/standings.duck";
import { useDeviceSize } from "hooks/useWindowSize";
// import ModeToggle from "components/ModeToggle/ModeToggle";
import { useNavigate, useParams } from "react-router-dom";
import EntryPlayerListDialog from "./components/EntryPlayerListDialog";

export type Entry = {
  entryId: number;
  teamName: string;
  entryName: string;
  playerIds: number[];
};

export type ColumnNames = keyof Player;

type SortColumn = {
  columnKey: ColumnNames;
  direction: "DESC" | "ASC";
};

export type Props = {
  entries: Entry[];
  standings?: EntryItem[];
  allPlayers: Player[];
  maxPlayersPerEntry?: number;
  itemsLoading?: {
    entryId: number;
    playerId: number;
  }[];
  isUpdatingEntryName: boolean;
  editable: boolean;
  onEditEntryName(entryId: number, name: string): void;
  onUserPlayerSelectionToggle?(
    entryId: number,
    playerId: number,
    isSelected: boolean
  ): void;
  onViewPlayerStats(playerId: number): void;
};

const UserEntryEditorGrid = (props: Props) => {
  const {
    allPlayers,
    entries,
    itemsLoading = [],
    maxPlayersPerEntry = 30,
    isUpdatingEntryName,
    onUserPlayerSelectionToggle,
    onViewPlayerStats,
  } = props;

  const [searchText, setSearchText] =
    useState<
      | {
          text: string;
          options: SearchOptions;
        }
      | undefined
    >();

  // const [showStats, setShowStats] = useState(false);

  const [sortColumns, setSortColumns] = useState<SortColumn>({
    columnKey: "points",
    direction: "DESC",
  });

  const [playerListDialogState, setPlayerListDialogState] = useState<{
    open: boolean;
    entryId: number;
    players: Player[];
    ticketNumber: number | "Void" | undefined;
  }>({
    open: false,
    entryId: 0,
    players: [],
    ticketNumber: 0,
  });

  const entryForDialog = entries.find((e) => e.entryId === playerListDialogState.entryId);

  const history = useNavigate();
  const params = useParams();

  const deviceSize = useDeviceSize(1000, 700);

  var isAllPlayersFilter = searchText?.options === "ALL" && searchText.text;

  var filtered = filterPlayers(searchText?.text || "", allPlayers);

  var showAllPlayers = true;

  var players = GetPlayerList(
    filtered,
    isAllPlayersFilter || showAllPlayers ? undefined : entries
  );

  //filter up to 100 max if using all nhl players (speed)
  // if (isAllPlayersFilter) {
  //   players = players.slice(0, 100);
  // }

  players = sortPlayer(players, sortColumns);

  const entryTicketNumbers = getEntryIdTicketNumbers(entries);

  const [scrollTop, setScrollTop] = useState(0);
  const scrollDiv = useRef(null as null | HTMLDivElement);
  const minRender = scrollTop - 150;
  const maxRender = scrollTop + (scrollDiv.current?.clientHeight ?? 0) + 150;


  return (
    <>
      <Page isAdminMode={params.userId !== undefined}>
        <TopBar>
          <div
            style={{
              display: "flex",
              // flexWrap: "wrap",
              justifyContent: "space-between",
              maxWidth: 1400,
              margin: "0 auto",
            }}
          >
            <div
              style={{
                margin: 20,
                width: deviceSize.small ? 230 : 350,
                minWidth: 230,
                zIndex: 4,
                position: "relative",
              }}
            >
              <SearchField
                allPlayers={allPlayers}
                searchText={searchText?.text}
                onSearchTextChange={(text, options) => {
                  setSearchText({ text, options });
                }}
              />
            </div>
            <div
              style={{
                margin: "auto 20px 20px 20px",
              }}
            >
              <button
                className="nait-button nait-button--primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={() =>
                  history(
                    params.userId
                      ? `/UserEntries/${params.userId}`
                      : "/UserEntries"
                  )
                }
              >
                {!deviceSize.large && !deviceSize.medium && (
                  <span>&lt; &lt;</span>
                )}
                {(deviceSize.large || deviceSize.medium) && (
                  <span>My Entries</span>
                )}
              </button>
            </div>
          </div>
        </TopBar>
        {!props.editable && (
          <BelowTopBar>
            Draft is currently closed. You can not modify your entries.
          </BelowTopBar>
        )}
        <TableWrapper
          ref={scrollDiv}
          onScroll={(e) => setScrollTop((e.target as HTMLDivElement).scrollTop)}
        >
          <SpreadsheetTable>
            <thead>
              <tr>
                {deviceSize.large && (
                  <TH isFirst className={"thTeam"}>
                    <SortHeader
                      propertyName="teamName"
                      sortColumns={sortColumns}
                      label={""}
                      onChange={(sc) => {
                        setSortColumns(sc);
                      }}
                      defaultSort="ASC"
                    />
                  </TH>
                )}
                <TH
                  isSecond
                  hideFirst={!deviceSize.large}
                  isSmallScreen={!deviceSize.large && !deviceSize.large}
                  className={"thPlayer"}
                >
                  <SortHeader
                    propertyName="lastName"
                    sortColumns={sortColumns}
                    label={"Player"}
                    onChange={(sc) => {
                      setSortColumns(sc);
                    }}
                    defaultSort="ASC"
                  />
                </TH>
                {!deviceSize.small && (
                  <React.Fragment>
                    <TH
                      hideFirst={!deviceSize.large}
                      isSmallScreen={deviceSize.medium}
                      statIndex={0}
                      className={"thPoints"}
                    >
                      <SortHeader
                        propertyName="points"
                        sortColumns={sortColumns}
                        label={"Points"}
                        onChange={(sc) => {
                          setSortColumns(sc);
                        }}
                      />
                    </TH>
                  </React.Fragment>
                )}
                {entries.map((e) => {
                  const ticketNumber =
                    e.entryId === 0.999
                      ? "Void"
                      : entryTicketNumbers.find((f) => f.id === e.entryId)
                          ?.ticketNumber;
                  return (
                    <React.Fragment key={e.entryId}>
                      <TH
                        style={{ paddingBottom: 5, cursor: "pointer" }}
                        className={"thTicket"}
                        onClick={() => {
                          setPlayerListDialogState({
                            ...playerListDialogState,
                            open: !playerListDialogState.open,
                            // name: e.entryName,
                            entryId: e.entryId,
                            players: players.filter((p) =>
                              e.playerIds.includes(p.playerId)
                            ),
                            ticketNumber,
                          });
                        }}
                      >
                        <Ticket
                          ticketNumber={ticketNumber}
                          size="sm"
                          complete={e.playerIds.length === maxPlayersPerEntry}
                        />
                        <div
                          style={{ fontSize: 12, marginTop: 5 }}
                          className="thTicketNote"
                        >
                          {e.playerIds.length !== maxPlayersPerEntry && (
                            <div>
                              {e.playerIds.length}/{maxPlayersPerEntry}
                            </div>
                          )}
                          {e.playerIds.length === maxPlayersPerEntry && (
                            <div style={{ color: "#17a501" }}>Done</div>
                          )}
                        </div>
                      </TH>
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {players.map((e, index) => (
                <tr key={`play_${e.playerId}`}>
                  {index * 54 < minRender || (index + 1) * 54 > maxRender ? (
                    <Dummy />
                  ) : (
                    <>
                      {deviceSize.large && (
                        <TD isFirst>
                          <TeamAvatar teamId={e.teamId} />
                        </TD>
                      )}
                      <TD
                        className="tdPlayer"
                        isSecond
                        hideFirst={!deviceSize.large}
                        isSmallScreen={!deviceSize.large && !deviceSize.medium}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onViewPlayerStats(e.playerId);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {!deviceSize.small && (
                            <div style={{ margin: "auto 10px auto 0" }}>
                              <PlayerAvatar
                                playerId={e.playerId}
                                teamId={deviceSize.large ? undefined : e.teamId}
                              />
                            </div>
                          )}
                          <div style={{ margin: "auto 0" }}>
                            {e.firstName} {e.lastName}
                          </div>
                        </div>
                      </TD>
                      {!deviceSize.small && (
                        <TD
                          hideFirst={!deviceSize.large}
                          isSmallScreen={deviceSize.medium}
                          statIndex={0}
                        >
                          {e.points}
                        </TD>
                      )}
                      {entries.map((f) => (
                        <React.Fragment key={`${f.entryId}_${e.playerId}`}>
                          <TD className="tdCheckBox">
                            <CheckboxButton
                              disabled={
                                !props.editable ||
                                (!f.playerIds.includes(e.playerId) &&
                                  !canAddPlayer(
                                    f.playerIds.length,
                                    maxPlayersPerEntry
                                  ))
                              }
                              state={
                                !!itemsLoading.find(
                                  (ee) =>
                                    ee.playerId === e.playerId &&
                                    ee.entryId === f.entryId
                                )
                                  ? "loading"
                                  : f.playerIds.includes(e.playerId)
                                  ? "checked"
                                  : "unchecked"
                              }
                              onClick={() => {
                                onUserPlayerSelectionToggle?.(
                                  f.entryId,
                                  e.playerId,
                                  !f.playerIds.includes(e.playerId)
                                );
                              }}
                            />
                          </TD>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </SpreadsheetTable>
        </TableWrapper>
        <EntryPlayerListDialog
          open={playerListDialogState.open}
          entryId={entryForDialog?.entryId??0}
          players={playerListDialogState.players}
          entryName={entryForDialog?.entryName??""}
          isUpdatingEntryName={isUpdatingEntryName}
          handleBackgroundClick={() => {
            setPlayerListDialogState({
              ...playerListDialogState,
              open: false,
            });
          }}
          onEditEntryName={props.onEditEntryName}
          ticketNumber={playerListDialogState.ticketNumber}
          maxPlayersPerEntry={maxPlayersPerEntry}
        />
      </Page>
    </>
  );
};

const SortHeader = (props: {
  label: string | JSX.Element;
  sortColumns: SortColumn;
  propertyName: ColumnNames;
  onChange(sort: SortColumn): void;
  defaultSort?: "ASC" | "DESC";
}) => {
  const {
    label,
    sortColumns,
    propertyName,
    onChange,
    defaultSort = "DESC",
  } = props;

  return (
    <div
      style={{ cursor: "pointer", display: "flex" }}
      onClick={() => {
        if (sortColumns.columnKey === propertyName) {
          onChange({
            columnKey: propertyName,
            direction: sortColumns.direction === "ASC" ? "DESC" : "ASC",
          });
        } else {
          onChange({
            columnKey: propertyName,
            direction: defaultSort,
          });
        }
      }}
    >
      <div>{label}&nbsp;</div>
      {sortColumns.columnKey === propertyName && (
        <div>
          <span
            className={
              sortColumns.direction === "ASC"
                ? "i-arrow_drop_up"
                : "i-arrow_drop_down"
            }
          ></span>
        </div>
      )}
    </div>
  );
};

function sortPlayer(
  players: (Player & { entryIds: number[] })[],
  sortColumn: SortColumn
) {
  return players.sort((a, b) => {
    //@ts-ignore
    var result = a[sortColumn.columnKey] > b[sortColumn.columnKey] ? 1 : -1;

    if (sortColumn.direction === "DESC") result = -result;

    return result;
  });
}

export function getEntryIdTicketNumbers(entries: Entry[]) {
  var tmp = entries.map((e) => e.entryId).sort((a, b) => (a > b ? 1 : -1));

  var ret: { id: number; ticketNumber: number }[] = [];

  entries.forEach((element) => {
    ret.push({
      id: element.entryId,
      ticketNumber: tmp.indexOf(element.entryId) + 1,
    });
  });

  return ret;
}

// function playerFilter(player: Player, searchText?: string): boolean {
//   if (!searchText) return true;
//   var name = `${player.firstName} ${player.lastName}`;

//   var reg = RegExp(searchText, "i");

//   return !!name.match(reg);
// }

function canAddPlayer(playerCount: number, maxAllowed: number) {
  return playerCount < maxAllowed;
}

const FIRST_COLUMN_WIDTH = 80;
const SECOND_COLUMN_WIDTH = 325;
const STAT_COLUMN_WIDTH = 100;
const SECOND_COLUMN_WIDTH_SMALL_SCREEN = 150;

const TopBar = styled.div`
  background-color: #0065c8;
`;
const BelowTopBar = styled.div`
  background-color: #d8212f;
  color: white;
  padding: 20px;
`;

const Page = styled.div<{ isAdminMode: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${(p) =>
    p.isAdminMode ? "calc(100vh - 160px)" : "calc(100vh - 90px)"};
`;

const TH = styled.th<{
  isFirst?: boolean;
  isSecond?: boolean;
  hideFirst?: boolean;
  statIndex?: number;
  isSmallScreen?: boolean;
}>`
  /* white-space: nowrap; */
  padding: 20px;
  text-align: center;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;

  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  ${(props) =>
    props.isFirst &&
    `
        left: 0;
        z-index: 3;
        min-width: ${FIRST_COLUMN_WIDTH}px;
        max-width: ${FIRST_COLUMN_WIDTH}px;
        width: ${FIRST_COLUMN_WIDTH}px;
        border-left: 1px solid #ddd;
  `}
  ${(props) =>
    props.isSecond &&
    `
    left: ${FIRST_COLUMN_WIDTH}px;
    z-index: 3;
    text-align: left;
    min-width: ${
      props.isSmallScreen
        ? SECOND_COLUMN_WIDTH_SMALL_SCREEN
        : SECOND_COLUMN_WIDTH
    }px;
    max-width: ${
      props.isSmallScreen
        ? SECOND_COLUMN_WIDTH_SMALL_SCREEN
        : SECOND_COLUMN_WIDTH
    }px;
    width: ${
      props.isSmallScreen
        ? SECOND_COLUMN_WIDTH_SMALL_SCREEN
        : SECOND_COLUMN_WIDTH
    }px;
  `}
  ${(props) =>
    props.isSecond &&
    props.hideFirst &&
    `
    left: 0;
    border-left: 1px solid #ddd;
  `}
  ${(props) =>
    props.statIndex !== undefined &&
    `
    z-index: 4;
    min-width: ${STAT_COLUMN_WIDTH}px;
    max-width: ${STAT_COLUMN_WIDTH}px;
    width: ${STAT_COLUMN_WIDTH}px;
    left: ${
      (props.hideFirst ? 0 : FIRST_COLUMN_WIDTH) +
      (props.isSmallScreen
        ? SECOND_COLUMN_WIDTH_SMALL_SCREEN
        : SECOND_COLUMN_WIDTH) +
      props.statIndex * STAT_COLUMN_WIDTH
    }px;
  `}
`;

const TD = styled.td<{
  isFirst?: boolean;
  isSecond?: boolean;
  hideFirst?: boolean;
  statIndex?: number;
  isSmallScreen?: boolean;
}>`
  /* white-space: nowrap; */
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  height: 54px;

  ${(props) =>
    props.isFirst &&
    `
        border-left: 1px solid #ddd;
        background-color: #fff;
        position: sticky;
        left: 0;
        padding: 0px;
  `}
  ${(props) =>
    props.isSecond &&
    `
    background-color: #fff;
    position: sticky;
    left: ${FIRST_COLUMN_WIDTH}px;
    text-align: left;
  `}

  ${(props) =>
    props.isSecond &&
    props.hideFirst &&
    `
        left: 0;
        border-left: 1px solid #ddd;
  `}
  ${(props) =>
    props.statIndex !== undefined &&
    `
    position: sticky;
    background-color: #fff;
    min-width: ${STAT_COLUMN_WIDTH}px;
    max-width: ${STAT_COLUMN_WIDTH}px;
    width: ${STAT_COLUMN_WIDTH}px;
    left: ${
      (props.hideFirst ? 0 : FIRST_COLUMN_WIDTH) +
      (props.isSmallScreen
        ? SECOND_COLUMN_WIDTH_SMALL_SCREEN
        : SECOND_COLUMN_WIDTH) +
      props.statIndex * STAT_COLUMN_WIDTH
    }px;
  `}
`;

const Dummy = styled.td`
  height: 54px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  flex: 1;
  max-width: 1400px;
  @media (min-width: 1400px) {
    width: 1400px;
    margin: 0 auto;
  }
`;

const SpreadsheetTable = styled.table`
  //   position: relative;
  //   border: 1px solid #ddd;
  border-collapse: separate;
  cell-spacing: 0;
  border-spacing: 0;
  table-layout: fixed;
`;

export default UserEntryEditorGrid;

export function filterPlayers(searchString: string, players: Player[]) {
  var searchFilters = getSearchItemFilters(searchString);

  var results = [...players];

  searchFilters.forEach((element) => {
    results = match(results, element);
  });

  return results;
}

function getSearchItemFilters(searchString: string) {
  var splitSpace = searchString.split(" ");

  return splitSpace;
}

function match(players: Player[], search: string) {
  if (!search) return players;

  search = search.toLowerCase();

  if (search.indexOf(":") > 0) {
    var [key, value] = search.split(":");

    switch (key) {
      case "teamid":
        return players.filter((e) => e.teamId.toString() === value);
      case "playerid":
        return players.filter((e) => e.playerId.toString() === value);
      case "position":
        return players.filter((e) => e.position.toLowerCase() === value);

      default:
        return players;
    }
  } else {
    return players.filter((player) => {
      var searchable =
        `${player.firstName} ${player.lastName} ${player.teamName}`.toLowerCase();

      return searchable.indexOf(search) >= 0;
    });
  }
}
