import styled from "styled-components";
import { useReduxState } from "store";
import moment from "moment-timezone";

export type Props = {
  draftMaxPicks: number;
  draftEndDate: string;
  draftStartDate: string;
};

const HowToEnter = ({ draftMaxPicks }: Props) => {
  const settingsState = useReduxState((e) => e.mainTemplate.getSettings);

  const startDateString = settingsState.data
    ? moment(settingsState.data.settings.entryOpenDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY")
    : "TBA";
  const endDateString = settingsState.data
    ? `${moment(settingsState.data.settings.entryCloseDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY hh:mm A")} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const endDateString = "TBD";
  const playerPickEndDateString = settingsState.data
    ? `${moment(settingsState.data.settings.playerPickCloseDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY h:mm A")} Mountain Time (pending official NHL schedule)`
    : "TBA";
  //const playerPickEndDateString = "TBD";

  return (
    <Wrapper>
      <div style={{ marginTop: -10 }}>
        <ol>
          <li>
            Limited to the first 7,999 entries (1,000 entries @ $50 and 6,999
            entries @ 3 for $100).
          </li>
          <li>
            Online entries can be purchased at{" "}
            <a href="https://www.worldsrichestdraft.com">
              www.worldsrichestdraft.com
            </a>{" "}
            starting {startDateString} until {endDateString}.
            Online entries can be accessed and changed until the deadline.
          </li>
          <li>
            Entry fees are: 1 for $50 or 3 for $100. Acceptable payment methods
            are: Visa, MasterCard, American Express, and Debit Credit Cards.
          </li>
          <li>
            There is no limit on the number of entries permitted per individual
            or household.
          </li>
          <li>
            Online entries shall not be sold to a person under the age of 18
            years.
          </li>
          <li>Select {draftMaxPicks} NHL players from the list provided.</li>
          <li>
            <b>
              Under no circumstances shall online entries be changed or modified
              after the {playerPickEndDateString} deadline.
            </b>
          </li>
          <li>
            Your accumulated point total will be your score. Highest score wins.
            Standings can be viewed on the draft website after the player
            selection deadline. You can search by Team Name or filter by your
            Entries.
          </li>
          <li>
            Standings and general inquiries will be handled by the NAIT
            Foundation. Standings will be updated daily and posted on the draft
            website at{" "}
            <a href="https://www.worldsrichestdraft.com">
              www.worldsrichestdraft.com
            </a>
          </li>
          <li>
            Confirmation letters for each individual entry purchased, including
            Round 1 statistics, will be sent to the email address provided at
            the time of purchase. This will be considered the entrant's official
            receipt for the World's Richest Playoff Hockey Draft.
          </li>
        </ol>
      </div>
      <div style={{ marginTop: 20 }}>
        <h2>Entering Online</h2>
        <div style={{ fontWeight: "bold", marginBottom: 10 }}>
          Due to an update to NAIT’s authentication system, all users will need
          to recreate their account profiles. Those using custom or non-social
          logins will also need to recreate their username and password.
        </div>
        <div style={{ marginBottom: 10 }}>
          On your first login, fill in the User Profile form to complete your
          account profile.
        </div>
        <div style={{ marginBottom: 10 }}>
          Once your account is set up, you can log into the site to purchase
          your entries and make your player selections. Player selections can be
          changed until the deadline of {playerPickEndDateString}.
        </div>
        <ol>
          <li>
            You may sign in using your Google, Apple, Facebook or NAIT account.
            If you are signed in to one of these accounts, you will be logged in
            automatically. If you are not using one of the third-party accounts,
            enter your email and password. To create an account, click "Sign up
            now".
          </li>
          <li>
            Remember to choose all 40 players for each of your entries before
            the player selection deadline - you will not be able to make
            additions or changes after the deadline.
          </li>
          <li>
            If you need help on how to select players, click on the "Tour" link
            at the top of the player selection page.
          </li>
        </ol>
        <p></p>
        If you forgot your password:
        <br />
        <ol>
          <li>
            If you use Google, Apple, Facebook or NAIT login, please follow the
            password recovery steps for these accounts.
          </li>
          <li>
            If you created an account on the site, click <b>Log In</b>.
          </li>
          <li>
            Click the <b>Forgot your Password</b> link.
          </li>
          <li>
            Enter your Email Address, click <b>Send Verification Code</b>.
          </li>
          <li>
            Check your email for a message with the subject "NAIT account email
            verification code".
          </li>
          <li>
            Enter the verification code on the "Verification Code" field on the
            password reset form, and click <b>Verify Code</b>.
          </li>
          <li>
            Once the code is verified, click <b>Continue</b>.
          </li>
          <li>
            Create and enter your new password, click <b>Continue</b>.
          </li>
          <li>
            Your password has been updated, and you will automatically be logged
            in to the site.
          </li>
        </ol>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  b {
    font-weight: bold !important;
  }
  margin-bottom: 20px;
  ol,
  ul {
    margin-left: 20px;
    margin-top: 10px;
  }

  li {
    /* padding: 3px 0; */
  }
`;

export default HowToEnter;
