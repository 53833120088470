import {
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
import {
  Paper,
} from "@mui/material";
import { SearchTextField } from "@nait-aits/ui";
import { useState } from "react";
import FlyoutPanel, { filterPlayers } from "./components/FlyoutPanel";
import { ColumnNames, Player } from "./PlayerGridTypes";

import "./datagrid.css";

// import DataGrid, {
//   Column as DataGridColumn,
//   SortColumn,
//   SelectColumn,
// } from "react-data-grid";
import styled from "styled-components";
import PlayerAvatar from "components/PlayerAvatar/PlayerAvatar";
import CheckboxButton from "components/CheckboxButton/CheckboxButton";

// type EnhancedDataGridColumn = DataGridColumn<Player> & { key: ColumnNames };

// type EnhancedSortColumn = SortColumn & {
//   columnKey: ColumnNames;
// };

export type Props = {
  players: (Player & { isAvailable?: boolean })[];
  searchable?: boolean;
  showSeasonPicker?: boolean;
  selectablePlayers?: boolean;
  seasons: number[];
  selectedSeason?: number;
  gridHeight?: number;
  seasonType?: "regular" | "playoffs";
  onSeasonChange?: (year: number, seasonType: "regular" | "playoffs") => void;
  onPlayerSelectionChange?: (player: Player, selected: boolean) => void;
  onViewPlayer?: (props: { playerId: number }) => void;
  maxPlayersPerEntry?: number;
};

type SortColumn = {
  columnKey: ColumnNames;
  direction: "DESC" | "ASC";
};

const PlayerGrid = (props: Props) => {
  const {
    players,
    searchable = true,
    selectablePlayers = false,
    onPlayerSelectionChange,
    onViewPlayer,
    maxPlayersPerEntry = 0,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [appliedSearchText, setAppliedSearchText] = useState("");

  const [sortColumns, setSortColumns] = useState<SortColumn>({
    columnKey: "points",
    direction: "DESC",
  });

  const sortedPlayers = sortPlayers(
    players.filter((e) => e.isAvailable),
    sortColumns.direction,
    sortColumns.columnKey
  );

  const totalPlayersResults = filterPlayers(appliedSearchText, sortedPlayers);

  const selectedPlayerCount = players.filter(
    (e) => e.status === "selected" || e.status === "saving"
  ).length;

  return (
    <>
      <Paper sx={{ width: 900 }}>
        {searchable && (
          <div
            style={{
              padding: "1em 1em 1em 1em",
              zIndex: 100,
              position: "relative",
            }}
          >
            <SearchTextField
              value={searchText}
              onFocusChanged={(isFocus) => {}}
              onChange={(e) => {
                setSearchText(e);
                if (!e) {
                  setAppliedSearchText("");
                }
              }}
              onEnterKeyPressed={() => {
                setAppliedSearchText(searchText);
              }}
              closeOnEnterKey={true}
              flyoutPanel={{
                // show: true,
                content: (
                  <FlyoutPanel
                    players={players.filter((e) => e.isAvailable)}
                    searchString={searchText}
                    onApplySearchFilter={(filter) => {
                      setAppliedSearchText(filter);
                      setSearchText(filter);
                    }}
                  />
                ),
              }}
            />
          </div>
        )}
        {selectablePlayers && (
          <div style={{margin:"0 20px"}}>
            <div
              className={
                selectedPlayerCount === maxPlayersPerEntry
                  ? "nait-notification"
                  : "nait-notification notification--yellow"
              }
            >
              You have selected {selectedPlayerCount}/{maxPlayersPerEntry}{" "}
              players
            </div>
          </div>
        )}
        <div style={{ height: 600, overflow: "auto" }}>
          <Table>
            <thead>
              <HeaderRow>
                {selectablePlayers && (
                  <TH
                    width={50}
                    onClick={() => {
                      setSortColumns(
                        getSort({
                          current: sortColumns,
                          key: "status",
                          defaultOrder: "ASC",
                        })
                      );
                    }}
                  >
                    <SortHeaderButton
                      sort={sortColumns}
                      columnKey={"status"}
                    />
                  </TH>
                )}
                <TH width={50}></TH>
                <TH
                  width={500}
                  first={true}
                  onClick={() => {
                    setSortColumns(
                      getSort({
                        current: sortColumns,
                        key: "lastName",
                        defaultOrder: "ASC",
                      })
                    );
                  }}
                >
                  Player
                  <SortHeaderButton sort={sortColumns} columnKey={"lastName"} />
                </TH>
                <TH
                  width={100}
                  onClick={() => {
                    setSortColumns(
                      getSort({
                        current: sortColumns,
                        key: "goals",
                        defaultOrder: "DESC",
                      })
                    );
                  }}
                >
                  Goals
                  <SortHeaderButton sort={sortColumns} columnKey={"goals"} />
                </TH>
                <TH
                  width={100}
                  onClick={() => {
                    setSortColumns(
                      getSort({
                        current: sortColumns,
                        key: "assists",
                        defaultOrder: "DESC",
                      })
                    );
                  }}
                >
                  Assists
                  <SortHeaderButton sort={sortColumns} columnKey={"assists"} />
                </TH>
                <TH
                  width={100}
                  onClick={() => {
                    setSortColumns(
                      getSort({
                        current: sortColumns,
                        key: "points",
                        defaultOrder: "DESC",
                      })
                    );
                  }}
                >
                  Points
                  <SortHeaderButton sort={sortColumns} columnKey={"points"} />
                </TH>
              </HeaderRow>
            </thead>
            <tbody>
              {totalPlayersResults.map((e) => (
                <PlayerRow
                  key={`pp_${e.playerId}`}
                  selected={e.status === "selected"}
                >
                  {selectablePlayers && (
                    <TD width={50}>
                      <CheckboxButton
                        style={{
                          color:
                            selectedPlayerCount < maxPlayersPerEntry ||
                            e.status === "saving" ||
                            e.status === "selected"
                              ? "#002d74"
                              : "#d5d5d5",
                        }}
                        onClick={() => {
                          onPlayerSelectionChange?.(
                            e,
                            e.status === "selected" ? false : true
                          );
                        }}
                        state={
                          e.status === "selected"
                            ? "checked"
                            : e.status === "unselected"
                            ? "unchecked"
                            : "loading"
                        }
                      />
                    </TD>
                  )}
                  <TD width={100}>
                    <PlayerAvatar playerId={e.playerId} teamId={e.teamId} />
                  </TD>
                  <TD
                    first={true}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onViewPlayer?.({ playerId: e.playerId });
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: 30, margin: "auto 0" }}>
                        <div style={{}}>
                          {e.firstName} {e.lastName} ({e.jerseyNumber})
                        </div>
                        <div
                          style={{ fontStyle: "italic", fontSize: "smaller" }}
                        >
                          {e.teamName}, {e.position}
                        </div>
                      </div>
                    </div>
                  </TD>
                  <TD>{e.goals}</TD>
                  <TD>{e.assists}</TD>
                  <TD>{e.points}</TD>
                </PlayerRow>
              ))}
            </tbody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

function getSort(props: {
  current: SortColumn;
  key: ColumnNames;
  defaultOrder?: "ASC" | "DESC";
}): SortColumn {
  const { current, key, defaultOrder = "DESC" } = props;

  if (current.columnKey === key) {
    return {
      columnKey: key,
      direction: current.direction === "ASC" ? "DESC" : "ASC",
    };
  } else {
    return {
      columnKey: key,
      direction: defaultOrder,
    };
  }
}

const Table = styled.table``;

const TH = styled.th<{ width: number; first?: boolean }>`
  position: sticky;
  top: 0px;
  background-color: white;
  cursor: pointer;
  padding: 10px 0;
  font-weight: bold;
  z-index: 1;
  width: ${(p) => p.width}px;
  ${(p) =>
    !p.first
      ? `
      text-align: right;
    `
      : "text-align: left;"};
`;

const TD = styled.td<{ first?: boolean }>`
  vertical-align: middle;
  height: 60px;
  padding: 10px;
  ${(p) =>
    !p.first &&
    `
      text-align: right;
    `};
`;

const HeaderRow = styled.tr`
  border-bottom: 1px solid #efefef;
`;

const PlayerRow = styled.tr<{ selected: boolean }>`
  border-bottom: 1px solid #efefef;

  ${(p) =>
    p.selected &&
    `
      background-color: #ddeafd;
    `};

  // height: 50px;
`;

const SortHeaderButton = (props: {
  sort: SortColumn;
  columnKey: ColumnNames;
}) => {
  const { sort, columnKey } = props;

  return (
    <span style={{ marginLeft: 5 }}>
      {sort.columnKey !== columnKey && <></>}

      {sort.columnKey === columnKey && (
        <>
          {sort.direction === "ASC" ? (
            <>
              <ArrowDropDown />
            </>
          ) : (
            <>
              <ArrowDropUp />
            </>
          )}
        </>
      )}
    </span>
  );
};

// const baseColumns: EnhancedDataGridColumn[] = [
//   {
//     key: "playerName",
//     name: "Player (Jersey #)",
//     width: 300,
//     frozen: true,
//     formatter: ({ row }) => {
//       return (
//         <div style={{ cursor: "pointer" }}>
//           <div style={{ lineHeight: "40px" }}>
//             {row.firstName} {row.lastName} ({row.jerseyNumber})
//           </div>
//           <div style={{ lineHeight: "0px", fontStyle: "italic" }}>
//             {row.teamName}, {row.position}
//           </div>
//         </div>
//       );
//     },
//   },
//   // {
//   //   key: "position",
//   //   name: "Position",
//   //   width: 60,
//   // },
//   // {
//   //   key: "teamName",
//   //   name: "Team",
//   //   width: 200,
//   //   formatter: ({ row }) => {
//   //     return (
//   //       <TeamCell>
//   //         <TeamImage
//   //           src={`https://www-league.nhlstatic.com/images/logos/teams-current-primary-light/${row.teamId}.svg`}
//   //         />
//   //         <TeamName>{row.teamName}</TeamName>
//   //       </TeamCell>
//   //     );
//   //   },
//   // },
//   { key: "gamesPlayed", name: "GP", width: 40 },
//   { key: "goals", name: "G", width: 40 },
//   { key: "assists", name: "A", width: 40 },
//   { key: "points", name: "P", width: 40 },
//   { key: "plusMinus", name: "+/-", width: 40 },
//   { key: "shotsOnGoal", name: "SOG", width: 40 },
//   { key: "pims", name: "PIM", width: 40 },
//   { key: "powerPlayGoals", name: "PPG", width: 40 },
//   {
//     key: "powerPlayAssists",
//     name: "PPA",
//     width: 40,
//     formatter: ({ row }) => {
//       return <>{(row.powerPlayPoints || 0) - row.powerPlayGoals}</>;
//     },
//   },
//   { key: "powerPlayPoints", name: "PPP", width: 40 },
// ];

// const MoreOptions = (props: {
//   seasons: number[];
//   selectedSeason?: number;
//   seasonType?: "regular" | "playoffs";
//   onSeasonChange?: (year: number, seasonType: "regular" | "playoffs") => void;
// }) => {
//   const {
//     seasons,
//     onSeasonChange,
//     seasonType = "regular",
//     selectedSeason,
//   } = props;

//   const [anchorEl, setAnchorEl] = useState<
//     undefined | Element | ((element: Element) => Element)
//   >();
//   const [showMenu, setShowMenu] = useState(false);

//   return (
//     <>
//       <IconButton
//         title={"Toggle extended stats"}
//         onClick={(e) => {
//           e.stopPropagation();
//           setAnchorEl(e.currentTarget);
//           setShowMenu(!showMenu);
//         }}
//         style={{ color: "#a3a3a3" }}
//       >
//         <MoreHoriz />
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={showMenu}
//         onClose={() => {
//           setShowMenu(false);
//         }}
//         onClick={(a) => {
//           setShowMenu(false);
//         }}
//       >
//         <CheckBoxMenuItem
//           checked={seasonType === "playoffs"}
//           onClick={() => {
//             if (selectedSeason) {
//               onSeasonChange?.(
//                 selectedSeason,
//                 seasonType === "regular" ? "playoffs" : "regular"
//               );
//             }
//           }}
//           label={"Playoffs"}
//         />
//         <Divider />
//         {seasons
//           .sort((a, b) => (a < b ? 1 : -1))
//           .map((e) => (
//             <CheckBoxMenuItem
//               key={`seaason_${e}`}
//               checked={selectedSeason === e}
//               onClick={() => {
//                 onSeasonChange?.(e, seasonType);
//               }}
//               label={`${e}/${e + 1}`}
//             />
//           ))}
//       </Menu>
//     </>
//   );
// };

// const CheckBoxMenuItem = (props: {
//   checked: boolean;
//   onClick: () => void;
//   label: string;
// }) => {
//   return (
//     <MenuItem
//       onClick={() => {
//         props.onClick();
//       }}
//     >
//       <ListItemIcon>
//         {props.checked ? (
//           <CheckBox fontSize="small" />
//         ) : (
//           <CheckBoxOutlineBlank fontSize="small" />
//         )}
//       </ListItemIcon>
//       {props.label}
//     </MenuItem>
//   );
// };

// const CheckCell = styled.div`
//   cursor: pointer;
//   text-align: center;
// `;

function sortPlayers(
  players: Player[],
  sortDir: "ASC" | "DESC",
  sortCol: ColumnNames = "points"
) {
  return players.sort((a, b) => {
    var ret = 0;

    if (sortCol === "playerAvatar") {
      return 1;
    } else if (sortCol === "playerName") {
      var nameA = `${a.firstName} ${a.lastName}`;
      var nameb = `${b.firstName} ${b.lastName}`;

      ret = nameA > nameb ? 1 : -1;
    } else if (sortCol === "powerPlayAssists") {
      var ppaA = (a.powerPlayPoints || 0) - a.powerPlayGoals;
      var ppaB = (b.powerPlayPoints || 0) - b.powerPlayGoals;

      ret = ppaA > ppaB ? 1 : -1;
    } else {
      ret = (a[sortCol] || 1) > (b[sortCol] || 1) ? 1 : -1;
    }

    return sortDir === "ASC" ? ret : -ret;
  });
}

export default PlayerGrid;
