import React, { useEffect } from "react";
import PlayerStatsCardItem from "./PlayerStatsCardItem";

import { useReduxState, useActions } from "store";

export type Props = {
  playerId: number;
};

const PlayerStatsCard = (props: Props) => {
  const { playerId } = props;

  const state = useReduxState((e) => e.playerStatsCard);
  const actions = useActions().playerStatsCard;

  useEffect(() => {
    actions.getPlayerStats({ playerId });

    return () => {
      actions.reset();
    };
  }, [playerId, actions]);

  return (
    <div id="divPlayerStats" style={{maxHeight:'85vh', overflowY:'auto'}}>
      {(state.getPlayerStats.isLoading || !state.getPlayerStats.data) && (
        <div>Loading...</div>
      )}
      {!state.getPlayerStats.isLoading && state.getPlayerStats.data && (
        <PlayerStatsCardItem player={state.getPlayerStats.data} />
      )}
    </div>
  );
};

export default PlayerStatsCard;
