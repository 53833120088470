import { MenuItem, Select, TextField } from "@mui/material";
import MainTemplate from "components/MainTemplate/MainTemplate";
import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import AccountsTable from "./components/AccountsTable";
import { NavButton, NavPageText, Nav } from "../sharedStyledComponents";

export type Props = {};

const Accounts = (props: Props) => {
  const state = useReduxState((e) => e.accounts);
  const actions = useActions().accounts;
  const [searchText, setSearchText] = useState<string>("");
  const [authType, setAuthType] = useState<string>("");
  const [entryYear, setEntryYear] = useState<number>(0);
  const [roleFilter, setRoleFilter] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);

  var searchDelay: NodeJS.Timeout;

  useEffect(() => {
    searchDelay = setTimeout(() => {
      actions.searchUser({
        searchText,
        includeDetails: true,
        authType,
        entryYear,
        page,
        role: roleFilter,
      });
    }, 300);
    return () => {
      clearTimeout(searchDelay);
    };
  }, [searchText, authType, entryYear]);

  useEffect(() => {
    if (!state.changeUserRole.isLoading && !state.searchUser.isLoading) {
      actions.searchUser({
        searchText,
        includeDetails: true,
        page,
        role: roleFilter,
      });
    }
  }, [state.changeUserRole.data]);

  const totalRecords = state.searchUser.data?.totalRecords ?? 0;
  const pageTotal = Math.ceil(totalRecords / 20);

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  return (
    <MainTemplate
      body={
        <div>
          <h1>Accounts</h1>
          <div style={{ marginBottom: 20 }}>
            <TextField
              style={{ width: 400, marginRight: 10, marginBottom: 10 }}
              label="Search by name, email, or id"
              variant="filled"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Select
              value={roleFilter}
              style={{ marginRight: 10 }}
              onChange={(e) => {
                setRoleFilter(e.target.value as number);
                actions.searchUser({
                  searchText,
                  includeDetails: true,
                  page,
                  role: e.target.value as number,
                });
              }}
            >
              <MenuItem value={-1}>All Roles</MenuItem>
              <MenuItem value={0}>Contestant</MenuItem>
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={2}>Media</MenuItem>
            </Select>
            <TextField
              style={{ width: 100, marginRight: 10, marginBottom: 10 }}
              label="Auth Type"
              variant="filled"
              value={authType}
              onChange={(e) => {
                setAuthType(e.target.value);
              }}
            />
            <TextField
              style={{ width: 100, marginRight: 10, marginBottom: 10 }}
              label="Active in Year"
              variant="filled"
              value={entryYear}
              type="number"
              onChange={(e) => {
                setEntryYear(Number.parseInt(e.target.value));
              }}
            />
          </div>
          <AccountsTable
            accounts={state.searchUser.data?.users || []}
            isLoading={state.searchUser.isLoading}
            onChangeUserRole={(userId, role) => {
              if (userId === userProfileState.data?.id) {
                alert("You cannot change your own role.");
                return;
              }

              if (
                window.confirm(
`WARNING: Changing role to Media will also convert the user's entries to Media type.
Are you sure you want to change this user's role?`
                )
              ) {
                actions.changeUserRole({ userId, role });
              }
            }}
          />
          <Nav>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === 1}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page - 1);
                  actions.searchUser({
                    searchText,
                    includeDetails: true,
                    page: page - 1,
                    role: roleFilter,
                  });
                }}
              >
                Previous
              </button>
            </NavButton>
            <NavPageText>
              Page {page} of {pageTotal} ({totalRecords} records)
            </NavPageText>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === pageTotal || pageTotal === 0}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page + 1);
                  actions.searchUser({
                    searchText,
                    page: page + 1,
                    includeDetails: true,
                    role: roleFilter,
                  });
                }}
              >
                Next
              </button>
            </NavButton>
          </Nav>
        </div>
      }
    />
  );
};

export default Accounts;
