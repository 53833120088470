import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "winners";

export type Winner = {
  entryId: number;
  user: WinnerInfo;
  userTeamName: string;
  goals: number;
  assists: number;
  rank: number;
  players: WinnerPlayer[];
  total: number;
}

export type WinnerPlayer = {
  playerId: number;
  name: string;
  teamName: string;
  goals: number;
  assists: number;
  total: number;
}

export type WinnerInfo = {
  userId: number;
  fullName: string;
  teamName: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
}

type LoadWinnersReturn = {
  winners: Winner[];
};

var loadWinners = createAsyncAction<
  LoadWinnersReturn, //return type
  { date: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadStandings",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Standings/GetWinnersOnDate`,
  postAsJson: true,
  // transformUrl: (url, params) => {
  //   return `${url}/${params.page}`;
  // },
  pending: (state, action) => {
    state.loadWinners.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.loadWinners.isLoading = false;
    state.loadWinners.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadWinners.isLoading = false;
    state.loadWinners.error = action.payload;
  },
});

type State = {
  loadWinners: ReducerState<LoadWinnersReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    loadWinners: { isLoading: false },
  } as State,
  reducers: {
    reset: (state) => {
      state.loadWinners = { isLoading: false };
    },
  },
  extraReducers: {
    ...loadWinners.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadWinners: loadWinners.action,
    },
  },
};

export default duck;
