import { useIsAuthenticated } from "@azure/msal-react";
import { ReducerState } from "@nait-aits/redux";
import ErrorBoxReducerState from "components/errorBox/ErrorBoxReducerState";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import AppBar from "./AppBar";
import ProfileButton from "./components/ProfileButton";
// import JoyrideTour from "./components/JoyrideTour";
import useWindowSize, { useDeviceSize } from "hooks/useWindowSize";
import JoyrideTour, { JoyrideSettings } from "components/Joyride/JoyrideTour";
import useDraftStatus from "hooks/useDraftStatus";
// import { AppBar } from '@nait-aits/ui';
import { Helmet } from "react-helmet";

export type Props = {
  appBarRightContent?: JSX.Element;
  body?: JSX.Element | string;
  responsiveBody?: boolean;
  isLoading?: boolean;
  statesForErrors?: ReducerState<any>[];
  children?: JSX.Element | string;
  ignoreCompletedProfileCheck?: boolean;
  showFooter?: boolean;
  isInitialLogin?: boolean;
  joyrideSettings?: JoyrideSettings & {
    autoStartIfNew?: boolean;
    isReady: boolean;
  };
};

const MainTemplate = ({
  body,
  responsiveBody = true,
  isLoading = false,
  statesForErrors,
  children,
  ignoreCompletedProfileCheck,
  showFooter = true,
  joyrideSettings,
  isInitialLogin,
}: Props) => {
  //show by default if not yet done and needed
  const [startTour, setStartTour] = useState(false);

  const { height = 0 } = useWindowSize();

  const appBarHeight = 90;
  const footerHeight = showFooter ? 90 : 0;
  const contentHeight = height - appBarHeight - footerHeight;

  useEffect(() => {
    if (
      joyrideSettings?.isReady &&
      joyrideSettings?.autoStartIfNew &&
      localStorage[joyrideSettings.localStorageKey] !== "1"
    ) {
      setStartTour(true);
    } else {
      setStartTour(false);
    }
  }, [joyrideSettings?.autoStartIfNew, joyrideSettings?.isReady]);

  const isDev = process.env.REACT_APP_MONERIS_CHECKOUT_MODE !== "prod";
  return (
    <>
      {isDev && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      {startTour && joyrideSettings && (
        <JoyrideTour
          {...joyrideSettings}
          onTourComplete={(e) => {
            setStartTour(false);
            joyrideSettings.onTourComplete?.(e);
          }}
        />
      )}
      <AppBarItem
        isLoading={isLoading}
        isInitialLogin={isInitialLogin ?? false}
        setStartTour={joyrideSettings && setStartTour}
        ignoreCompletedProfileCheck={ignoreCompletedProfileCheck || false}
      />
      <Page>
        {responsiveBody && (
          <>
            <ResponsiveBody height={contentHeight} id={"responsiveBodyId"}>
              {statesForErrors &&
                statesForErrors.map((e, i) => (
                  <ErrorBoxReducerState key={`sfe_${i}`} state={e} />
                ))}
              <ResponsiveBodyContent>
                {body}
                {children && children}
              </ResponsiveBodyContent>
            </ResponsiveBody>
          </>
        )}
        {!responsiveBody && (
          <>
            <NonResponsiveBody height={contentHeight} id={"responsiveBodyId"}>
              {statesForErrors &&
                statesForErrors.map((e, i) => (
                  <ErrorBoxReducerState key={`sfe_${i}`} state={e} />
                ))}
              {body}
              {children && children}
            </NonResponsiveBody>
          </>
        )}
        {showFooter && <Footer />}
      </Page>
    </>
  );
};

const AppBarItem = (props: {
  isLoading: boolean;
  ignoreCompletedProfileCheck: boolean;
  setStartTour?: (val: boolean) => void;
  isInitialLogin: boolean;
}) => {
  const history = useNavigate();
  var isAuthenticated = useIsAuthenticated();

  const deviceSize = useDeviceSize(1000, 650);

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const userProfileActions = useActions().profile;

  const state = useReduxState((e) => e.mainTemplate.getSettings);
  const actions = useActions().mainTemplate;

  const draftStatus = useDraftStatus();

  const showButtons = true;
  // draftStatus.isDraftOpen ||
  // draftStatus.draftHasEnded ||
  // (isAuthenticated && userProfileState.data?.isAdmin);

  const isMedia = isAuthenticated && userProfileState.data?.isMedia;

  useEffect(() => {
    //if authenticated and user data is not loaded, load
    if (
      isAuthenticated &&
      !props.isInitialLogin &&
      !userProfileState.data &&
      !userProfileState.isLoading &&
      !userProfileState.error
    ) {
      userProfileActions.getUserSimple({});
    } else if (isAuthenticated && userProfileState.data) {
      if (!userProfileState.data.profileComplete) {
        !props.ignoreCompletedProfileCheck &&
          setTimeout(() => {
            history("/SetupProfile");
          }, 100);
      }
    }
  }, [
    isAuthenticated,
    userProfileState,
    userProfileActions,
    history,
    props.ignoreCompletedProfileCheck,
  ]);

  useEffect(() => {
    if (!state.data && !state.isLoading && !state.error) {
      actions.getSettings({});
    }
  }, [actions, state]);

  return (
    <AppBar
      isDev={process.env.REACT_APP_MONERIS_CHECKOUT_MODE !== "prod"}
      heading={
        <div style={{ display: "flex" }}>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              history("/");
            }}
          >
            <img
              style={{
                maxWidth: 225,
                width: deviceSize.large
                  ? 225
                  : deviceSize.medium
                  ? 225
                  : isAuthenticated
                  ? 200
                  : 170,
              }}
              src="/images/wrd-horizontal.png"
              alt="logo"
            />
          </div>
        </div>
      }
      rightContent={
        !showButtons ? undefined : (
          <nav id="utility-nav">
            <ul>
              {(deviceSize.large || deviceSize.medium) &&
                draftStatus.isDraftOpen &&
                !isMedia && (
                  <>
                    <li>
                      <div>
                        <PurchaseButton
                          onClick={() => {
                            history("/Order");
                          }}
                        >
                          Purchase Entries Now!
                        </PurchaseButton>
                      </div>
                    </li>
                    {props.setStartTour && (
                      <li>
                        <a
                          className="nait-button nait-button--link"
                          // href="https://www.nait.ca"
                          onClick={() => props.setStartTour?.(true)}
                        >
                          Tour
                        </a>
                      </li>
                    )}
                  </>
                )}
              {deviceSize.small && draftStatus.isDraftOpen && (
                <li>
                  <div>
                    <PurchaseButton
                      onClick={() => {
                        history("/Order");
                      }}
                    >
                      Buy Now!
                    </PurchaseButton>
                  </div>
                </li>
              )}
              <li>
                <ProfileButtonItem />
              </li>
            </ul>
          </nav>
        )
      }
      isLoading={props.isLoading}
    />
  );
};

const ProfileButtonItem = () => {
  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  return (
    <ProfileButton
      user={
        userProfileState.data
          ? {
              name: userProfileState.data.displayName,
              teamName: userProfileState.data.teamName,
            }
          : undefined
      }
    />
  );
};

const Footer = () => {
  const deviceSize = useDeviceSize(1000, 600);

  return (
    <FooterWrapper>
      <div style={{ justifyContent: "right", padding: 10 }}>
        <a href="/About">About</a> | <a href="/About">Contact Us</a> |{" "}
        <a href="/FAQ">FAQ</a> |{" "}
        <span>
          <b>Licence #676188</b>
        </span>
      </div>
      {!deviceSize.small && (
        <div style={{ justifyContent: "left", padding: 10 }}>
          <a href="http://www.nait.ca" title="NAIT" target={"_blank"}>
            <img alt="NAIT Logo" src="/images/naitlogo.png" height={50} />
          </a>
          <a
            href="https://www.naitooks.com/landing/index"
            title="NAIT Athletics"
            target={"_blank"}
            style={{ marginLeft: 20 }}
          >
            <img alt="OOKS Logo" src="/images/nait_ooks.png" height={50} />
          </a>
        </div>
      )}
      {deviceSize.small && (
        <div style={{ textAlign: "center", padding: "5px 0" }}>
          <a href="http://www.nait.ca">
            NAIT - A Leading Polytechnic Committed to Your Success
          </a>
          <br />
          <a href="https://www.naitooks.com/landing/index">NAIT - Athletics</a>
        </div>
      )}
    </FooterWrapper>
  );
};

const Page = styled.div`
  // grid-column: 1;
  // grid-row: 1;
`;

const FooterWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  padding: 10px;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: solid 1px #aaa;
  height: 90px;

  b {
    font-weight: bold !important;
  }
`;

export const ResponsiveBody = styled.div<{
  height: number;
}>`
  margin: 90px 0;

  height: ${({ height }) => `${height}`}px;
  overflow: auto;
`;

export const ResponsiveBodyContent = styled.div<{
  maxWidth?: 600 | 800 | 1000;
}>`
  @media (min-width: 780px) {
    width: ${(p) => (p.maxWidth && p.maxWidth < 800 ? p.maxWidth : 800)}px;
    margin: 50px auto 100px auto;
  }
  @media (min-width: 1100px) {
    width: ${(p) => (p.maxWidth ? p.maxWidth : 1000)}px;
    margin: 50px auto 100px auto;
  }
`;

export const NonResponsiveBody = styled.div<{
  height: number;
}>`
  margin: 90px 0 0 0;
  height: ${({ height }) => `${height}`}px;
  overflow: auto;
`;

var PurchaseButton = styled.button`
  background-color: #f29718;
  padding: 10px;
  border-radius: 20px;
  color: black !important;
  /* font-weight: bold; */
  margin: auto;
  border: 1px solid #073b8d;
  cursor: pointer;

  &:hover {
    border: 1px solid white;
  }
`;

var FiftyButton = styled.button`
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  color: #1a6cc0 !important;
  font-weight: bold;
  margin: auto;
  border: 1px solid #073b8d;
  cursor: pointer;

  &:hover {
    border: 1px solid white;
  }
`;

export default MainTemplate;
