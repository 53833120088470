import styled from "styled-components";

export type QuestionProps = {
  question: string;
  answer: string | JSX.Element;
};

const Question = (props: QuestionProps) => {
  return (
    <Wrapper>
      <Q>{props.question}</Q>
      <A>{props.answer}</A>
    </Wrapper>
  );
};

export default Question;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const Q = styled.h3`
  font-weight: bold;
`;

const A = styled.div``;
