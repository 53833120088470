import goal from "./Goal/goal.duck";
// import topPicks from "./TopPicks/topPicks.duck";
// import teamPicks from "./TeamPicks/teamPicks.duck";

const index = {
  reducer: {
    ...goal.reducer,
    // ...topPicks.reducer,
    // ...teamPicks.reducer,
  },
  actions: {
    ...goal.actions,
    // ...topPicks.actions,
    // ...teamPicks.actions,
  },
};

export default index;
