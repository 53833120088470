import { PlayerJson } from "pages/PlayerListing/apiTypes";
import { Player } from "components/PlayerGrid/PlayerGridTypes";

export function playerMapper(
    p: PlayerJson,
    seasonType: "regular" | "playoffs"
  ): Player {
    var statItem =
      seasonType === "regular" ? p.regularSeasonStats : p.playoffStats;
  
    return {
      assists: statItem?.assists || 0,
      position: p.info.primaryPosition.abbreviation,
      firstName: p.info.firstName,
      lastName: p.info.lastName,
      gamesPlayed: statItem?.games || 0,
      goals: statItem?.goals || 0,
      points: statItem?.points || 0,
      jerseyNumber: p.info.primaryNumber,
      pims: statItem?.penaltyMinutes,
      playerId: p.info.id,
      plusMinus: statItem?.plusMinus || 0,
      powerPlayGoals: statItem?.powerPlayGoals || 0,
      powerPlayPoints: statItem?.powerPlayPoints || 0,
      shotsOnGoal: statItem?.shots || 0,
      teamId: p.teamId,
      teamName: p.teamName,
      isAvailable: p.isAvailable,
    };
  }
  