import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "adminSettings";

export type Team = {
  id: number;
  name: string;
  conference: {
    id: number;
    name: string;
  };
  division: {
    id: number;
    name: string;
  };
};

export type Settings = {
  season: number;
  entryOpenDateGMT: string;
  entryCloseDateGMT: string;
  playerPickCloseDateGMT: string;
  maxPicksPerEntry: number;
  maxSingleEntries: number;
  maxBundles: number;
  singleEntryPrice: number;
  bundleEntryPrice: number;
  allowedTeamIds: number[];
};

export type LoadSettingsReturn = {
  teams: Team[];
  settings: Settings;
};

var loadSettings = createAsyncAction<
  LoadSettingsReturn, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadSettings",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Admin/Settings`,
  pending: (state, action) => {
    state.loadSettings = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadSettings.isLoading = false;
    state.loadSettings.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadSettings.isLoading = false;
    state.loadSettings.error = action.payload;
  },
});

type UpdateSettingsReturn = {};

var updateSettings = createAsyncAction<
  UpdateSettingsReturn, //return type
  { settings: Settings }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "updateSettings",
  postAsJson: true,
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Admin/UpdateSeasonSettings`,
  pending: (state, action) => {
    state.updateSettings = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.updateSettings.isLoading = false;
    state.updateSettings.data = action.payload;
  },
  rejected: (state, action) => {
    state.updateSettings.isLoading = false;
    state.updateSettings.error = action.payload;
  },
});

type ClearCacheReturn = {};

var clearCache = createAsyncAction<
  ClearCacheReturn, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "clearCache",
  url: `${process.env.REACT_APP_API_BASE}/Admin/ClearCache`,
  getAuthBearerToken,
  pending: (state, action) => {
    state.clearCache = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.clearCache.isLoading = true;
    state.clearCache.data = action.payload;
  },
  rejected: (state, action) => {
    state.clearCache.isLoading = false;
    state.clearCache.error = action.payload;
  },
});

type State = {
  loadSettings: ReducerState<LoadSettingsReturn>;
  updateSettings: ReducerState<UpdateSettingsReturn>;
  clearCache: ReducerState<ClearCacheReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    loadSettings: {
      isLoading: false,
    },
    updateSettings: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadSettings.reducer,
    ...updateSettings.reducer,
    ...clearCache.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadSettings: loadSettings.action,
      updateSettings: updateSettings.action,
      clearCache: clearCache.action,
    },
  },
};

export default duck;
