import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { PlayerJson } from "pages/PlayerListing/apiTypes";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "standings";

export type EntryItem = {
  id: number;
  rank: number;
  teamName: string;
  entryName: string;
  goals: number;
  assists: number;
  total: number;
  userId: number;
};

export type PlayerItem = {
  playerId: number;
  teamId: number;
  teamName: string;
  lastName: string;
  firstName: string;
  position: "C" | "RW" | "LW" | "D" | "G";
  jerseyNumber?: string | null;
  goals: number;
  assists: number;
  points: number;
};

type LoadStandingsReturn = {
  items: EntryItem[];
  page: number;
  totalRecords: number;
  maxPoints: number;
};

var loadStandings = createAsyncAction<
  LoadStandingsReturn, //return type
  {
    page: number;
    searchText: string;
    option: string;
    userId: number;
    standingsType: number; // 0 = contestants, 1 = media, 2 = all
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadStandings",
  url: `${process.env.REACT_APP_API_BASE}/Standings/GetStandings`,
  postAsJson: true,
  // transformUrl: (url, params) => {
  //   return `${url}/${params.page}`;
  // },
  pending: (state, action) => {
    state.loadStandings.isLoading = true;
    if (action.params.page === 1) state.loadStandings.isReloading = true;
  },
  fulfilled: (state, action) => {
    state.loadStandings.isLoading = false;
    if (action.params.page === 1) state.loadStandings.isReloading = false;
    state.loadStandings.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadStandings.isLoading = false;
    if (action.params.page === 1) state.loadStandings.isReloading = false;
    state.loadStandings.error = action.payload;
  },
});

var loadMediaStandings = createAsyncAction<
  LoadStandingsReturn, //return type
  {
    page: number;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadMediaStandings",
  url: `${process.env.REACT_APP_API_BASE}/Standings/GetStandings`,
  postAsJson: true,
  transformQueryParams: (params) => {
    return {
      page:params.page,
      standingsType: 1
    };
  },
  pending: (state, action) => {
    state.loadMediaStandings.isLoading = true;
    if (action.params.page === 1) state.loadMediaStandings.isReloading = true;
  },
  fulfilled: (state, action) => {
    state.loadMediaStandings.isLoading = false;
    if (action.params.page === 1) state.loadMediaStandings.isReloading = false;
    state.loadMediaStandings.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadMediaStandings.isLoading = false;
    if (action.params.page === 1) state.loadMediaStandings.isReloading = false;
    state.loadMediaStandings.error = action.payload;
  },
});

type LoadStandingsTimeStampReturn = {
  date: string;
};

var loadStandingsTimeStamp = createAsyncAction<
  LoadStandingsTimeStampReturn, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadStandingsTimeStamp",
  url: `${process.env.REACT_APP_API_BASE}/Standings/GetStandingsTimeStamp`,
  postAsJson: true,
  // transformUrl: (url, params) => {
  //   return `${url}/${params.page}`;
  // },
  pending: (state, action) => {
    state.loadStandingsTimeStamp.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.loadStandingsTimeStamp.isLoading = false;
    state.loadStandingsTimeStamp.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadStandingsTimeStamp.isLoading = false;
    state.loadStandingsTimeStamp.error = action.payload;
  },
});

type GetEntryPlayersReturn = {
  name: string;
  players: PlayerItem[];
};

var getEntryPlayers = createAsyncAction<
  GetEntryPlayersReturn, //return type
  { entryId: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getEntryPlayers",
  url: `${process.env.REACT_APP_API_BASE}/Entries/GetEntryPlayers`,
  postAsJson: true,
  pending: (state, action) => {
    state.getEntryPlayers.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.getEntryPlayers.isLoading = false;
    state.getEntryPlayers.data = action.payload;
  },
  rejected: (state, action) => {
    state.getEntryPlayers.isLoading = false;
    state.getEntryPlayers.error = action.payload;
  },
});

export type TeamPicksItem = {
  count: number;
  percentage: number;
  teamId: number;
  teamName: string;
};

var getTeamPickTotals = createAsyncAction<
  TeamPicksItem[], //return type
  { topX: number }, //params,
  State
>({
  actionPrefix: controlName,
  actionName: "getTeamPickTotals",
  transformUrl: (url, params) => {
    return `${url}?topX=${params.topX}`;
  },
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetTeamPickTotals`,
  pending: (state, action) => {
    state.teamPickTotals = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.teamPickTotals.isLoading = false;
    state.teamPickTotals.data = action.payload;
  },
  rejected: (state, action) => {
    state.teamPickTotals.isLoading = false;
    state.teamPickTotals.error = action.payload;
  },
});

export type TopPicksItem = {
  count: number;
  percentage: number;
  playerInfo: PlayerJson;
};

var loadTopPicks = createAsyncAction<
  TopPicksItem[], //return type
  { topX: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadTopPicks",
  transformUrl: (url, params) => {
    return `${url}/${params.topX}`;
  },
  // getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetTopPicks`,
  pending: (state, action) => {
    state.topPicks = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.topPicks.isLoading = false;
    state.topPicks.data = action.payload;
  },
  rejected: (state, action) => {
    state.topPicks.isLoading = false;
    state.topPicks.error = action.payload;
  },
});

export type TopPlayersItem = {
  count: number;
  percentage: number;
  playerInfo: PlayerJson;
  goals: number;
  assists: number;
};

var loadTopPlayers = createAsyncAction<
  TopPlayersItem[], //return type
  { topX: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadTopPlayers",
  transformUrl: (url, params) => {
    return `${url}/${params.topX}`;
  },
  // getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetTopPlayers`,
  pending: (state, action) => {
    state.topPlayers = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.topPlayers.isLoading = false;
    state.topPlayers.data = action.payload;
  },
  rejected: (state, action) => {
    state.topPlayers.isLoading = false;
    state.topPlayers.error = action.payload;
  },
});

type State = {
  loadStandings: ReducerState<LoadStandingsReturn> & { isReloading: boolean };
  loadMediaStandings: ReducerState<LoadStandingsReturn> & { isReloading: boolean };
  getEntryPlayers: ReducerState<GetEntryPlayersReturn>;
  loadStandingsTimeStamp: ReducerState<LoadStandingsTimeStampReturn>;
  teamPickTotals: ReducerState<TeamPicksItem[]>;
  topPicks: ReducerState<TopPicksItem[]>;
  topPlayers: ReducerState<TopPlayersItem[]>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    loadStandings: { isLoading: false, isReloading: false },
    loadMediaStandings: { isLoading: false, isReloading: false },
    loadStandingsTimeStamp: { isLoading: false },
    getEntryPlayers: { isLoading: false },
    teamPickTotals: { isLoading: false },
    topPicks: { isLoading: false },
    topPlayers: { isLoading: false },
  } as State,
  reducers: {
    reset: (state) => {
      state.loadStandings = { isLoading: false, isReloading: false };
    },
    resetMedia: (state) => {
      state.loadMediaStandings = { isLoading: false, isReloading: false };
    },
  },
  extraReducers: {
    ...loadStandings.reducer,
    ...getEntryPlayers.reducer,
    ...getTeamPickTotals.reducer,
    ...loadTopPicks.reducer,
    ...loadStandingsTimeStamp.reducer,
    ...loadTopPlayers.reducer,
    ...loadMediaStandings.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadStandings: loadStandings.action,
      getEntryPlayers: getEntryPlayers.action,
      getTeamPickTotals: getTeamPickTotals.action,
      loadTopPicks: loadTopPicks.action,
      loadStandingsTimeStamp: loadStandingsTimeStamp.action,
      loadTopPlayers: loadTopPlayers.action,
      loadMediaStandings: loadMediaStandings.action,
    },
  },
};

export default duck;
