import styled from 'styled-components';

export const NavButton = styled.div`
  text-align: center;
  width: 100px;
`;

export const NavPageText = styled.div`
  flex: 1;
  text-align: center;
  margin: auto;
`;

export const Nav = styled.div`
  display: flex;
  margin-top: 10px;
  max-width: 900px;
`;

export const Table = styled.table`
  th,
  td {
    padding: 5px;
    vertical-align: top;
  }
  th {
    font-weight: bold;
    text-align: left;
  }
`;

export const DetailRow = styled.div`
  display: flex;
  max-width: 700px;
  > div:nth-child(1) {
    font-weight: bold;
    min-width: 150px;
  }
`;

export const TR = styled.tr`
  :hover {
    background-color: #f5f5f5;
    transition: background-color 0.1s ease-in-out;
  }
  cursor: pointer;
`;
