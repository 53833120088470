import { useState } from "react";
import styled from "styled-components";
import CheckboxButton from "./CheckboxButton/CheckboxButton";

export type Props = {
  message: string;
  showConfirm?: boolean;
  onConfirmChange?(confirmed: boolean): void;
};

const AdminInfoTopBar = (props: Props) => {
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);

  return (
    <TopBar>
      <div style={{alignContent:"center"}}>{props.message}</div>
      {props.showConfirm && (
        <div
          style={{
            display: "flex",
            margin: "-2px 0 auto 40px",
          }}
        >
          <div>
            <CheckboxButton
              onClick={() => {
                setConfirmStatus(!confirmStatus);
                props.onConfirmChange && props.onConfirmChange(!confirmStatus);
              }}
              state={confirmStatus ? "checked" : "unchecked"}
            />
          </div>
          <div style={{ margin: "auto 0 5px 10px" }}>Admin acknowledgement</div>
        </div>
      )}
    </TopBar>
  );
};

const TopBar = styled.div`
  background-color: #b43286;
  color: white;
  padding: 20px 20px;
  display: flex;
`;
export default AdminInfoTopBar;
