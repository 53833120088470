import React, { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import { useParams } from "react-router-dom";

import UserEntryEditor from "components/UserEntryEditorGrid/UserEntryEditorGrid";
import MainTemplate from "components/MainTemplate/MainTemplate";
import PlayerStatsCard from "components/PlayerStatsCard/PlayerStatsCard";
import { SimpleDialog } from "@nait-aits/ui";
import { playerMapper } from "components/helpers/PlayerFunctions";

import styled from "styled-components";
import AdminInfoTopBar from "components/AdminInfoTopBar";
import { useDeviceSize } from "hooks/useWindowSize";
import { PlayerJson } from "pages/PlayerListing/apiTypes";
import { ReducerState } from "@nait-aits/redux";
import { getJoyrideSteps } from "./components/JoyrideSteps";
import useDraftStatus from "hooks/useDraftStatus";

export type Props = {};

const EntryEditor = (props: Props) => {
  const params = useParams();
  const state = useReduxState((e) => e.userEntries);
  const playerStatsState = useReduxState((e) => e.playerListing);
  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const mainTemplateState = useReduxState((e) => e.mainTemplate.getSettings);

  const playerStatsActions = useActions().playerListing;
  const actions = useActions().userEntries;

  const [selectedPlayer, setSelectedPlayer] = useState<number | undefined>();
  const [adminConfirm, setAdminConfirm] = useState<boolean>(false);
  // const [initialized, setInitialized] = useState(false);

  const [playerStatsData, setPlayerStatsData] =
    useState<ReducerState<PlayerJson[]>>();

  const deviceSize = useDeviceSize(1000, 700);

  useEffect(() => {
    actions.getUserEntriesWithPlayers({
      userId: parseInt(params.userId ?? "0"),
    });
  }, []);

  useEffect(() => {
    setPlayerStatsData(
      playerStatsState.playerStats.find(
        (e) => e.id === userProfileState.data?.nhlSeason
      )?.data || {
        isLoading: true,
      }
    );
  }, [playerStatsState]);

  //only load when user simple has completed
  useEffect(() => {
    userProfileState.data?.nhlSeason &&
      playerStatsActions.getPlayerStats({
        year: userProfileState.data.nhlSeason,
      });
  }, [userProfileState.data]);

  const draftStatus = useDraftStatus();

  const isEditable =
    (userProfileState.data?.isAdmin || draftStatus.isPlayerPickOpen) ?? false;

  if (!userProfileState.data) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<Page>Loading...</Page>} />
      </>
    );
  }

  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  const adminMode = userIsAdmin && params.userId;

  if ((!userIsAdmin && params.userId) || draftStatus.draftHasNotStarted) {
    return (
      <>
        <MainTemplate
          responsiveBody={false}
          body={<Page>Access Denied.</Page>}
        />
      </>
    );
  }

// console.log(state.getUserEntries.data);

  return (
    <>
      <MainTemplate
        responsiveBody={false}
        showFooter={false}
        joyrideSettings={{
          steps: getJoyrideSteps(deviceSize),
          localStorageKey: "user-entries-page",
          autoStartIfNew: true,
          isReady:
            !!state.getUserEntries.data && !state.getUserEntries.isLoading,
        }}
        isLoading={playerStatsData?.isLoading || state.getUserEntries.isLoading}
        body={
          <Page isSmallScreen={deviceSize.small}>
            {state.getUserEntries.data && playerStatsData?.data && (
              <>
                {adminMode && (
                  <AdminInfoTopBar
                    message={`User: ${state.getUserEntries.data.displayName} - Team: ${state.getUserEntries.data.teamName}`}
                    onConfirmChange={(confirm) => {
                      setAdminConfirm(confirm);
                    }}
                    showConfirm
                  />
                )}
                {state.getUserEntries.data && playerStatsData.data && (
                  <UserEntryEditor
                    allPlayers={
                      playerStatsData.data?.map((p) =>
                        playerMapper(
                          p,
                          mainTemplateState.data?.settings.isPlayerPickOpen
                            ? "regular"
                            : "playoffs"
                        )
                      ) || []
                    }
                    onViewPlayerStats={(id) => {
                      setSelectedPlayer(id);
                    }}
                    entries={state.getUserEntries.data.entries.map((e, i) => {
                      return {
                        entryId: e.entryId,
                        entryName: e.entryName,
                        teamName: state.getUserEntries.data?.teamName ?? "",
                        playerIds: e.playerIds,
                      };
                    })}
                    onUserPlayerSelectionToggle={(
                      entryId,
                      playerId,
                      isSelected
                    ) => {
                      if (adminMode && !adminConfirm) {
                        alert(
                          "Please acknowledge that you are modifying the entries as an Administrator."
                        );
                      } else {
                        actions.toggleUserEntryPlayer({
                          entryId,
                          playerId,
                          isSelected,
                        });
                      }
                    }}
                    onEditEntryName={(entryId, name) => {
                      actions.updateEntryName({
                        entryId: entryId,
                        entryName: name,
                      });
                    }}
                    itemsLoading={state.toggleUserEntryPlayer.data || []}
                    maxPlayersPerEntry={
                      state.getUserEntries.data.maxPicksPerEntry
                    }
                    editable={isEditable}
                    isUpdatingEntryName={state.updateEntryName.isLoading}
                    standings={state.getUserEntries.data?.standings}
                  />
                )}
              </>
            )}
            {selectedPlayer && (
              <SimpleDialog
                handleBackgroundClick={() => {
                  setSelectedPlayer(undefined);
                }}
              >
                <PlayerStatsCard playerId={selectedPlayer} />
              </SimpleDialog>
            )}
          </Page>
        }
      />
    </>
  );
};

const Page = styled.div<{ isSmallScreen?: boolean }>`
  position: absolute;
  width: 100%;
`;

export default EntryEditor;
