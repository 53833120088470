import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { DetailRow, Table, TR } from "pages/Admin/sharedStyledComponents";
import { useState } from "react";
import JSONPretty from "react-json-pretty";
import { Order, OrderUser } from "../orders.duck";
import MoveEntriesDialog from "./MoveEntriesDialog";

type OrderListProps = {
  orders: Order[];
  isLoading: boolean;
  isSearchingUser: boolean;
  userResults: OrderUser[];
  onMoveEntries(orderId: number, toUserId: number): void;
  onSearchUser(searchText: string): void;
};

type DialogState = {
  open: boolean;
  orderId: number;
  user: OrderUser;
  entryIds: number[];
};

const OrdersTable = (props: OrderListProps) => {
  const [expandedIds, setExpandedIds] = useState<number[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    orderId: 0,
    user: {
      id: 0,
      displayName: "",
      email: "",
      authType: "",
    },
    entryIds: [],
  });

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Contestant</th>
            <th>Moneris ID</th>
            <th>Amount</th>
            <th>Entries</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {props.isLoading && (
            <tr>
              <td colSpan={6}>
                <CircularProgress />
              </td>
            </tr>
          )}
          {!props.isLoading &&
            props.orders.map((c) => {
              const expanded = expandedIds.indexOf(c.id) >= 0;
              return (
                <>
                  <TR
                    key={c.id}
                    onClick={() => {
                      expanded
                        ? setExpandedIds(expandedIds.filter((d) => d !== c.id))
                        : setExpandedIds([...expandedIds, c.id]);
                    }}
                  >
                    <td>{expanded ? <ExpandLess /> : <ExpandMore />}</td>
                    <td>{c.id}</td>
                    <td>
                      {c.fullName} (ID: {c.userId})
                    </td>
                    <td>{c.transactionId}</td>
                    <td style={{ textAlign: "right" }}>${c.amount}</td>
                    <td style={{ textAlign: "right" }}>{c.numberOfTickets}</td>
                    <td style={{ textAlign: "right" }}>
                      {moment.utc(c.date)
                        .tz("America/Edmonton")
                        .format("MMM D, YYYY HH:mm")}
                    </td>
                  </TR>
                  {expanded && (
                    <tr>
                      <td colSpan={7} style={{ paddingLeft: 40 }}>
                        <div style={{ margin: "10px 0" }}>
                          <button
                            className="nait-button nait-button--primary"
                            style={{ height: 45 }}
                            onClick={() => {
                              setDialogState({
                                open: true,
                                orderId: c.id,
                                user: {
                                  id: c.userId,
                                  displayName: c.fullName,
                                  email: c.email,
                                  authType: c.userAuthType,
                                },
                                entryIds: c.entryIds,
                              });
                            }}
                          >
                            Move Entries
                          </button>
                        </div>
                        <DetailRow>
                          <div>Payment Id:</div>
                          <div>{c.details.paymentId}</div>
                        </DetailRow>
                        <DetailRow>
                          <div>Email:</div>
                          <div>{c.email}</div>
                        </DetailRow>
                        <DetailRow>
                          <div>Auth Type:</div>
                          <div>{c.userAuthType}</div>
                        </DetailRow>
                        <DetailRow>
                          <div>Entry Ids:</div>
                          <div>{c.entryIds.join(", ")}</div>
                        </DetailRow>
                        <DetailRow>
                          <div>Order Data:</div>
                          <div>
                            <JSONPretty data={c.details.orderItemsJson} />
                          </div>
                        </DetailRow>
                        <DetailRow>
                          <div>Receipt Data:</div>
                          <div>
                            <JSONPretty data={c.details.receiptJsonData} />
                          </div>
                        </DetailRow>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
      <MoveEntriesDialog
        {...dialogState}
        onClose={() => {
          setDialogState({ ...dialogState, open: false });
        }}
        onConfirm={(orderId, toUserId) => props.onMoveEntries(orderId, toUserId)}
        onSearchUser={props.onSearchUser}
        isSearchingUser={props.isSearchingUser}
        userResults={props.userResults}
      />
    </>
  );
};

export default OrdersTable;

