import React, { useEffect } from "react";
import { useReduxState, useActions } from "store";
import { useParams, useNavigate } from "react-router-dom";

import MainTemplate from "components/MainTemplate/MainTemplate";
import { playerMapper } from "components/helpers/PlayerFunctions";

import styled from "styled-components";
import UserEntrySummary from "components/UserEntrySummary/UserEntrySummary";
import { useDeviceSize } from "hooks/useWindowSize";
// import moment from "moment";
import useDraftStatus from "hooks/useDraftStatus";
import moment from "moment-timezone";

export type Props = {};

const Summary = (props: Props) => {
  const history = useNavigate();
  const params = useParams();
  const state = useReduxState((e) => e.userEntries);
  const playerStatsState = useReduxState((e) => e.playerListing);
  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const settingsState = useReduxState((e) => e.mainTemplate.getSettings);
  const year = settingsState.data?.settings.season ?? -1;

  // const entryCloseDateGMT =
  //   useReduxState((e) => e.mainTemplate.getSettings).data?.settings
  //     .entryCloseDateGMT ?? "";

  const actions = useActions().userEntries;
  const playerStatsActions = useActions().playerListing;

  var playerStatsData = playerStatsState.playerStats.find((e) => e.id === year)
    ?.data || {
    isLoading: true,
  };

  useEffect(() => {
    actions.getUserEntriesWithPlayers({
      userId: parseInt(params.userId ?? "0"),
    });
    // playerStatsActions.getPlayerStats({ year: year });
  }, []);

  useEffect(() => {
    if (year > -1) {
      playerStatsActions.getPlayerStats({ year: year });
    }
  }, [year]);

  const deviceSize = useDeviceSize(1000, 700);
  const draftStatus = useDraftStatus();

  const startDateString = settingsState.data
    ? moment(settingsState.data.settings.entryOpenDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY")
    : "TBA";

  const endDateString = settingsState.data
    ? moment(settingsState.data.settings.playerPickCloseDateGMT)
        .tz("America/Edmonton")
        .format("MMMM DD, YYYY h:mm A")
    : "TBA";

  if (userProfileState.isLoading || state.getUserEntries.isLoading) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<Page>Loading...</Page>} />
      </>
    );
  }

  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  const userIsMedia = userProfileState.data?.isMedia ?? false;
  if (!userIsAdmin && params.userId) {
    return (
      <>
        <MainTemplate
          responsiveBody={false}
          body={<Page>Access Denied.</Page>}
        />
      </>
    );
  }

  if (draftStatus.draftHasNotStarted) {
    return (
      <>
        <MainTemplate
          responsiveBody={false}
          body={
            <Page>
              <div style={{ padding: 20 }}>
                Draft has not started yet. Come back on{" "}
                <span style={{ fontWeight: "bold" }}>{startDateString} </span>
                to purchase your entries.{" "}
              </div>
            </Page>
          }
        />
      </>
    );
  }

  const maxPick = state.getUserEntries.data?.maxPicksPerEntry ?? 0;
  const allEntriesCompleted =
    state.getUserEntries.data?.entries.filter(
      (c) => c.playerIds.length < maxPick
    ).length === 0;

  return (
    <>
      <MainTemplate
        responsiveBody={false}
        showFooter={false}
        body={
          <Page isSmallScreen={deviceSize.small}>
            {!state.getUserEntries.isLoading && !userProfileState.isLoading && (
              <>
                {!state.getUserEntries.data?.entries.length && (
                  <div
                    style={{ maxWidth: 1400, margin: "20px auto", padding: 20 }}
                  >
                    <div>
                      <h2>Manage Entries</h2>
                      <div>You have no entries.</div>
                      {draftStatus.isDraftOpen && (
                        <div style={{ marginTop: 20 }}>
                          <button
                            className="nait-button nait-button--primary"
                            disabled={userIsMedia}
                            onClick={() => {
                              history("/Order");
                            }}
                          >
                            Click Here to Purchase Entries
                          </button>
                          {userIsMedia && (
                            <div style={{ fontStyle: "italic", fontSize: 14 }}>
                              You are logged in as a media member. You are not
                              able to purchase entries.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {state.getUserEntries.data &&
                  state.getUserEntries.data.entries.length > 0 &&
                  playerStatsData.data && (
                    <>
                      <div style={{ maxWidth: 750, margin: "20px auto" }}>
                        <div>
                          <h2>My Entries</h2>
                        </div>
                        {draftStatus.isPlayerPickOpen && (
                          <>
                            {allEntriesCompleted && (
                              <div>
                                All of your entries have been filled with
                                selections. You are ready for the draft. Your
                                entries will be locked on{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {endDateString} Mountain Time.
                                </span>{" "}
                              </div>
                            )}
                            {!allEntriesCompleted && (
                              <div>
                                Below is a summary of your entries. You have
                                until{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {endDateString} Mountain Time
                                </span>{" "}
                                to make your selections.
                              </div>
                            )}
                          </>
                        )}
                        {!draftStatus.isPlayerPickOpen && (
                          <div>Player selection is now closed.</div>
                        )}

                        <div style={{ marginTop: 20 }}>
                          <button
                            className="nait-button nait-button--primary"
                            onClick={() =>
                              history(
                                params.userId
                                  ? `/UserEntries/Editor/${params.userId}`
                                  : "/UserEntries/Editor"
                              )
                            }
                          >
                            {draftStatus.isPlayerPickOpen
                              ? "Click here to make your selections"
                              : "View Grid"}
                          </button>
                          {!draftStatus.isPlayerPickOpen && (
                            <button
                              className="nait-button nait-button--primary"
                              onClick={() => {
                                history("/Standings");
                              }}
                            >
                              View Standings
                            </button>
                          )}
                        </div>
                      </div>
                      {state.getUserEntries.data && playerStatsData.data && (
                        <UserEntrySummary
                          teamName={state.getUserEntries.data.teamName}
                          entries={state.getUserEntries.data.entries.map(
                            (e, i) => {
                              return {
                                entryId: e.entryId,
                                entryName: e.entryName,
                                teamName:
                                  state.getUserEntries.data?.teamName ?? "",
                                playerIds: e.playerIds,
                              };
                            }
                          )}
                          allPlayers={
                            playerStatsData.data?.map((p) =>
                              playerMapper(p, "playoffs")
                            ) || []
                          }
                          maxPlayersPerEntry={
                            state.getUserEntries.data.maxPicksPerEntry
                          }
                          standings={state.getUserEntries.data?.standings}
                          showPoints={true || !draftStatus.isDraftOpen}
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </Page>
        }
        isLoading={playerStatsData.isLoading || state.getUserEntries.isLoading}
      />
    </>
  );
};

const Page = styled.div<{ isSmallScreen?: boolean }>`
  // position: absolute;
  // margin-top: ${(p) => (p.isSmallScreen ? "-15px" : "-10px")};
  width: 100%;
`;

export default Summary;
