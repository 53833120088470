import React from "react";
import { FetchError } from "@nait-aits/redux";

import styled from "styled-components";

type InnerException = {
  type: string;
  status: string;
  title: string;
  detail: string;
};

type KnownError = {
  codeMatch: string | number;
  title: string;
  message: string | JSX.Element;
};

const knownErrors: KnownError[] = [
  {
    codeMatch: "no_account_error",
    title: "Not Logged In",
    message: <div>You must be logged in to view this page.</div>,
  },
  {
    codeMatch: 0,
    title: "Server Connection",
    message: <div>We are unable to connect to the server.</div>,
  },
  {
    codeMatch: 500,
    title: "There was an error processing your request",
    message: (
      <div>There was an error processing your request. Please try again.</div>
    ),
  },
  {
    codeMatch: 901,
    title: "Licence Error",
    message: (
      <div>Maximum number of entries (per gaming license) exceeded.</div>
    ),
  },
  {
    codeMatch: 900,
    title: "Quantity Error",
    message: (
      <div>Please enter valid ticket quantity.</div>
    ),
  },
];

export type Props = {
  error: FetchError | string | undefined;
};

const ErrorBox = (props: Props) => {
  const { error } = props;

  if (!error) return <></>;

  var inner: InnerException;

  console.log(error);
  

  if (error instanceof FetchError) {
    var known = knownErrors.find((e) => e.codeMatch === error.errorCode);
    if (known) {
      return (
        <ErrorWrapper>
          <h2>{known.title}</h2>
          <div>{known.message}</div>
        </ErrorWrapper>
      );
    }

    try {
      inner = JSON.parse(error.message);

      if (inner.detail) {
        return (
          <ErrorWrapper>
            <h2>{inner.title}</h2>
            <div>status: {inner.status}</div>
            <div>{inner.detail}</div>
          </ErrorWrapper>
        );
      }
    } catch (e) {}

    return (
      <ErrorWrapper>
        <h2>{error.errorCode}</h2>
        <div>{error.status}</div>
        <div>{error.message}</div>
      </ErrorWrapper>
    );
  } else {
    try {
      inner = JSON.parse(error);

      if (inner.detail) {
        return (
          <ErrorWrapper>
            <div>Title: {inner.title}</div>
            <div>message: {inner.detail}</div>
            <div>status: {inner.status}</div>
          </ErrorWrapper>
        );
      }
    } catch (e) {}
  }

  return (
    <ErrorWrapper>
      <h2>Error</h2>
      <div>{error}</div>
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  padding: 10px;
  border: 1px solid red;
  background-color: pink;
  border-radius: 0.25em;
`;

export default ErrorBox;
