import styled from "styled-components";
import MainTemplate from "components/MainTemplate/MainTemplate";
import React, { useEffect, useRef, useState } from "react";
import { useActions, useReduxState } from "store";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
} from "@mui/material";
import moment from "moment";
import { Winner } from "./winners.duck";
import { useReactToPrint } from "react-to-print";
import { Article, MinorCrashOutlined, Print } from "@mui/icons-material";
import { openFile } from "../Reports/Reports";
import { PlayerJson } from "pages/PlayerListing/apiTypes";

export type Props = {};

type SubtractionItem = {
  id: string;
  playerId: number;
  value: number;
};

function getWinnersWithSubtractions(
  winners: Winner[],
  subtractions: SubtractionItem[]
) {
  var temp = winners.map((c) => {
    return {
      ...c,
      players: c.players.map((p) => {
        const sub = subtractions.find((e) => e.playerId === p.playerId);
        return {
          ...p,
          total: p.total - (sub?.value ?? 0),
        };
      }),
    };
  }).map((c) => {
    return {
      ...c,
      total: c.players.reduce((a, b) => a + b.total, 0),
    };
  })
  .sort((a, b) => b.total - a.total)
  .map((c, index) => {
    return {
      ...c,
      rank: index + 1,
    };
  });
  return temp;
}

const WinnersWithSubtractions = (props: Props) => {
  const state = useReduxState((e) => e.winners);
  const actions = useActions().winners;

  const settingsState = useReduxState((e) => e.mainTemplate);
  const playerStatsState = useReduxState((e) => e.playerListing);
  const playerStatsActions = useActions().playerListing;

  const year = settingsState.getSettings.data?.settings.season ?? 0;

  var playerStatsData = playerStatsState.playerStats.find((e) => e.id === year)
    ?.data || {
    isLoading: true,
  };

  const [date, setDate] = useState<string>("");

  const [subtractions, setSubtractions] = useState<SubtractionItem[]>([]);
  const [openSubtractions, setOpenSubtractions] = useState<boolean>(false);

  const [isDownloadingExcel, setIsDownloadingExcel] = useState<boolean>(false);
  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    return () => {
      actions.reset();
    };
  }, []);

  useEffect(() => {
    playerStatsActions.getPlayerStats({
      year: settingsState.getSettings.data?.settings.season ?? 0,
    });
  }, [year]);

  const userIsAdmin = userProfileState.data?.isAdmin ?? false;

  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  const WinnersWithSubtractions = getWinnersWithSubtractions(
    state.loadWinners.data?.winners ?? [],
    subtractions
  );

  return (
    <div>
      <MainTemplate
        body={
          <div>
            <h1>Winners</h1>
            <div>
              List winners (entries with rank less or equal than 100) on the
              following date:{" "}
            </div>
            <TextField
              style={{ width: 200, marginTop: 5 }}
              label="Date"
              variant="filled"
              value={moment(date).local().format("YYYY-MM-DD")}
              type="date"
              onChange={(e) => {
                const dateString = moment(e.target.value)
                  .endOf("day")
                  .toISOString();
                setDate(dateString);
                actions.loadWinners({ date: dateString });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div ref={printRef} style={{ padding: "40px 0" }}>
              {state.loadWinners.isLoading && <CircularProgress />}
              {!state.loadWinners.isLoading && (
                <>
                  {state.loadWinners.data?.winners && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handlePrint}
                        endIcon={<Print />}
                      >
                        Print All
                      </Button>
                      <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        disabled={isDownloadingExcel}
                        onClick={() => {
                          setIsDownloadingExcel(true);
                          openFile(
                            `${process.env.REACT_APP_API_BASE}/Standings/DownloadWinnersExcel`,
                            "WRD-Winners.xlsx",
                            () => {
                              setIsDownloadingExcel(false);
                            }
                          );
                        }}
                        endIcon={<Article />}
                      >
                        Excel Summary
                      </Button>

                      <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        onClick={() => {
                          setOpenSubtractions(true);
                        }}
                        endIcon={<MinorCrashOutlined />}
                      >
                        Subtractions
                      </Button>
                    </>
                  )}
                  {state.loadWinners.data &&
                    WinnersWithSubtractions.map((c, index) => {
                      return (
                        <WinnerItem
                          key={index}
                          subtractions={subtractions}
                          winner={c}
                        />
                      );
                    })}
                </>
              )}
            </div>
            <SubtractionsDialog
              open={openSubtractions}
              subtractions={subtractions}
              players={playerStatsData.data ?? []}
              onAdd={(sub) => {
                setSubtractions([...subtractions, sub]);
              }}
              onRemove={(sub) => {
                setSubtractions(subtractions.filter((c) => c !== sub));
              }}
              onClose={() => {
                setOpenSubtractions(false);
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export type ItemProps = {
  winner: Winner;
  subtractions: SubtractionItem[];
};

const WinnerItem = (props: ItemProps) => {
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <WinnerItemContainer ref={printRef}>
      <div style={{ marginBottom: 20, position: "relative" }}>
        <IconButton
          style={{ marginLeft: 5, position: "absolute", left: 175 }}
          onClick={handlePrint}
        >
          <Print />
        </IconButton>
        <Table>
          <tbody>
            <ContactTR>
              <td>Rank</td>
              <td>{props.winner.rank} </td>
            </ContactTR>
            <ContactTR>
              <td>Total Points</td>
              <td>
                <div>{props.winner.total}</div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Team Name</td>
              <td>{props.winner.user.teamName}</td>
            </ContactTR>
            <ContactTR>
              <td>User Name</td>
              <td>
                <div>{props.winner.user.fullName}</div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Address</td>
              <td>
                <div>{props.winner.user.address}</div>
                <div>
                  {props.winner.user.city}, AB, {props.winner.user.postalCode}
                </div>
              </td>
            </ContactTR>
            <ContactTR>
              <td>Email</td>
              <td>{props.winner.user.email}</td>
            </ContactTR>
            <ContactTR>
              <td>Phone</td>
              <td>{props.winner.user.phone}</td>
            </ContactTR>
          </tbody>
        </Table>
      </div>
      <Table>
        <thead>
          <TR>
            <TH>Player</TH>
            <TH>Team</TH>
            <TH>Goals</TH>
            <TH>Assists</TH>
            <TH>Total</TH>
          </TR>
        </thead>
        <tbody>
          {props.winner.players.map((c, index) => {
            const sub = props.subtractions.find(
              (e) => e.playerId === c.playerId
            );
            return (
              <TR key={index}>
                <TD width={175} center={false}>
                  {c.name}
                </TD>
                <TD width={175} center={false}>
                  {c.teamName}
                </TD>
                <TD>{c.goals}</TD>
                <TD>{c.assists}</TD>
                <TD>{c.total}</TD>
              </TR>
            );
          })}
          <TR>
            <TD></TD>
            <TD center={false} style={{ fontWeight: "bold" }}>
              Total
            </TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.goals}</TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.assists}</TD>
            <TD style={{ fontWeight: "bold" }}>{props.winner.total}</TD>
          </TR>
        </tbody>
      </Table>
    </WinnerItemContainer>
  );
};

function SubtractionsDialog(props: {
  subtractions: SubtractionItem[];
  open: boolean;
  players: PlayerJson[];
  onAdd: (sub: SubtractionItem) => void;
  onRemove: (sub: SubtractionItem) => void;
  onClose: () => void;
}) {
  const [newItem, setNewItem] = useState<SubtractionItem>({
    id: Date.now().toString(),
    playerId: 0,
    value: 0,
  });

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div style={{ padding: 20 }}>
        <h2>Subtractions</h2>
        <Table>
          <thead>
            <TR>
              <TH>Player</TH>
              <TH>Subtraction</TH>
              <TH></TH>
            </TR>
          </thead>
          <tbody>
            {props.subtractions.map((c, index) => {
              const player = props.players.find((e) => e.info.id === c.playerId);
              return (
                <TR key={index}>
                  <TD width={175} center={false}>
                    {`${player?.info
                      .fullName} (${player?.info.id} - ${player?.teamName})` ?? "Unknown"}
                  </TD>
                  <TD>{c.value}</TD>
                  <TD>
                    <Button
                      onClick={() => {
                        props.onRemove(c);
                      }}
                    >
                      Remove
                    </Button>
                  </TD>
                </TR>
              );
            })}
            <TR>
              <TD width={175} center={false}>
                <Autocomplete
                  options={
                    props.players
                      .map((e) => ({
                        id: e.info.id,
                        label: `${e.info.fullName} (${e.info.id} - ${e.teamName})`,
                      }))
                      .sort((a, b) => (b.label > a.label ? -1 : 0)) ?? []
                  }
                  style={{ width: 300 }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Player" variant="filled" />
                  )}
                  onChange={(e, value) => {
                    setNewItem({ ...newItem, playerId: value?.id ?? 0 });
                  }}
                />
              </TD>
              <TD>
                <TextField
                  label="Subtraction"
                  variant="filled"
                  value={newItem.value}
                  onChange={(e) => {
                    setNewItem({ ...newItem, value: parseInt(e.target.value) });
                  }}
                />
              </TD>
              <TD>
                <Button
                  onClick={() => {
                    props.onAdd(newItem);
                    setNewItem({
                      id: Date.now().toString(),
                      playerId: 0,
                      value: 0,
                    });
                  }}
                >
                  Add
                </Button>
              </TD>
            </TR>
          </tbody>
        </Table>
        <Button onClick={props.onClose}>Close</Button>
      </div>
    </Dialog>
  );
}

const WinnerItemContainer = styled.div`
  padding: 20px 0;
  width: fit-content;
  border-bottom: dotted 5px black;
  @media print {
    page-break-after: always;
    margin: 20px;
  }
`;

const Table = styled.table`
  font-size: 16px;
  // width: 600px;
`;
const TR = styled.tr<{ noBorder?: boolean }>`
  ${(p) =>
    !p.noBorder &&
    `
      border-bottom: 1px solid #c1c1c1;    
    `}
`;

const ContactTR = styled.tr`
  > td:first-child {
    font-weight: bold;
    width: 110px;
  }
`;
const TD = styled.td<{ width?: number; center?: boolean }>`
  padding: 5px;
  vertical-align: middle;
  ${(p) => `width: ${p.width ? p.width : "100"}px;`}
  ${({ center = true }) => center && `text-align: center`}
`;

const TH = styled.th`
  font-weight: bold;
  padding: 5px;
  text-align: center;
  background-color: #33b3e5;
  color: #fff;
`;

export default WinnersWithSubtractions;
