import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "adminContestantListing";

export type ContestantEntry = {
  contestant: ContestantInfo;
  entryId: number;
  orderId: number;
  ticketNumber: number;
  isActive: boolean;
  isMedia: boolean;
  pickCount: number;
  isUpdating: boolean;
  entryType: string;
  logValidationStatus: string;
};

export type ContestantInfo = {
  id: number;
  fullName: string;
  teamName: string;
  address: string;
  addressLine2: string;
  city: string;
  province: string;
  phone: string;
  email: string;
  postalCode: string;
  authType: string;
};

type GetAllContestantsReturn = {
  contestants: ContestantEntry[];
  totalRecords: number;
  page: number;
};

export type SearchOption =
  | "complete"
  | "incomplete"
  | "empty"
  | "valid"
  | "invalid"
  | "media"
  | "contestant";

var getContestants = createAsyncAction<
  GetAllContestantsReturn, //return type
  { searchText: string; page: number; options: SearchOption[] }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getContestants",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetAllContestants`,
  pending: (state, action) => {
    state.getContestants = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getContestants.isLoading = false;
    state.getContestants.data = action.payload;
  },
  rejected: (state, action) => {
    state.getContestants.isLoading = false;
    state.getContestants.error = action.payload;
  },
});

type UpdateEntryStatusReturn = {
  entryId: number;
  newStatus: boolean;
};

var updateEntryStatus = createAsyncAction<
  UpdateEntryStatusReturn, //return type
  { entryId: number; newStatus: boolean }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "updateEntryStatus",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/Admin/UpdateEntryStatus`,
  pending: (state, action) => {
    state.updateEntryStatus.isLoading = true;
    var found = state.getContestants.data?.contestants.find(
      (e) => e.entryId === action.params.entryId
    );
    if (found) {
      found.isUpdating = true;
    }
  },
  fulfilled: (state, action) => {
    state.updateEntryStatus.isLoading = false;
    var found = state.getContestants.data?.contestants.find(
      (e) => e.entryId === action.params.entryId
    );
    if (found) {
      found.isUpdating = false;
      found.isActive = action.payload.newStatus;
    }
  },
  rejected: (state, action) => {
    state.updateEntryStatus.isLoading = false;
    var found = state.getContestants.data?.contestants.find(
      (e) => e.entryId === action.params.entryId
    );
    if (found) {
      found.isUpdating = false;
    }
    state.updateEntryStatus.error = action.payload;
  },
});

export type PlayerSelectionLogReturn = {
  ticketId: string;
  name: string;
  sequence: string;
  logs: PlayerSelectionLog[];
};

export type PlayerSelectionLog = {
  action: string;
  playerName: string;
  performedBy: string;
  timeStamp: string;
  validTime: boolean;
};

var getPlayerSelectionLogs = createAsyncAction<
  PlayerSelectionLogReturn, //return type
  { entryId: number; userId: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getPlayerSelectionLogs",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetPlayerSelectionUpdateLogs`,
  pending: (state, action) => {
    state.getPlayerSelectionLogs = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getPlayerSelectionLogs.isLoading = false;
    state.getPlayerSelectionLogs.data = action.payload;
  },
  rejected: (state, action) => {
    state.getPlayerSelectionLogs.isLoading = false;
    state.getPlayerSelectionLogs.error = action.payload;
  },
});

export type EmailResponse = {
  statusCode: number;
  isSuccessStatusCode: boolean;
  emailContentHtml: string;
  emailContentPlainText: string;
};

var sendTestFirstRoundLetter = createAsyncAction<
  EmailResponse, //return type
  { userId: number; date: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "sendTestFirstRoundLetters",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/Email/SendTestFirstRoundLetter`,
  pending: (state, action) => {
    state.sendTestFirstRoundLetter = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.sendTestFirstRoundLetter.isLoading = false;
    state.sendTestFirstRoundLetter.data = action.payload;
  },
  rejected: (state, action) => {
    state.sendTestFirstRoundLetter.isLoading = false;
    state.sendTestFirstRoundLetter.error = action.payload;
  },
});

export type FirstRoundLettersReturn = {
  count: number;
};

var sendFirstRoundLetters = createAsyncAction<
  { count: number }, //return type
  { date: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "sendFirstRoundLetters",
  getAuthBearerToken,
  postAsJson: true,
  url: `${process.env.REACT_APP_API_BASE}/Email/SendFirstRoundLetters`,
  pending: (state, action) => {
    state.sendFirstRoundLetters = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.sendFirstRoundLetters.isLoading = false;
    state.sendFirstRoundLetters.data = action.payload;
  },
  rejected: (state, action) => {
    state.sendFirstRoundLetters.isLoading = false;
    state.sendFirstRoundLetters.error = action.payload;
  },
});

export type FirstRoundLettersData = {
  processIsRunning: boolean;
  lastStart: string;
  alreadySent: number;
  toSend: number;
};

var getFirstRoundLettersData = createAsyncAction<
  FirstRoundLettersData, //return type
  undefined, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getFirstRoundLettersData",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Email/GetFirstRoundLettersData`,
  pending: (state, action) => {
    state.getFirstRoundLettersData = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getFirstRoundLettersData.isLoading = false;
    state.getFirstRoundLettersData.data = action.payload;
  },
  rejected: (state, action) => {
    state.getFirstRoundLettersData.isLoading = false;
    state.getFirstRoundLettersData.error = action.payload;
  },
});

type State = {
  getContestants: ReducerState<GetAllContestantsReturn>;
  updateEntryStatus: ReducerState<UpdateEntryStatusReturn>;
  getPlayerSelectionLogs: ReducerState<PlayerSelectionLogReturn>;
  sendTestFirstRoundLetter: ReducerState<EmailResponse>;
  // sendFirstRoundLetters: ReducerState<EmailResponse[]>;
  sendFirstRoundLetters: ReducerState<FirstRoundLettersReturn>;
  getFirstRoundLettersData: ReducerState<FirstRoundLettersData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getContestants: { isLoading: true },
    updateEntryStatus: { isLoading: false },
    sendTestFirstRoundLetter: { isLoading: false },
    sendFirstRoundLetters: { isLoading: false },
  } as State,
  reducers: {
    reset: (state) => {
      state.sendTestFirstRoundLetter = {
        isLoading: false,
      };
      state.sendFirstRoundLetters = {
        isLoading: false,
      };
    },
  },
  extraReducers: {
    ...getContestants.reducer,
    ...updateEntryStatus.reducer,
    ...getPlayerSelectionLogs.reducer,
    ...sendTestFirstRoundLetter.reducer,
    ...sendFirstRoundLetters.reducer,
    ...getFirstRoundLettersData.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getContestants: getContestants.action,
      updateEntryStatus: updateEntryStatus.action,
      getPlayerSelectionLogs: getPlayerSelectionLogs.action,
      sendTestFirstRoundLetter: sendTestFirstRoundLetter.action,
      sendFirstRoundLetters: sendFirstRoundLetters.action,
      getFirstRoundLettersData: getFirstRoundLettersData.action,
    },
  },
};

export default duck;
