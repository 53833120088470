import { createSlice } from "@reduxjs/toolkit";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "newOrder";

type State = {
  singles: number;
  bundles: number;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    bundles: 0,
    singles: 0,
  } as State,
  reducers: {
    incrementBundle: (state) => {
      state.bundles = state.bundles + 1;
    },
    decrementBundle: (state) => {
      if (state.bundles > 0) state.bundles = state.bundles - 1;
    },
    incrementSingles: (state) => {
      state.singles = state.singles + 1;
      if (state.singles === 2) {
        state.singles = 0;
        state.bundles = state.bundles + 1;
      }
    },
    decrementSingles: (state) => {
      if (state.singles > 0) state.singles = state.singles - 1;
    },
    reset: (state) => {
      state.bundles = 0;
      state.singles = 0;
    },
  },
  extraReducers: {},
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
    },
  },
};

export default duck;
