import mainTemplate from "components/MainTemplate/mainTemplate.duck";
import playerCard from "components/PlayerStatsCard/playerStatsCard.duck";
import newOrder from "./NewOrder/newOrder.duck";

const ret = {
  reducer: {
    ...mainTemplate.reducer,
    ...playerCard.reducer,
    ...newOrder.reducer,
  },
  actions: {
    ...mainTemplate.actions,
    ...playerCard.actions,
    ...newOrder.actions,
  },
};

export default ret;
