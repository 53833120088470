import { useEffect, useState } from "react";
import Joyride, { EVENTS, ACTIONS, Step, CallBackProps } from "react-joyride";

export type Props = {
  steps: (Step & { hidden?: boolean })[];
  stepCallback?: (event: CallBackProps) => void;
  onTourComplete?: (event: CallBackProps) => void;
  localStorageKey: string;
};

//just  a fancier name if people want it
export type JoyrideSettings = Props;

const JoyrideTour = (props: Props) => {
  const { steps, stepCallback, onTourComplete, localStorageKey } = props;

  const [initialized, setInitialized] = useState(false);

  const [stepIndex, setStepIndex] = useState(0);
  const next = () => setStepIndex(stepIndex + 1);

  useEffect(() => {
    setTimeout(() => {
      setInitialized(true);
    }, 100);
  }, [props]);

  console.log(initialized);

  if (!initialized) return <></>;

  return (
    <>
      <Joyride
        showSkipButton
        disableScrolling
        continuous
        hideBackButton
        disableOverlayClose
        disableCloseOnEsc
        styles={{ options: { zIndex: 10000 } }}
        stepIndex={stepIndex}
        steps={steps.filter((e) => !e.hidden)}
        callback={(e) => {
          if (e.type === EVENTS.TOUR_START) {
            console.log("start");

            if (localStorageKey) localStorage[localStorageKey] = "1";
            return;
          }
          if (e.type === EVENTS.TOUR_END || e.action === ACTIONS.CLOSE) {
            onTourComplete?.(e);
            return;
          }
          if (e.type === EVENTS.STEP_AFTER) {
            next();
          }
          stepCallback?.(e);
        }}
      />
    </>
  );
};

export const JoyrideStepHelper = (props: {
  content: string | JSX.Element;
  note?: string | JSX.Element;
}) => {
  return (
    <div>
      <div>{props.content}</div>
      {props.note && (
        <div style={{ marginTop: 20, fontStyle: "italic" }}>
          <span style={{ fontWeight: "bold" }}>Note: </span> {props.note}
        </div>
      )}
    </div>
  );
};

export default JoyrideTour;
