import { TextField } from "@mui/material";
import MainTemplate from "components/MainTemplate/MainTemplate";
import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import OrdersTable from "./components/OrdersTable";
import { Nav, NavButton, NavPageText } from "../sharedStyledComponents";
import moment from "moment";

export type Props = {};

const Orders = (props: Props) => {
  const state = useReduxState((e) => e.adminOrders);
  const actions = useActions().adminOrders;

  const [searchText, setSearchText] = useState<string>("");
  //So we're not grabbing too many orders on initial load on the server side.
  const [fromDate, setFromDate] = useState<string>(moment().startOf('day').format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  var searchDelay: NodeJS.Timeout;

  useEffect(() => {
    actions.getOrders({ searchText, page, fromDate, toDate });
  }, []);

  useEffect(() => {
    searchDelay = setTimeout(() => {
      actions.getOrders({ searchText, page, fromDate, toDate });
    }, 500);
    return () => {
      clearTimeout(searchDelay);
    };
  }, [searchText]);

  useEffect(() => {
    if (!state.moveEntries.isLoading && !state.orders.isLoading) {
      actions.getOrders({ searchText, page, fromDate, toDate });
    }
  }, [state.moveEntries.data]);

  const totalRecords = state.orders.data?.totalRecords ?? 0;
  const pageTotal = Math.ceil(totalRecords / 20);

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  return (
    <MainTemplate
      body={
        <div>
          <h1>Orders</h1>
          <div style={{ marginBottom: 20 }}>
            <TextField
              style={{ width: 400, marginRight: 10, marginBottom: 10 }}
              label="Search by Order ID, Trans. ID, Name, or Email"
              variant="filled"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <TextField
              style={{ width: 200, marginRight: 10 }}
              label="From"
              variant="filled"
              value={fromDate}
              type="date"
              onChange={(e) => {
                setFromDate(e.target.value);
                actions.getOrders({
                  searchText,
                  page,
                  fromDate: e.target.value,
                  toDate,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              style={{ width: 200 }}
              label="To"
              variant="filled"
              value={toDate}
              type="date"
              onChange={(e) => {
                setToDate(e.target.value);
                actions.getOrders({
                  searchText,
                  page,
                  fromDate,
                  toDate: e.target.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div style={{margin: "10px 0"}}>
              Total sales: ${state.orders.data?.totalSales ?? 0}
          </div>

          <OrdersTable
            orders={state.orders.data?.orders ?? []}
            isLoading={state.orders.isLoading}
            isSearchingUser={state.searchUser.isLoading}
            userResults={state.searchUser.data?.users ?? []}
            onSearchUser={(searchText) => {
              searchText.length > 0 && actions.searchUser({ searchText });
            }}
            onMoveEntries={(orderId, toUserId) => {
              actions.moveEntries({
                orderId,
                toUserId,
              });
            }}
          />
          <Nav>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === 1}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page - 1);
                  actions.getOrders({
                    searchText,
                    page: page - 1,
                    fromDate,
                    toDate,
                  });
                }}
              >
                Previous
              </button>
            </NavButton>
            <NavPageText>
              Page {page} of {pageTotal} ({totalRecords} records)
            </NavPageText>
            <NavButton>
              <button
                style={{ width: "100%" }}
                disabled={page === pageTotal || pageTotal === 0}
                className="nait-button nait-button--primary"
                onClick={() => {
                  setPage(page + 1);
                  actions.getOrders({
                    searchText,
                    page: page + 1,
                    fromDate,
                    toDate,
                  });
                }}
              >
                Next
              </button>
            </NavButton>
          </Nav>
        </div>
      }
    />
  );
};

export default Orders;
