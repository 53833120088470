import React, { useEffect } from "react";
import { useReduxState, useActions } from "store";
import { useParams, useNavigate } from "react-router-dom";

import MainTemplate from "components/MainTemplate/MainTemplate";

import styled from "styled-components";
import { useDeviceSize } from "hooks/useWindowSize";
import Profile from "./components/Profile";
import Orders from "./components/Orders";
import useDraftStatus from "hooks/useDraftStatus";

export type Props = {};

const Summary = (props: Props) => {
  const state = useReduxState((e) => e.profile);
  const actions = useActions().profile;

  const history = useNavigate();
  const params = useParams();

  const deviceSize = useDeviceSize();
  const draftStatus = useDraftStatus();

  //   const largeScreen = deviceSize === "lg";
  //   const mediumScreen = deviceSize === "md" || largeScreen;
  //   const smallScreen = !largeScreen && !mediumScreen;

  var mode = params.mode?.toLowerCase() || "profile";

  useEffect(() => {
    actions.getUser({});
  }, []);

  return (
    <>
      <MainTemplate
        responsiveBody={false}
        body={
          <Page isSmallScreen={deviceSize.small}>
            <PageMenu>
              {/* <PageMenuItem
                selected={mode === "entries"}
                onClick={() => {
                  history("/My2");
                }}
              >
                Entries
              </PageMenuItem> */}
              <PageMenuItem
                selected={mode === "profile"}
                onClick={() => {
                  history("/My/Profile");
                }}
              >
                Profile
              </PageMenuItem>
              <PageMenuItem
                selected={mode === "orders"}
                onClick={() => {
                  history("/My/Orders");
                }}
              >
                Order History
              </PageMenuItem>
            </PageMenu>
            {state.getUser.data && (
              <PageContents>
                {/* {mode === "entries" && <div>Entries</div>} */}
                {mode === "profile" && (
                  <div>
                    <Profile
                      user={state.getUser.data}
                      onSave={(user) => {
                        actions.updateUserProfile({
                          data: user,
                          onComplete: () => {
                            setTimeout(() => {
                              actions.getUser({});
                            }, 100);
                          },
                        });
                      }}
                      isSaving={false}
                      canChangeTeamName={!draftStatus.draftHasEnded}
                    />
                  </div>
                )}
                {mode === "orders" && (
                  <div>
                    <Orders user={state.getUser.data} />
                  </div>
                )}
              </PageContents>
            )}
          </Page>
        }
        isLoading={state.getUser.isLoading || state.updateUserProfile.isLoading}
      />
    </>
  );
};

const PageContents = styled.div`
  margin: 20px auto;
  width: fit-content;
`;

const PageMenu = styled.div`
  // background-color: #002d74;
  background-color: #0090c9;
  margin-top: -10px;
  // padding: 10px;
  color: white;
  justify-content: center;
  display: flex;
  position: sticky;
  top: 0px;
`;

const PageMenuItem = styled.div<{ selected?: boolean }>`
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    // background-color: #02a0df;
    background-color: #002d74;
  `}
`;

const Page = styled.div<{ isSmallScreen?: boolean }>`
  // position: absolute;
  // margin-top: ${(p) => (p.isSmallScreen ? "-15px" : "-10px")};
  width: 100%;
`;

export default Summary;
