import { SimpleDialog } from "@nait-aits/ui";
import { currencyFormatter } from "components/helpers/CurrencyFormatter";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import { GetUserReturn, UserOrder } from "../userProfile.duck";

export type Props = { user: GetUserReturn };

const Orders = (props: Props) => {
  const { user } = props;

  const { userOrders } = user;

  const [selectedOrder, setSelectedOrder] =
    useState<UserOrder | undefined>(undefined);

  return (
    <>
      {selectedOrder && (
        <SimpleDialog handleBackgroundClick={() => setSelectedOrder(undefined)}>
          <div>
            <ReceiptPanel data={selectedOrder} />
          </div>
        </SimpleDialog>
      )}
      <div>
        {userOrders.length === 0 && <div>No orders found.</div>}
        {userOrders.length > 0 && (
          <div>
            <Table>
              <thead>
                <tr>
                  <TH>Order Id</TH>
                  <TH>Order Date</TH>
                  <TH>Order Amount</TH>
                  <TH></TH>
                </tr>
              </thead>
              <tbody>
                {userOrders.map((e) => (
                  <tr key={`o_${e.id}`}>
                    <TD>{e.id}</TD>
                    <TD width={200}>
                      {moment(e.dateCreated).format("MMM DD, YYYY")}
                    </TD>
                    <TD width={150}>{currencyFormatter.format(e.total)}</TD>
                    <TD>
                      <button
                        className="nait-button nait-button--primary"
                        onClick={() => {
                          setSelectedOrder(e);
                        }}
                      >
                        View
                      </button>
                    </TD>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

const ReceiptPanel = (props: { data: UserOrder }) => {
  const { id, total, numberOfEntries } = props.data;

  return (
    <ReceiptPanelWrapper>
      <LabelAndValue label={"Order Id"} value={id.toString()} />
      {/* <LabelAndValue label={"Transaction Id"} value={transactionId} /> */}
      <LabelAndValue
        label={"Number of Entries Purchased"}
        value={numberOfEntries?.toString()}
      />
      {/* <LabelAndValue
        label={"Subtotal"}
        value={currencyFormatter.format(subtotal)}
      />
      <LabelAndValue label={"Taxes"} value={currencyFormatter.format(taxes)} /> */}
      <LabelAndValue label={"Total"} value={currencyFormatter.format(total)} />
    </ReceiptPanelWrapper>
  );
};

const LabelAndValue = (props: { label: string; value: string }) => {
  return (
    <LabelValueRow>
      <Label>{props.label}</Label>
      <Value>{props.value}</Value>
    </LabelValueRow>
  );
};

const Value = styled.div`
  text-align: right;
  width: 100px;
`;

const ReceiptPanelWrapper = styled.div`
  margin: 20px;
  max-width: 500px;
`;

const LabelValueRow = styled.div`
  display: flex;
  margin: 10px 0;
`;
const Label = styled.div`
  flex: 1;
  min-width: 150px;
  font-weight: bold;
`;

const TH = styled.th`
  padding: 10px;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: center;
`;

const TD = styled.td<{ width?: number }>`
  padding: 10px;
  width: ${(p) => p.width || 100}px;
  border-bottom: 1px solid black;
  text-align: center;
`;

const Table = styled.table`
  font-size: 12px;
`;

export default Orders;
