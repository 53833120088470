import React from "react";
import { JoyrideStepHelper } from "components/Joyride/JoyrideTour";
import { Step } from "react-joyride";

export function getJoyrideSteps(deviceSize: {
  small: boolean;
  medium: boolean;
  large: boolean;
}): (Step & { hidden?: boolean })[] {
  return [
    {
      target: "#divSearchPlayerTeam",
      disableBeacon: true,
      content: (
        <JoyrideStepHelper
          content={
            <div>
              Use this text box to find any available player. You may search by
              player or team name.
            </div>
          }
          note={
            <span>
              This list only contains players and teams who have not yet been
              eliminated from playoff contention.
            </span>
          }
        />
      ),
    },
    {
      target: ".thTicket",
      content: (
        <JoyrideStepHelper
          content={
            <div>
              These tickets represent your entry. When they are grey, it means
              that you have more picks to make. When they are green, they are
              complete.
            </div>
          }
        />
      ),
    },
    {
      target: ".thTicketNote",
      content: (
        <JoyrideStepHelper
          content={
            <div>
              This shows how many picks you have made out of the total needed.
              Once it says "Done", you have selected the maximum of players for
              this entry.
            </div>
          }
        />
      ),
    },
    {
      target: ".thTeam",
      content: (
        <JoyrideStepHelper
          content={
            <div>
              You can click on the headings to sort the player listing by
              team...
            </div>
          }
        />
      ),
      hidden: !deviceSize.large,
    },
    {
      target: ".thPlayer",
      content: <JoyrideStepHelper content={<div>by player...</div>} />,
      hidden: !deviceSize.large,
    },
    {
      target: ".thPoints",
      content: (
        <JoyrideStepHelper content={<div>or by regular season points.</div>} />
      ),
      hidden: !deviceSize.large,
    },
    {
      target: ".thPlayer",
      content: (
        <JoyrideStepHelper
          content={
            <div>
              You can click on the headings to sort the player listing by player
              {deviceSize.medium ? ` and regular season points.` : ""})
            </div>
          }
        />
      ),
      hidden: deviceSize.large,
    },
    {
      target: ".thPlayer",
      content: (
        <JoyrideStepHelper
          content={""}
          note={
            "The visible columns may change, depending on your screen size. The wider your screen, the more columns you will see."
          }
        />
      ),
    },
    {
      target: ".thTicket",

      content: (
        <JoyrideStepHelper
          content={
            "You can click on the ticket heading to see a list of all selected players for that entry."
          }
        />
      ),
    },
    {
      target: ".tdPlayer",
      content: (
        <JoyrideStepHelper
          content={
            "Clicking on the player will allow you to see that players statistics, including past playoff performance."
          }
        />
      ),
    },
    {
      target: ".tdCheckBox",
      content: (
        <JoyrideStepHelper
          content={
            "Click on the checkbox to add the player to your entry. Click again to remove it."
          }
          // note={
          //   // <div>
          //     "Once the pick limit is reached for an entry, you will not be able to add any additional players."
          //   // </div>
          // }
        />
      ),
    },
  ];
}
