import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { useState } from "react";
import JSONPretty from "react-json-pretty";
import { Log, LogType } from "../logs.duck";
import { DetailRow, Table, TR } from "pages/Admin/sharedStyledComponents";

type Props = {
  logs: Log[];
  isLoading: boolean;
  logTypeList: LogType[];
};

const LogsTable = (props: Props) => {
  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Reference</th>
            <th>LogType</th>
            <th>TimeStamp</th>
          </tr>
        </thead>
        <tbody>
          {props.isLoading && (
            <tr>
              <td colSpan={6}>
                <CircularProgress />
              </td>
            </tr>
          )}
          {!props.isLoading &&
            props.logs.map((c) => {
              const expanded = expandedIds.indexOf(c.id) >= 0;
              return (
                <>
                  <TR
                    key={c.id}
                    onClick={() => {
                      expanded
                        ? setExpandedIds(expandedIds.filter((d) => d !== c.id))
                        : setExpandedIds([...expandedIds, c.id]);
                    }}
                  >
                    <td>{expanded ? <ExpandLess /> : <ExpandMore />}</td>
                    <td>{c.id}</td>
                    <td>{c.reference}</td>
                    <td>
                      {props.logTypeList.find((d) => d.id === c.logType)?.name}
                    </td>
                    <td>
                      {moment.utc(c.timeStamp)
                        .tz("America/Edmonton")
                        .format("MMM D, YYYY HH:mm")}
                    </td>
                  </TR>
                  {expanded && (
                    <tr style={{ borderBottom: "solid 1px gray" }}>
                      <td colSpan={7} style={{ paddingLeft: 40 }}>
                        <DetailRow>
                          <div>Log Data:</div>
                          <div>
                            <JSONPretty data={c.logDataJson} />
                          </div>
                        </DetailRow>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default LogsTable;
