import React, { useState, useEffect } from "react";
import { Player } from "../PlayerGridTypes";

import styled from "styled-components";
import { Icons } from "@nait-aits/ui";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

// import "./flyout.css";

export type Team = {
  teamId: number;
  teamName: string;
};

export type Props = {
  players: Player[];
  searchString: string;
  onApplySearchFilter: (searchText: string) => void;
};

const FlyoutPanel = (props: Props) => {
  const { players, searchString, onApplySearchFilter } = props;

  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    setTeams(buildTeamList(players));
  }, [players]);

  var resultsToDisplay = filterPlayers(searchString, players);

  return (
    <div>
      <div style={{ minHeight: 305, backgroundColor: "#f5f5f5" }}>
        {searchString && (
          <Section>
            <ResultRow
              onClick={() => {
                onApplySearchFilter(searchString);
              }}
            >
              <IconCol>
                <Icons.SearchIcon />
              </IconCol>
              <ContentCol>
                <div>{searchString}</div>
              </ContentCol>
            </ResultRow>
          </Section>
        )}
        {searchString && resultsToDisplay.length > 0 && (
          <Section>
            {resultsToDisplay.splice(0, 5).map((e, i) => (
              <ResultRow
                key={`resultRow_${i}`}
                onClick={() => {
                  onApplySearchFilter(`playerId:${e.playerId}`);
                }}
              >
                <IconCol>
                  <StyledImg
                    size={30}
                    src={`https://assets.nhle.com/mugs/nhl/latest/${e.playerId}.png`}
                    alt={`${e.firstName} ${e.lastName}`}
                  />
                </IconCol>
                <ContentCol>
                  <div>
                    {e.firstName} {e.lastName}
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "smaller",
                    }}
                  >
                    {e.teamName}, {e.position}
                  </div>
                </ContentCol>
              </ResultRow>
            ))}
          </Section>
        )}
      </div>
      <Section>
        <ResultRow>
          <TextColumn>Position:</TextColumn>
          <ContentCol>
            <ToggleButtonGroup
              size="small"
              value={getPositionFilter(searchString)}
              onChange={(_, value) => {
                onApplySearchFilter(`position:${value}`);
              }}
            >
              <ToggleButton value="c">C</ToggleButton>
              <ToggleButton value="lw">LW</ToggleButton>
              <ToggleButton value="rw">RW</ToggleButton>
              <ToggleButton value="d">D</ToggleButton>
              <ToggleButton value="g">G</ToggleButton>
            </ToggleButtonGroup>
          </ContentCol>
        </ResultRow>
      </Section>
      {teams.length > 0 && (
        <TeamSection>
          {teams.map((team) => (
            <TeamLogoHolder
              key={team.teamId}
              onClick={() => {
                onApplySearchFilter(`teamId:${team.teamId}`);
              }}
            >
              <StyledImg
                alt={team.teamName}
                src={`https://www-league.nhlstatic.com/images/logos/teams-current-primary-light/${team.teamId}.svg`}
              />
            </TeamLogoHolder>
          ))}
        </TeamSection>
      )}
    </div>
  );
};

const TextColumn = styled.div`
  margin: auto 20px auto 0;
  font-weight: bold;
`;

const Section = styled.div`
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
`;
const ResultRow = styled.div`
  display: flex;
  height: 50px;
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    background-color: #eee;
  }
`;

const IconCol = styled.div`
  width: 50px;
  text-align: center;
  margin: auto 0;
`;
const ContentCol = styled.div`
  margin: auto 0;
`;

const TeamSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TeamLogoHolder = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const StyledImg = styled.img<{ size?: number }>`
  height: ${(e) => e.size || 40}px;
  width: ${(e) => e.size || 40}px;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #999999;
  &:hover {
    background-color: #eee;
  }
`;

function getPositionFilter(searchString: string) {
  var searchFilters = getSearchItemFilters(searchString);

  var pos = searchFilters.find((e) => e.toLowerCase().startsWith("position:"));

  if (pos) {
    // eslint-disable-next-line
    var [_, val] = pos.split(":");

    return val;
  }
  return "";
}

export function filterPlayers(searchString: string, players: Player[]) {
  var searchFilters = getSearchItemFilters(searchString);

  var results = [...players];

  searchFilters.forEach((element) => {
    results = match(results, element);
  });

  return results;
}

function getSearchItemFilters(searchString: string) {
  var splitSpace = searchString.split(" ");

  return splitSpace;
}

function match(players: Player[], search: string) {
  if (!search) return players;

  search = search.toLowerCase();

  if (search.indexOf(":") > 0) {
    var [key, value] = search.split(":");

    switch (key) {
      case "teamid":
        return players.filter((e) => e.teamId.toString() === value);
      case "playerid":
        return players.filter((e) => e.playerId.toString() === value);
      case "position":
        return players.filter((e) => e.position.toLowerCase() === value);

      default:
        return players;
    }
  } else {
    return players.filter((player) => {
      var searchable = `${player.firstName} ${player.lastName} ${player.teamName}`.toLowerCase();

      return searchable.indexOf(search) >= 0;
    });
  }
}

function buildTeamList(players: Player[]) {
  return players
    .map((e) => {
      return { teamId: e.teamId, teamName: e.teamName };
    })
    .filter((v, i, a) => a.find((e) => e.teamId === v.teamId) === v)
    .sort((a, b) => (a.teamName > b.teamName ? 1 : -1));
}

export default FlyoutPanel;
