import { FetchError } from "@nait-aits/redux";
import styled from "styled-components";
import MainTemplate from "components/MainTemplate/MainTemplate";
import React, { useEffect, useState } from "react";
import getAuthBearerToken from "store/getAuthBearerToken";
import { useActions, useReduxState } from "store";
import { SalesSummary } from "./reports.duck";
import { CircularProgress } from "@mui/material";

export type Props = {};

// type DownloadState = {
//   isDownloadingMoneris: boolean;
//   isDownloadingReconciliation: boolean;
//   isDownloadingIncompleteEntries: boolean;
//   isDownloadingEmptyEntries: boolean;
// };

type Report = {
  id: string;
  title: string;
  fileName: string;
  path: string;
};

const Reports = (props: Props) => {
  const reportsState = useReduxState((e) => e.adminReports);
  const reportsActions = useActions().adminReports;
  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const currentSeason = useReduxState((e) => e.mainTemplate.getSettings).data?.settings.season ?? 0;

  const [loadings, setLoadings] = useState<string[]>([]);
  const [selectedSeason, setSelectedSeason] = useState(currentSeason);

  const reports: Report[] = [
    {
      id: "moneris",
      title: "Moneris Report",
      fileName: `WRD-MonerisReport-${selectedSeason}.xlsx`,
      path: `${process.env.REACT_APP_API_BASE}/Admin/DownloadMonerisReport?season=${selectedSeason}`,
    },
    {
      id: "reconciliation",
      title: "Reconciliation Report",
      fileName: `WRD-ReconciliationReport-${selectedSeason}.xlsx`,
      path: `${process.env.REACT_APP_API_BASE}/Admin/DownloadReconciliationReport?season=${selectedSeason}`,
    },
    {
      id: "allEntries",
      title: "Entries Report - All",
      fileName: `WRD-AllEntries-${selectedSeason}.xlsx`,
      path: `${process.env.REACT_APP_API_BASE}/Admin/DownloadEntriesReport?entryType=all&season=${selectedSeason}`,
    },
    {
      id: "incompleteEntries",
      title: "Entries Report - Incomplete",
      fileName: `WRD-IncompleteEntries-${selectedSeason}.xlsx`,
      path: `${process.env.REACT_APP_API_BASE}/Admin/DownloadEntriesReport?entryType=incomplete&season=${selectedSeason}`,
    },
    {
      id: "emptyEntries",
      title: "Entries Report - Empty",
      fileName: `WRD-EmptyEntries-${selectedSeason}.xlsx`,
      path: `${process.env.REACT_APP_API_BASE}/Admin/DownloadEntriesReport?entryType=empty&season=${selectedSeason}`,
    },
  ];

  useEffect(() => {
    reportsActions.getSalesSummary({});
    reportsActions.getAvailableSeasons();
  }, []);
  useEffect(() => {
    setSelectedSeason(currentSeason);
  }, [currentSeason]);

  const userIsAdmin = userProfileState.data?.isAdmin ?? false;
  if (!userIsAdmin) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Access Denied.</div>} />
      </>
    );
  }

  if (reportsState.availableSeasons.length === 0) {
    return (
      <>
        <MainTemplate responsiveBody={false} body={<div>Invalid Season Data.</div>} />
      </>
    );
  }

  return (
    <div>
      <MainTemplate
        body={
          <div>
            <div style={{ display: "flex", marginBottom:20 }}>
              <div>Seasons: </div>
              <select
                style={{ marginLeft: 10, padding: 5, borderRadius: 5, border: "1px solid #ccc" }}
                value={selectedSeason}
                onChange={(e) => {
                  setSelectedSeason(e.target.value as any);
                  reportsActions.getSalesSummary({ season: e.target.value as any });
                }}
              >
                {reportsState.availableSeasons.map((s) => {
                  return (
                    <option key={s} value={s}>
                      {s} {s === currentSeason && "(current)"}
                    </option>
                  );
                })}
              </select>
            </div>

            <Summary summary={reportsState.salesSummary.data} />
            <h1>Reports</h1>
            {reports.map((c) => {
              return (
                <LinkDiv
                  key={c.id}
                  onClick={() => {
                    if (loadings.indexOf(c.id) >= 0) return;
                    setLoadings([...loadings, c.id]);
                    openFile(c.path, c.fileName, () => {
                      setLoadings(loadings.filter((d) => d !== c.id));
                    });
                  }}
                >
                  {c.title}{" "}
                  {loadings.indexOf(c.id) >= 0 && (
                    <span style={{ marginLeft: 5 }}>(generating...)</span>
                  )}
                </LinkDiv>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

const Summary = (props: { summary?: SalesSummary }) => {
  if (!props.summary)
    return (
      <>
        <CircularProgress />
      </>
    );
  return (
    <div style={{ marginBottom: 40 }}>
      <h1>Highlights</h1>
      <div>Sales: ${props.summary.totalSales}</div>
      <div>Bundles sold: {props.summary.bundlesCount}</div>
      <div>Singles sold: {props.summary.singlesCount}</div>
      <div>Contestants: {props.summary.userCount}</div>
    </div>
  );
};

export function openFile(
  url: string,
  fileName: string,
  onComplete: () => void
) {
  getAuthBearerToken().then((result) => {
    if (result instanceof FetchError) return;
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + result.token,
      }),
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        onComplete();
      });
    });
  });
}

const LinkDiv = styled.div`
  margin: 20px 0;
  width: fit-content;
  color: #0065c8;
  cursor: pointer;
`;

export default Reports;
