import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

export type ApiCurrentTeam = {
  id: number;
  name: string;
  link?: string;
};

export type ApiPosition = {
  code: string;
  name: string;
  type: string;
  abbreviation: "C" | "RW" | "LW" | "D" | "G";
};

export type ApiPerson = {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  primaryNumber: string;
  primaryPosition: ApiPosition;
  currentTeam: ApiCurrentTeam;
  link?: string;
};

export type ApiSplitStat = {
  assists: number;
  goals: number;
  games: number;
  shots: number;
  plusMinus: number;
  penaltyMinutes: string;
  points: number;
  powerPlayGoals: number;
  powerPlayPoints: number;
};

const controlName = "playerStatsCard";

export type Player = {
  info: ApiPerson;
  years: {
    year: number;
    teamId: number;
    teamName: string;
    regularSeasonStats?: ApiSplitStat;
    playoffStats?: ApiSplitStat;
  }[];
};

var getPlayerStats = createAsyncAction<
  Player, //return type
  { playerId: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getPlayerStats",
  url: `${process.env.REACT_APP_API_BASE}/Stats/GetPlayerStats`,
  transformUrl: (url, params) => {
    return `${url}/${params.playerId}`;
  },
  pending: (state, action) => {
    state.getPlayerStats = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getPlayerStats.isLoading = false;
    state.getPlayerStats.data = action.payload;
  },
  rejected: (state, action) => {
    state.getPlayerStats.isLoading = false;
    state.getPlayerStats.error = action.payload;
  },
});

type State = {
  getPlayerStats: ReducerState<Player>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getPlayerStats: {
      isLoading: false,
    },
  } as State,
  reducers: {
    reset: (state) => {
      state.getPlayerStats = {
        isLoading: false,
      };
    },
  },
  extraReducers: {
    ...getPlayerStats.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getPlayerStats: getPlayerStats.action,
    },
  },
};

export default duck;
