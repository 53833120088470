import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export type Props = {
  state: "checked" | "unchecked" | "loading";
  disabled?: boolean;
  onClick(): void;
  style?: React.CSSProperties;
};

const CheckboxButton = (props: Props) => {
  const { state, style, onClick, disabled } = props;

  const combinedDisabled = state === "loading" || disabled;

  return (
    <Wrapper
      style={disabled ? { ...style, color: "#d5d5d5" } : { ...style,color: "#073b8d" }}
      disabled={combinedDisabled}
      onClick={
        combinedDisabled
          ? undefined
          : () => {
              onClick();
            }
      }
    >
      {state === "loading" && (
        <>
          <CircularProgress size={20} />
        </>
      )}
      {state === "checked" && (
        <>
          <CheckBox />
        </>
      )}
      {state === "unchecked" && (
        <>
          <CheckBoxOutlineBlank />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;
  `}
`;

export default CheckboxButton;
