import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
// import getAuthBearerToken from "store/getAuthBearerToken";

const controlName = "faq";

export type IncludedTeam = {
  id: number;
  name: string;
  division: string;
};

type getIncludedTeamsReturn = {
  teams: IncludedTeam[];
};

var getIncludedTeams = createAsyncAction<
  getIncludedTeamsReturn, //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getIncludedTeams",
  url: `${process.env.REACT_APP_API_BASE}/Account/GetIncludedTeams`,
  pending: (state, action) => {
    state.getIncludedTeams = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getIncludedTeams.isLoading = false;
    state.getIncludedTeams.data = action.payload;
  },
  rejected: (state, action) => {
    state.getIncludedTeams.isLoading = false;
    state.getIncludedTeams.error = action.payload;
  },
});

type State = {
  getIncludedTeams: ReducerState<getIncludedTeamsReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getIncludedTeams: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getIncludedTeams.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getIncludedTeams: getIncludedTeams.action,
    },
  },
};

export default duck;
