import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { OrderUser } from "../orders.duck";

export type Props = {
  open: boolean;
  orderId: number;
  user: OrderUser;
  entryIds: number[];
  isSearchingUser: boolean;
  userResults: OrderUser[];
  onClose(): void;
  onConfirm(orderId: number, toUserId: number): void;
  onSearchUser(searchText: string): void;
};

const MoveEntriesDialog = ({
  open,
  orderId,
  user,
  entryIds,
  isSearchingUser,
  userResults,
  onClose,
  onConfirm,
  onSearchUser,
}: Props) => {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  var searchDelay: NodeJS.Timeout;
  useEffect(() => {
    searchDelay = setTimeout(() => {
      onSearchUser(searchText);
    }, 500);
    return () => {
      clearTimeout(searchDelay);
    };
  }, [searchText]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Move Entries</DialogTitle>
      <DialogContent>
        <ContentWrapper>
          <div style={{margin:"10px 0"}}>
            Use this dialog to move entries between user accounts, to resolve
            duplicate account issues.
          </div>
          <div>
            <b>Move entries</b>: {entryIds.join(", ")}.
          </div>
          <div>
            <b>From user</b>: {user.displayName} ({user.email}), id: {user.id},
            auth: {user.authType}
          </div>

          <div>
            <TextField
              style={{ width: 400, margin: "10px 0" }}
              label="To user..."
              variant="filled"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div>
            {isSearchingUser && <CircularProgress />}
            {!isSearchingUser && (
              <div>
                <FormControl>
                  <RadioGroup
                    value={selectedUserId}
                    onChange={(e) => {
                      setSelectedUserId(parseInt(e.target.value));
                    }}
                  >
                    {userResults.map((u) => (
                      <FormControlLabel
                        value={u.id}
                        control={<Radio />}
                        disabled={user.id === u.id}
                        label={`${u.displayName} (${u.email}), id: ${u.id}, auth: ${u.authType}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <div>
          <button
            className="nait-button nait-button--primary"
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                onConfirm(orderId, selectedUserId);
                onClose();
              }
            }}
          >
            Confirm
          </button>
          <button
            className="nait-button nait-button--secondary"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MoveEntriesDialog;

const ContentWrapper = styled.div`
  b {
    font-weight: bold !important;
  }
`;
