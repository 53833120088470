import { useDeviceSize } from "hooks/useWindowSize";
import { ApiSplitStat } from "pages/PlayerListing/apiTypes";
import React from "react";
import styled from "styled-components";
import { Player } from "./playerStatsCard.duck";

export type Props = {
  player: Player;
};

const PlayerStatsCardItem = (props: Props) => {
  const { player } = props;
  const deviceSize = useDeviceSize(1000, 600);

  return (
    <div>
      <HeadingRow>
        <div style={{ margin: "auto 10px", fontSize: 20 }}>
          {player.info.fullName}
        </div>
        <div style={{ margin: "auto 10px", fontWeight: "bold" }}>
          {player.info.currentTeam.name}
        </div>
        <div style={{ margin: "auto 10px" }}>
          {player.info.primaryPosition.name}
        </div>
      </HeadingRow>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: deviceSize.small ? 350 : "auto",
          marginTop:55
        }}
      >
        <StatsGroup>
          <StatsGroupHeading>Regular Season</StatsGroupHeading>
          <GetStatsTable years={player.years} playoffs={false} />
        </StatsGroup>
        <StatsGroup>
          <StatsGroupHeading>Playoffs</StatsGroupHeading>
          <GetStatsTable years={player.years} playoffs={true} />
        </StatsGroup>
      </div>
    </div>
  );
};

const StatsGroup = styled.div`
  margin: 20px;
`;
const StatsGroupHeading = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

type GetStatsTableProps = {
  playoffs?: boolean;
  years: {
    year: number;
    teamId: number;
    teamName: string;
    regularSeasonStats?: ApiSplitStat;
    playoffStats?: ApiSplitStat;
  }[];
};

const GetStatsTable = (props: GetStatsTableProps) => {
  const { playoffs, years } = props;

  var yearItems = years.filter((e) =>
    playoffs ? e.playoffStats : e.regularSeasonStats
  );

  if (yearItems.length === 0)
    return <div style={{ fontSize: 12 }}>Not record available.</div>;

  return (
    <Table>
      <thead>
        <tr>
          <TH>Year</TH>
          <TH>Team</TH>
          <TH>GP</TH>
          <TH>G</TH>
          <TH>A</TH>
          <TH>Pts</TH>
        </tr>
      </thead>
      <tbody>
        {yearItems
          .sort((a, b) => (a.year < b.year ? 1 : -1))
          .map((year) => {
            var stat = playoffs ? year.playoffStats : year.regularSeasonStats;

            return (
              <tr key={`rs_${year.year}`}>
                <TD>
                  {year.year}-{year.year + 1}
                </TD>
                <TD>{year.teamName}</TD>
                <TD>{stat?.games || 0}</TD>
                <TD>{stat?.goals || 0}</TD>
                <TD>{stat?.assists || 0}</TD>
                <TD>{stat?.points || 0}</TD>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const TH = styled.th`
  padding: 5px;
  border: 1px solid black;
  font-weight: bold;
`;

const TD = styled.td`
  padding: 5px;
  border: 1px solid black;
`;

const Table = styled.table`
  font-size: 12px;
  border: 1px solid black;
`;

const HeadingRow = styled.div`
  display: flex;
  background-color: #083f93;
  color: white;
  padding: 10px;
  width:100%;
  position:absolute;
  top:0;
`;

export default PlayerStatsCardItem;
