import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "checkout";

export type LoadCartReturn = {
  subTotal: number;
  gst: number;
  total: number;

  bundlePrice: number;
  numberOfBundles: number;
  bundlesSubtotal: number;

  singleEntryPrice: number;
  numberOfSingles: number;
  singlesSubtotal: number;
};

var loadPreCheckout = createAsyncAction<
  LoadCartReturn, //return type
  { numberOfBundles: number; numberOfSingles: number }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadCart",
  url: `${process.env.REACT_APP_API_BASE}/Checkout/GetPreOrderDetails`,
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.loadPreCheckout = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadPreCheckout.isLoading = false;
    state.loadPreCheckout.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadPreCheckout.isLoading = false;
    state.loadPreCheckout.error = action.payload;
  },
});

type State = {
  loadPreCheckout: ReducerState<LoadCartReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    loadPreCheckout: {
      isLoading: false,
    },
  } as State,
  reducers: {
    reset: (state) => {
      state.loadPreCheckout = {
        isLoading: false,
      };
    },
  },
  extraReducers: {
    ...loadPreCheckout.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadPreCheckout: loadPreCheckout.action,
    },
  },
};

export default duck;
