import moment from "moment";
import { useReduxState } from "store";


export function useDraftStatus(): {
  isDraftOpen: boolean;
  draftHasNotStarted: boolean;
  draftHasEnded: boolean;
  isPlayerPickOpen: boolean;
} {
  const mainTemplateState = useReduxState((e) => e.mainTemplate.getSettings);
  // const entryStartDate = moment(
  //   mainTemplateState.data?.settings.entryOpenDateGMT
  // );
  // const entryCloseDate = moment(
  //   mainTemplateState.data?.settings.entryCloseDateGMT
  // );
  // const playerPickCloseDate = moment(
  //   mainTemplateState.data?.settings.playerPickCloseDateGMT
  // );
  const draftHasNotStarted = mainTemplateState.data?.settings.draftHasNotStarted ?? false;
  const draftHasEnded = mainTemplateState.data?.settings.draftHasEnded ?? false;
  const isPlayerPickOpen = mainTemplateState.data?.settings.isPlayerPickOpen ?? false;
  const isDraftOpen = mainTemplateState.data?.settings.isDraftOpen ?? false;

  var ret = {
    isDraftOpen,
    draftHasNotStarted,
    draftHasEnded,
    isPlayerPickOpen
  };

  return ret;
}

export default useDraftStatus;
