import React, { useEffect } from "react";

export type Props = {
  children: React.ReactNode;
  title?: string;
  excludeBaseTitle?: boolean;
};

const PageWrapper = ({ children, title, excludeBaseTitle }: Props) => {
  useEffect(() => {
    var baseTitle = "Worlds Richest Draft";

    if (title && !excludeBaseTitle) {
      baseTitle = `${baseTitle} - ${title}`;
    }
    if (title && excludeBaseTitle) {
      baseTitle = `${title}`;
    }
    document.title = baseTitle;
  }, [title, excludeBaseTitle]);

  return <>{children}</>;
};

export default PageWrapper;
