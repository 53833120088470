import { useEffect } from "react";
import { useReduxState, useActions } from "store";
// import styled from "styled-components";
import { currencyFormatter } from "components/helpers/CurrencyFormatter";
import MainTemplate from "components/MainTemplate/MainTemplate";

export type Props = {};

const Goal = (props: Props) => {
  const state = useReduxState((e) => e.goal.goal);
  const actions = useActions().goal;

  useEffect(() => {
    actions.loadGoals();
  }, []);

  var goalTotal = 50000;
  var percentage = (state.data?.totalRaised || 0) / goalTotal;

  return (
    <MainTemplate
      body={
        <div style={{ margin: 20 }}>
          {state.isLoading && <div>Loading...</div>}
          {!state.isLoading && state.data && (
            <div>
              <h2>
                Total Raised -{" "}
                {currencyFormatter
                  .format(state.data.totalRaised)
                  .replace(".00", "")}
              </h2>
              <h3>
                {(percentage * 100).toFixed(0)}% of{" "}
                {currencyFormatter.format(goalTotal).replace(".00", "")} goal
              </h3>
              <div>Bundles Sold: {state.data.bundles}</div>
              <div>Singles Sold: {state.data.singles}</div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default Goal;
