import { LinearProgress } from "@nait-aits/ui";
import { useDeviceSize } from "hooks/useWindowSize";
import React from "react";
import styled from "styled-components";
//import { NAIT } from '../Icons/MainIcons';

//@ts-ignore
// import { useScrollPosition } from "./useScrollPosition";

export type Props = {
  rightContent?: JSX.Element;
  heading?: string | JSX.Element;
  onHeadingClick?(): void;
  isLoading?: boolean;
  isDev?: boolean;
};

const AppBar = ({
  onHeadingClick,
  rightContent,
  isLoading = false,
  heading,
  isDev,
}: Props) => {
  const deviceSize = useDeviceSize(1000, 500);

  return (
    <Container>
      {isDev && (
        <div style={{ backgroundColor: "red", color:'white' }}>
          DEVELOPMENT Site - DO NOT USE!
        </div>
      )}
      <StyledDiv
        isSmallScreen={deviceSize.small}
        isScrolled={false}
        showLeftPadding={!deviceSize.small}
      >
        <InnerBar>
          {heading}
          <Right isSmallScreen={deviceSize.small}>{rightContent}</Right>
        </InnerBar>
      </StyledDiv>
      {isLoading && <LinearProgress />}
      {/* <Spacer /> */}
    </Container>
  );
};

export default AppBar;

type StyledDivProps = {
  isScrolled: boolean;
  showLeftPadding?: boolean;
  isSmallScreen?: boolean;
};

const StyledDiv = styled.div<StyledDivProps>`
  background: #002d74
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 200'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23002d74;%7D.cls-2%7Bfill:%23093f93;%7D.cls-3%7Bfill:%23073b8d;%7D.cls-4%7Bfill:%23043582;%7D.cls-5%7Bfill:%2302317c;%7D.cls-6%7Bfill:%230c459e;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_4' data-name='Layer 4'%3E%3Crect class='cls-1' width='1600' height='200'/%3E%3C/g%3E%3Cg id='Layer_3' data-name='Layer 3'%3E%3Cpolygon class='cls-2' points='658.71 122.62 731.73 0 880.9 0 968.66 110.37 843.05 200 804.75 200 658.71 122.62'/%3E%3Cpolygon class='cls-3' points='658.71 122.62 434.03 0 731.73 0 658.71 122.62'/%3E%3Cpolygon class='cls-4' points='91.39 200 301.77 200 165.43 0 30.11 0 91.39 200'/%3E%3Cpolygon class='cls-1' points='0 0 30.11 0 91.39 200 0 200 0 0'/%3E%3Cpolygon class='cls-2' points='968.66 110.37 1118.79 0 1199.47 0 1251.05 200 1042.2 200 968.66 110.37'/%3E%3Cpolygon class='cls-3' points='880.9 0 1118.79 0 968.66 110.37 880.9 0'/%3E%3Cpolygon class='cls-3' points='1354.71 200 1251.05 200 1199.47 0 1482.37 0 1354.71 200'/%3E%3Cpolygon class='cls-5' points='1518.11 200 1354.71 200 1482.37 0 1546.7 0 1518.11 200'/%3E%3Cpolygon class='cls-1' points='1600 0 1600 200 1518.11 200 1546.7 0 1600 0'/%3E%3Cpolygon class='cls-6' points='612.75 200 658.71 122.62 804.75 200 612.75 200'/%3E%3Cpolygon class='cls-6' points='843.05 200 968.66 110.37 1042.2 200 843.05 200'/%3E%3Cpolygon class='cls-2' points='412.32 0 434.03 0 658.71 122.62 612.75 200 386.67 200 412.32 0'/%3E%3Cpolygon class='cls-3' points='301.77 200 386.67 200 412.32 0 165.43 0 301.77 200'/%3E%3C/g%3E%3C/svg%3E")
    center center no-repeat;
  background-size: cover;
  color: #fff;
  position: relative;
  padding: ${(p) => (p.showLeftPadding ? "15px 1.5em" : "15px .5em 15px .5em")};
  transform: translateZ(0);
  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.15s cubic-bezier(0.4, 0, 1, 1);
  z-index: 310;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0px 1px 2px 0px rgba(60,64,67,.6),0px 1px 3px 1px rgba(60,64,67,.15)"
      : "none"};
  height: 90px;
`;

const InnerBar = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  & > a {
    color: #fff;
    display: flex;
    text-decoration: none;
    align-items: center;
  }
`;

const Right = styled.div<{ isSmallScreen?: boolean }>`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  margin-right: ${(p) => (p.isSmallScreen ? "0" : "1em")};
`;
const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: 1100;
`;
