import { Provider } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import store from "store";
import PlayerListing from "pages/PlayerListing/PlayerListing";
import Summary from "pages/UserEntries/Summary";
import Editor from "pages/UserEntries/Editor";
// import LandingPage from "pages/Home/LandingPage";
import { lazy, Suspense } from "react";
import PageWrapper from "components/PageWrapper/PageWrapper";
import FAQ from "pages/FAQ/FAQ";
import About from "pages/About/About";

import MyLanding from "pages/My/Main";
import AdminLogin from "pages/Admin/Login/AdminLogin";
import Goal from "pages/Admin/Beta/Goal/Goal";
import Orders from "pages/Admin/Orders/Orders";
import Winners from "pages/Admin/Winners/Winners";
import Accounts from "pages/Admin/Accounts/Accounts";
import Logs from "pages/Admin/Logs/Logs";
import WinnersWithSubtractions from "pages/Admin/Winners/WinnersWithSubtractions";

const AdminSetup = lazy(() => import("pages/Admin/Setup/Setup"));
const AdminReports = lazy(() => import("pages/Admin/Reports/Reports"));
const AdminContestants = lazy(
  () => import("pages/Admin/ContestantListing/ContestantListing")
);

const LandingPage = lazy(() => import("pages/Home/LandingPage"));
const LoginComplete = lazy(() => import("pages/LoginComplete/LoginComplete"));

const EditProfile = lazy(() => import("pages/EditProfile/EditProfile"));
// const UserSettings = lazy(() => import("pages/UserSettings/UserSettings"));

const Standings = lazy(() => import("pages/Standings/Standings"));

const Order = lazy(() => import("pages/Order/Order"));
const Checkout = lazy(() => import("pages/Checkout/Checkout"));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/UserEntries" element={<Summary />} />
          {/* <Route path="/UserEntries/Summary" element={<Summary />} /> */}
          <Route path="/UserEntries/:userId" element={<Summary />} />
          <Route path="/UserEntries/Editor" element={<Editor />} />
          <Route path="/UserEntries/Editor/:userId" element={<Editor />} />
          <Route path="/PlayerListing" element={<PlayerListing />} />
          <Route path="/About" element={<About />} />
          <Route
            path="/LoggedIn"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <LoginComplete />
              </Suspense>
            }
          />
          <Route
            path="/EditProfile"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <EditProfile initial={false} />
              </Suspense>
            }
          />
          <Route
            path="/SetupProfile"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <EditProfile initial={true} />
              </Suspense>
            }
          />
          {/* <Route
            path="/My"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <UserSettings />
              </Suspense>
            }
          /> */}
          <Route
            path="/My"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <MyLanding />
              </Suspense>
            }
          />
          <Route
            path="/My/:mode"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <MyLanding />
              </Suspense>
            }
          />
          <Route
            path="/Order"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Order />
              </Suspense>
            }
          />
          <Route
            path="/Checkout"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Checkout />
              </Suspense>
            }
          />
          <Route
            path="/Admin/Reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <AdminReports />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/Admin/Contestants"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <AdminContestants />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/Admin/Setup"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <AdminSetup />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/Admin/Login"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <AdminLogin />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/Admin/Beta"
            element={
              <div>
                <h2>Beta Features</h2>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Beta/Total">Total Raised</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Beta/TopPicks">TopPicks</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Beta/TeamPicks">Team Picks</a>
                </div>{" "}
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Beta/Standings">Standings</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Beta/StandingsEd">Standings (Ed)</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Accounts">Account Listing</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Orders">Orders</a>
                </div>
                <div style={{ margin: 20 }}>
                  <a href="/Admin/Logs">Logs</a>
                </div>
              </div>
            }
          />
          <Route
            path="/Admin/Beta/Total"
            element={
              <div>
                <Goal />
              </div>
            }
          />
          <Route
            path="/Admin/Orders"
            element={
              <div>
                <Orders />
              </div>
            }
          />
          <Route
            path="/Admin/Accounts"
            element={
              <div>
                <Accounts />
              </div>
            }
          />
          <Route
            path="/Admin/Logs"
            element={
              <div>
                <Logs />
              </div>
            }
          />

          <Route
            path="/Admin/Winners"
            element={
              <div>
                <Winners />
              </div>
            }
          />

          <Route
            path="/Admin/WinnersSub"
            element={
              <div>
                <WinnersWithSubtractions />
              </div>
            }
          />

          <Route
            path="/Standings"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <Standings />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/FAQ"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <FAQ />
                </PageWrapper>
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <PageWrapper>
                  <LandingPage />
                </PageWrapper>
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
