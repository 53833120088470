import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { AccountCircle } from "@mui/icons-material";
import { IconButton, Paper } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { performLogin } from "store/getAuthBearerToken";

import { useReduxState } from "store";

export type Props = {
  // onManageEntriesClick?(): void;
  // onMyProfileClick?(): void;
  // onLogOutClick?(): void;
  // onLogInClick?(): void;
  // onPurchaseEntryClick?(): void;
  // onAdminSettingsClick?(): void;
  // onContestantListingClick?(): void;
  // onReportsClick?(): void;
  // isAuthenticated?: boolean;
  // isAdmin?: boolean;
  // isDraftOpen?: boolean;
  user?: {
    name: string;
    teamName: string;
    avatar?: JSX.Element;
  };
};

const ProfileButton = (props: Props) => {
  const {
    // onLogOutClick,
    // onLogInClick,
    // onManageEntriesClick,
    // onMyProfileClick,
    // onPurchaseEntryClick,
    // onAdminSettingsClick,
    // onContestantListingClick,
    // onReportsClick,
    // isAuthenticated,
    // isAdmin,
    // isDraftOpen,
    user,
  } = props;

  var isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const history = useNavigate();

  const userProfileState = useReduxState((e) => e.profile.getUserSimple);
  const state = useReduxState((e) => e.mainTemplate.getSettings);

  const [showMenu, setShowMenu] = useState(false);
  let ref = useRef(null as null | HTMLDivElement);

  var isDraftOpen = state.data?.settings.isDraftOpen;
  var isAdmin = userProfileState.data?.isAdmin ?? false;
  var isMedia = userProfileState.data?.isMedia ?? false;

  const handleClickOutside = (event: MouseEvent) => {
    // Do not hide menu during tour.
    if (
      document
        .getElementsByClassName("__floater __floater__open")[0]
        ?.parentElement?.id?.endsWith("step-1")
    )
      return;
    if (ref.current && !ref.current.contains(event.target as Node | null)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={ref}>
      {!isAuthenticated && (
        <button
          className="nait-button nait-button--primary dropdown-button"
          onClick={() => {
            if (!isAuthenticated) {
              performLogin(instance);
            }
          }}
        >
          <span className="i-login"></span> Log In
        </button>
      )}
      {isAuthenticated && (
        <IconButton
          id="iconAccount"
          aria-label="AccountSettings"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <AccountCircle />
        </IconButton>
      )}
      {!showMenu && <div></div>}
      {showMenu && isAuthenticated && (
        <Menu>
          {user && (
            <>
              <MenuItem selectable={false}>
                <div>
                  {/* <div style={{color: "#757575"}}>
                    <AccountCircle/>
                  </div> */}
                  <div>
                    <AccountName>{user.name}</AccountName>
                    <AccountLink
                      onClick={() => {
                        setShowMenu(false);
                        history("/My");
                      }}
                    >
                      Manage your Account
                    </AccountLink>
                  </div>
                </div>
              </MenuItem>
              <HR />
            </>
          )}
          <MenuItem
            onClick={() => {
              setShowMenu(false);
              history("/UserEntries");
            }}
          >
            My Entries
          </MenuItem>
          {isDraftOpen && !isMedia && (
            <MenuItem
              id="menuPurchaseEntry"
              onClick={() => {
                setShowMenu(false);
                history("/Order");
              }}
            >
              Purchase Entries
            </MenuItem>
          )}
          {!user && (
            <MenuItem
              onClick={() => {
                setShowMenu(false);
                history("/My");
              }}
            >
              My Profile
            </MenuItem>
          )}
          {isAdmin && (
            <>
              <MenuItem
                isAdminLink
                onClick={() => {
                  setShowMenu(false);
                  history("/Admin/Setup");
                }}
              >
                Site Setup
              </MenuItem>
              <MenuItem
                isAdminLink
                onClick={() => {
                  setShowMenu(false);
                  history("/Admin/Contestants");
                }}
              >
                Contestant Listing
              </MenuItem>
              <MenuItem
                isAdminLink
                onClick={() => {
                  setShowMenu(false);
                  history("/Admin/Winners");
                }}
              >
                Winners
              </MenuItem>
              <MenuItem
                isAdminLink
                onClick={() => {
                  setShowMenu(false);
                  history("/Admin/Reports");
                }}
              >
                Reports
              </MenuItem>
              
            </>
          )}
          <MenuItem
            onClick={() => {
              setShowMenu(false);
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            }}
          >
            Log Out
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

const Menu = styled(Paper)`
  position: absolute;
  z-index: 1;
  // background-color: silver;
  margin-left: -150px;
  width: 200px;
`;
const MenuItem = styled.div<{ selectable?: boolean; isAdminLink?: boolean }>`
  padding: 20px;
  white-space: nowrap;

  ${({ selectable = true }) =>
    selectable &&
    `
    cursor: pointer;
  &:hover {
    // text-decoration: underline;
    background-color: #eeeded;
    
  }
  `}

  ${({ isAdminLink }) =>
    isAdminLink &&
    `
    font-weight: bold;
    color: #b43286;    
  `}
`;

const AccountName = styled.div`
  font-weight: bold;
`;

const AccountLink = styled.div`
  font-size: 14px;
  color: #0a61d4;
  margin-top: 10px;
  cursor: pointer;
`;

const HR = styled.hr`
  margin-bottom: 10px;
`;

export default ProfileButton;
