import styled from "styled-components";
import { Player } from "components/PlayerGrid/PlayerGridTypes";
import { SimpleDialog } from "@nait-aits/ui";
import PlayerAvatar, { TeamAvatar } from "components/PlayerAvatar/PlayerAvatar";
import Ticket from "components/EntriesTicketSelector/components/Ticket";
import { useDeviceSize } from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import TF from "components/TextField/TF";
import { Done, Edit, Save } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import useDraftStatus from "hooks/useDraftStatus";

export type Props = {
  open: boolean;
  entryName: string;
  entryId: number;
  players: Player[];
  ticketNumber: number | "Void" | undefined;
  maxPlayersPerEntry: number;
  isUpdatingEntryName: boolean;
  handleBackgroundClick(): void;
  onEditEntryName(id: number, newName: string): void;
};

const EntryPlayerListDialog = ({
  open,
  players,
  ticketNumber,
  maxPlayersPerEntry,
  entryName,
  entryId,
  isUpdatingEntryName,
  handleBackgroundClick,
  onEditEntryName,
}: Props) => {
  const deviceSize = useDeviceSize(1000, 600);
  const draftStatus = useDraftStatus();

  const [editNameMode, setEditNameMode] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>(entryName);

  useEffect(() => {
    setEditedName(entryName);
  }, [entryName]);

  if (!open) return <></>;

  return (
    <SimpleDialog handleBackgroundClick={handleBackgroundClick}>
      <Header>
        <HeaderDiv>
          <Ticket
            ticketNumber={ticketNumber}
            size="md"
            complete={players.length === maxPlayersPerEntry}
          />
          <div style={{ fontSize: 14, marginTop: 5, textAlign: "center" }}>
            {players.length !== maxPlayersPerEntry && (
              <div>
                {players.length}/{maxPlayersPerEntry}
              </div>
            )}
            {players.length === maxPlayersPerEntry && (
              <div style={{ color: "#17a501" }}>Done</div>
            )}
          </div>
          <NameEditorWrapper>
            {!editNameMode && (
              <div style={{ display: "flex" }}>
                {entryName ? (
                  <div style={{ padding: 7 }}>
                    {isUpdatingEntryName ? "Saving..." : entryName}
                  </div>
                ) : (
                  <div
                    style={{
                      padding: 7,
                      fontSize: 16,
                      fontStyle: "italic",
                      color: "#8F959F",
                    }}
                  >
                    Name your entry (optional)
                  </div>
                )}
                <IconButton
                  title="Edit entry name"
                  style={{ marginLeft: 10 }}
                  disabled={!draftStatus.isPlayerPickOpen}
                  onClick={() => setEditNameMode(true)}
                >
                  <Edit />
                </IconButton>
              </div>
            )}
            {editNameMode && (
              <div>
                <input
                  style={{ width: 150, textAlign: "center" }}
                  type="text"
                  className="nait-input"
                  disabled={!editNameMode || isUpdatingEntryName}
                  placeholder="Name your entry"
                  onChange={(e) => setEditedName(e.target.value)}
                  value={editedName}
                />
                <IconButton
                  title="Save"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    if (editedName !== entryName) {
                      onEditEntryName(entryId, editedName);
                      setEditedName("Saving...");
                    }
                    setEditNameMode(false);
                  }}
                >
                  <Save />
                </IconButton>{" "}
              </div>
            )}
          </NameEditorWrapper>
        </HeaderDiv>
      </Header>
      <TableContainer>
        {players.length === 0 && <div>You have not selected any players.</div>}
        {players.length > 0 && (
          <PlayersTable>
            <thead>
              <tr>
                <TH colSpan={deviceSize.small ? 1 : 2}>Players</TH>
              </tr>
            </thead>
            <tbody>
              {players.map((p) => {
                return (
                  <tr key={p.playerId}>
                    {!deviceSize.small && (
                      <TeamTD>
                        <TeamAvatar teamId={p.teamId} />
                      </TeamTD>
                    )}
                    <td>
                      <div style={{ display: "flex" }}>
                        <div style={{ margin: "auto 10px auto 0" }}>
                          <PlayerAvatar
                            playerId={p.playerId}
                            teamId={deviceSize.small ? p.teamId : undefined}
                          />
                        </div>
                        <div style={{ margin: "auto 0" }}>
                          {p.firstName} {p.lastName}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </PlayersTable>
        )}
      </TableContainer>
    </SimpleDialog>
  );
};

const PlayersTable = styled.table`
  td {
    border-bottom: solid 1px #ddd;
    padding: 5px 20px;
  }
`;

const TableContainer = styled.div`
  overflow-y: auto;
  margin: 10px;
  max-height: 75vh;
`;

const Header = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
`;

const HeaderDiv = styled.div`
  > :first-child {
    margin: auto;
  }
`;

const TeamTD = styled.td`
  padding: 5px;
  border-right: solid 1px #ddd;
  vertical-align: middle;
`;

const TH = styled.th`
  font-weight: bold;
  padding: 5px;
  white-space: nowrap;
  text-align: center;
  background-color: #33b3e5;
  position: sticky;
  top: 0;
  color: #fff;
`;

const NameEditorWrapper = styled.div`
  padding: 10px;
  display: flex;
`;

export default EntryPlayerListDialog;
