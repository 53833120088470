import MainTemplate from "components/MainTemplate/MainTemplate";
import React, { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";

import PlayerGrid from "components/PlayerGrid/PlayerGrid";
import { SimpleDialog } from "@nait-aits/ui";
import PlayerStatsCard from "components/PlayerStatsCard/PlayerStatsCard";
import { playerMapper } from "components/helpers/PlayerFunctions";

export type Props = {
  year?: number;
};

const PlayerListing = (props: Props) => {
  const state = useReduxState((e) => e.playerListing);
  const actions = useActions().playerListing;

  const [selectedPlayer, setSelectedPlayer] = useState<number | undefined>();

  const { year = -1 } = props;

  var playerStatsData = state.playerStats.find((e) => e.id === year)?.data || {
    isLoading: true,
  };

  useEffect(() => {
    actions.getPlayerStats({ year: year });
  }, [year, actions]);

  return (
    <>
      <MainTemplate
        body={
          <>
            {selectedPlayer && (
              <>
                <SimpleDialog
                  handleBackgroundClick={() => {
                    setSelectedPlayer(undefined);
                  }}
                >
                  <PlayerStatsCard playerId={selectedPlayer} />
                </SimpleDialog>
              </>
            )}
            <PlayerGrid
              searchable={true}
              gridHeight={600}
              seasons={[year]}
              selectedSeason={year}
              showSeasonPicker={false}
              onViewPlayer={({ playerId }) => {
                setSelectedPlayer(playerId);
              }}
              players={
                playerStatsData.data?.map((p) => playerMapper(p, "regular")) ||
                []
              }
            />
          </>
        }
        isLoading={playerStatsData.isLoading}
      />
    </>
  );
};

export default PlayerListing;
