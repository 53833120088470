import { Player } from "components/PlayerGrid/PlayerGridTypes";
import { Entry } from "components/UserEntryEditorGrid/UserEntryEditorGrid";

// type Entry = {
//   entryId: number;
//   entryName: string;
//   teamName: string;
//   playerIds: number[];
// };

// type TT = Player & {entryIds: number[]};

export function GetPlayerList(allPlayers: Player[], userEntries?: Entry[]) {
  var ret = allPlayers;

  if (userEntries) {
    //get distinct list of player ids
    var playerIds = userEntries
      .map((e) => e.playerIds)
      .flat(1)
      .filter((v, i, a) => a.indexOf(v) === i);

    ret = ret.filter((e) => playerIds.includes(e.playerId));
  }

  var tmp = ret.map((e) => {
    return {
      ...e,
      entryIds:
        userEntries
          ?.filter((f) => f.playerIds.includes(e.playerId))
          .map((f) => f.entryId) || [],
    };
  });

  return tmp.filter((e) => e.isAvailable || e.entryIds.length > 0);
}
