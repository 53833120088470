import { FetchError, ReducerState } from "@nait-aits/redux";
import React from "react";
import ErrorBox from "./ErrorBox";

export type Props = {
  state: ReducerState<any>;
};

const ErrorBoxReducerState = (props: Props) => {
  const { state } = props;

  console.log(state.error);

  console.log(state.error instanceof FetchError);

  if (!state.error || state.isLoading) return <></>;

  return <ErrorBox error={state.error} />;
};

export default ErrorBoxReducerState;
