//The commented items show how to include ducks
import playerListing from "./PlayerListing/playerListing.duck";
import userEntries from "./UserEntries/userEntries.duck";
import loginComplete from "./LoginComplete/loginComplete.duck";
import editProfile from "./EditProfile/editProfile.duck";
import admin from "./Admin/reducer";
// import shoppingCart from "./ShoppingCart/shoppingCart.duck";
import standings from "./Standings/standings.duck";
import checkout from "./Checkout/checkout.duck";
import userSettings from "./UserSettings/userSettings.duck";
import userProfile from "./My/userProfile.duck";
import faq from "./FAQ/faq.duck";

const index = {
  reducer: {
    ...playerListing.reducer,
    ...userEntries.reducer,
    ...loginComplete.reducer,
    ...editProfile.reducer,
    // ...shoppingCart.reducer,
    ...admin.reducer,
    ...standings.reducer,
    ...checkout.reducer,
    ...userSettings.reducer,
    ...userProfile.reducer,
    ...faq.reducer,
  },
  actions: {
    ...playerListing.actions,
    ...userEntries.actions,
    ...loginComplete.actions,
    ...editProfile.actions,
    // ...shoppingCart.actions,
    ...admin.actions,
    ...standings.actions,
    ...checkout.actions,
    ...userSettings.actions,
    ...userProfile.actions,
    ...faq.actions,
  },
};

export default index;
